export const NodeType = {
    AREA: "site",
    MEDIASPOT: "securispot",
}


export const getNodeTypeFromString = (typeString:string) => {
    if(typeString === undefined){
        return NodeType.AREA
    }
    switch (typeString.toLowerCase()){
        case NodeType.MEDIASPOT: return NodeType.MEDIASPOT
        default: return NodeType.AREA
    }
}
