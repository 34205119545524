// src/navigation/tabs/Tabs.tsx

import React, { useEffect, useState } from "react";
import TabNavigation from "./components/TabNavigation";
import DateRangeSelector from "./components/DateRangeSelector";
import FilterIconButton from "./components/FilterIconButton";
import i18n from "../../i18n";
import classes from "./Tabs.module.css";
import { FilterModal } from "./filter/Filter";
import { TabItem, Interval } from "./TabsTypes";

interface TabsProps {
  items: TabItem[];
  onSelect: (tabItem: TabItem) => void;
  selectedTab: TabItem | undefined;
  onPeriodChange: (value: any, event: React.SyntheticEvent) => void;
  interval: Interval;
  onIntervalChanged: (interval: Interval) => void;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;
  serials: string[];
  selectSerials: (selection: string[]) => void;
  selectedSerials: string[];
  noPadding?: boolean;
  isMenuCollapsed: boolean;
}

const Tabs: React.FC<TabsProps> = ({
  items,
  onSelect,
  selectedTab,
  onPeriodChange,
  interval,
  onIntervalChanged,
  startDatePeriodTimestamp,
  endDatePeriodTimestamp,
  serials,
  selectSerials,
  selectedSerials,
  noPadding,
  isMenuCollapsed,
}) => {
  const currentLanguage = i18n.language;
  const dateFormat = currentLanguage.startsWith("fr") ? "dd/MM/yyyy" : "MM/dd/yyyy";
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedSerials);

  const closeFilterModal = () => setIsFilterModalOpen(false);

  const applyFilters = () => {
    selectSerials(selectedOptions);
    closeFilterModal();
  };

  useEffect(() => {
    setSelectedOptions(selectedSerials);
  }, [isFilterModalOpen]);

  return (
    <div
      className={`${classes.tabContainer} ${
        isMenuCollapsed ? classes.tabContainerCollapsed : ""
      } ${noPadding ? classes.tabContainerNoPadding : ""}`}
    >
      <div className={classes.tabContent}>
        <TabNavigation items={items} selectedTab={selectedTab} onSelect={onSelect} />
        <DateRangeSelector
          dateFormat={dateFormat}
          startDatePeriodTimestamp={startDatePeriodTimestamp}
          endDatePeriodTimestamp={endDatePeriodTimestamp}
          onPeriodChange={onPeriodChange}
          onIntervalChanged={onIntervalChanged}
        />
      </div>
      <div className={classes.filterIconContainer}>
        <FilterIconButton onClick={() => setIsFilterModalOpen(true)} />
        {isFilterModalOpen && (
          <FilterModal
            isOpen={isFilterModalOpen}
            onClose={closeFilterModal}
            options={serials}
            selectedOptions={selectedOptions}
            onChange={setSelectedOptions}
            applyChange={applyFilters}
            selectedSerials={selectedSerials}
          />
        )}
      </div>
    </div>
  );
};

export default Tabs;
