// src/ai/pdf/generateChart/generateChart.ts

import jsPDF from 'jspdf';
import { createHeatmap } from '../createheatmap/createMatrixChart';
import { ApiDataResponse } from '../../../api/Request';
import { processApiResponseData } from '../../datahelpers/processApiResponseData';
import { addImageToPDF } from './addImageToPDF';
import { calculateWeekNumber } from '../createheatmap/dateshelpers/formatDate';
import i18n from '../../../i18n';
import { addHorizontalLine, addSectionTitle } from './utils';
import { GraphType } from '../../../graph/GraphTypes';
/**
 * Interface for general data items (when data contains 'date' property).
 */
interface GeneralDataItem {
  date: string;
  [key: string]: any; // Other properties
}

/**
 * Interface for specific data entries within SpecificDataItem.
 */
interface SpecificDataEntry {
  date: string;
  count: number;
}

/**
 * Interface for specific data items (when data contains 'data' property).
 */
interface SpecificDataItem {
  data: SpecificDataEntry[];
  hostname: string;
  [key: string]: any; // Other properties
}

/**
 * Interface for aggregated rule data.
 */
interface AggregatedRuleData {
  rule: string;
  dates: string[];
  count: number;
  language: string;
}

/**
 * Generates a heatmap chart and adds it to the PDF with a dynamic caption.
 * @param doc - jsPDF instance.
 * @param yPosition - Y position to start rendering the chart.
 * @param margin - Margin to apply for the chart.
 * @param apiResponseData - API response data to generate the chart.
 * @returns Updated Y position after rendering the chart.
 */
export const generateChart = async (
  doc: jsPDF,
  yPosition: number,
  margin: number,
  apiResponseData?: ApiDataResponse<GeneralDataItem[] | SpecificDataItem[]>,
  grapheType?:GraphType
): Promise<number> => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const imgWidth = 180; // Fixed width for readability
  const imgHeight = 120; // Fixed height for readability

  // Early return if apiResponseData is not provided or contains no data
  if (!apiResponseData || !apiResponseData.data) {
    console.error('apiResponseData is undefined or contains no data');
    return yPosition;
  }

  try {
    // Process and aggregate the API response data
    const aggregatedDataArray: AggregatedRuleData[] | undefined = await processApiResponseData(apiResponseData, grapheType);
    if(!aggregatedDataArray) {
      console.error("aggregatedDataArray is undefined check processApiResponseData")
      return yPosition;
    }
    if (aggregatedDataArray.length === 0) {
      return yPosition;
    }

    let startDateString = '';
    let endDateString = '';

    if (Array.isArray(apiResponseData.data) && apiResponseData.data.length > 0) {
      const firstDataItem = apiResponseData.data[0];

      // Determine if it's general or specific by checking properties of the first item
      if ('date' in firstDataItem) {
        // General data type
        const dataArray = apiResponseData.data as GeneralDataItem[];
        startDateString = dataArray[0].date || '';
        endDateString = dataArray[dataArray.length - 1].date || '';
      } else if ('data' in firstDataItem && Array.isArray(firstDataItem.data)) {
        // Specific data type
        const dataArray = apiResponseData.data as SpecificDataItem[];
        const specificDates = dataArray.flatMap((item) =>
          item.data.map((entry) => entry.date)
        );

        // Sort dates and get the first and last in sorted order
        specificDates.sort();
        startDateString = specificDates[0] || '';
        endDateString = specificDates[specificDates.length - 1] || '';
      } else {
        console.error('Unknown data item structure.');
        return yPosition;
      }

      if (!startDateString || !endDateString) {
        console.error('Dates are missing from the data items.');
        return yPosition;
      }
    } else {
      console.warn('Data array is empty or invalid.');
      return yPosition;
    }

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Generate the heatmap as an image
    const chartImage = await createHeatmap(
      aggregatedDataArray,
      calculateWeekNumber(startDateString),
      false
    );
    if (!chartImage) {
      console.error('Failed to generate chart image.');
      return yPosition;
    }

    // Check for available space, add a new page if needed
    const availableSpace = pageHeight - yPosition - margin;
    if (imgHeight + 10 > availableSpace) {
      doc.addPage();
      yPosition = margin;
    }

    // Add horizontal line, section title, and heatmap with dynamic caption
    yPosition = addHorizontalLine(doc, yPosition, margin, pageWidth, '#004aac');
    yPosition = addSectionTitle(doc, yPosition, margin, i18n.t('Heatmap Section'));

    // Format dates for caption and add the heatmap image to the PDF
    const formattedStartDate = startDate.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const formattedEndDate = endDate.toLocaleDateString(i18n.language, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    const caption =
      i18n.t('Heatmap from') +
      ' ' +
      `${formattedStartDate}` +
      ' ' +
      i18n.t('to1') +
      ' ' +
      `${formattedEndDate}`;

    yPosition = addImageToPDF(
      doc,
      chartImage,
      margin,
      yPosition,
      imgWidth,
      imgHeight,
      caption
    );

    return yPosition + imgHeight + 10;
  } catch (error) {
    console.error('Error generating chart:', error);
    return yPosition;
  }
};
