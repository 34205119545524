import { MaterialColors } from "../../helpers/Colors";
import { ChartDataSets } from "chart.js";
import { GetAlarmsEvolutionApiResponse } from "../../api/DetectionsRequests";
import { DateTime } from 'luxon';
import i18n from "../../i18n";

export const getAlarmsEvolutionPerTypesDataset = (
  data: GetAlarmsEvolutionApiResponse
) => {
  const labels: Array<string> = new Array<string>();

  const dates: Array<Date> = new Array<Date>();

  const datasets = Array<ChartDataSets>();
  datasets.push({
    lineTension: 0,
    borderColor: MaterialColors[0],
    backgroundColor: MaterialColors[0] + "66", //add opacity,
    label: i18n.t("HelmetWearing"),
    data: data.map((it) => {
      return {
        x: new Date(it.date),
        y: it.rule2,
      };
    }),
  });

  datasets.push({
    lineTension: 0,
    borderColor: MaterialColors[1],
    backgroundColor: MaterialColors[1] + "66", //add opacity,
    label: i18n.t("Area"),
    data: data.map((it) => {
      return {
        x: new Date(it.date),
        y: it.rule1,
      };
    }),
  });

  datasets.push({
    lineTension: 0,
    borderColor: MaterialColors[2],
    backgroundColor: MaterialColors[2] + "66", //add opacity,
    label: i18n.t("LyingPerson"),
    data: data.map((it) => {
      return {
        x: new Date(it.date),
        y: it.rule3,
      };
    }),
  });

  datasets.push({
    lineTension: 0,
    borderColor: MaterialColors[3],
    backgroundColor: MaterialColors[3] + "66", //add opacity,
    label: i18n.t("OverloadedArea"),
    data: data.map((it) => {
      return {
        x: new Date(it.date),
        y: it.rule4,
      };
    }),
  });

    datasets.push({
    lineTension: 0,
    borderColor: MaterialColors[4],
    backgroundColor: MaterialColors[4] + "66", //add opacity,
    label: i18n.t("Collision"),
    data: data.map((it) => {
      return {
        x: new Date(it.date),
        y: it.rule5,
      };
    }),
    });
    
  return {
    datasets: datasets,
    dates: [],
    error: undefined,
    labels: labels,
    maxValue: undefined,
  };
};
