import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface TabItem {
  label: string;
  path: string;
  icon?: IconDefinition;
}

export enum Interval {
  HOUR,
  DAY,
}

export const getIntervalString = (interval: Interval): string => {
  return interval === Interval.HOUR ? "hour" : "day";
};
