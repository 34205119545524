import {Config } from "@fastpoint/mediaspot-management"

export default {
    // "API_URL": `https://cotentin-api.fast-point.net/${process.env.REACT_APP_STAGE}`,
    // "API_URL": "https://7a8pmu6df2.execute-api.eu-west-3.amazonaws.com/dev",
    "API_URL": "https://vision-api.fast-point.net/dev",
    // "API_KEY": `${process.env.REACT_APP_API_KEY}`
    "API_KEY": "p2CpKTbBYO6uhlVT4gVrK97PQr6c563K19RRVLwX"
}

Config.getInstance().update({
    API_PROTOCOL: "https",
    API_HOST: `cotentin-api.fast-point.net`,
    API_PORT: `443/${process.env.REACT_APP_STAGE}`,
    API_KEY: `${process.env.REACT_APP_API_KEY}`,
    WEBSOCKET_HOST: "websocket-prod.fp-internal.com",
    WEBSOCKET_PORT: "3052",
})