import { Chart, ChartDataSets } from 'chart.js';
import 'chartjs-plugin-zoom';
import { GraphType } from '../graph/GraphTypes';
import { Interval } from "../navigation/tabs/TabsTypes";
import { selectChartConfig } from './chartHelpers/configSelectors';
import { getBackgroundColor, getBorderColor } from './chartHelpers/styleHelpers';
import { getChartTitle, getDefaultChartOptions } from './chartHelpers/chartOptions';
import { getWeekdays } from './chartHelpers/xAxisConfig';

interface CreateChartParams {
  ctx: CanvasRenderingContext2D;
  graphType: GraphType;
  datasets: ChartDataSets[];
  dates: Date[];
  labels?: string[] | Array<string[]>;
  maxValue?: number;
  options?: Chart.ChartOptions;
  interval: Interval;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;
  preventUpdateAnimations: boolean;
}

export const createChart = ({
  ctx,
  graphType,
  datasets,
  dates,
  labels,
  maxValue,
  options = {},
  interval,
  startDatePeriodTimestamp,
  endDatePeriodTimestamp,
  preventUpdateAnimations,
}: CreateChartParams): Chart => {
  const chartConfig = selectChartConfig(graphType, dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp, datasets);

  const isSingleDataPoint = datasets.length === 1 && datasets[0].data && Array.isArray(datasets[0].data) && datasets[0].data.length === 1 && interval === Interval.DAY;

  if (isSingleDataPoint) {
    const weekdays = getWeekdays();
    // Setup weekday-based labels if single point
    labels = weekdays;

    // Ensure options, scales, and xAxes are properly initialized
    chartConfig.options = chartConfig.options ?? {};
    chartConfig.options.scales = chartConfig.options.scales ?? {};
    chartConfig.options.scales.xAxes = chartConfig.options.scales.xAxes ?? [{}];

    // Set x-axis properties with safe fallback for nested properties
    chartConfig.options.scales.xAxes[0] = {
      ...chartConfig.options.scales.xAxes[0],
      type: 'category', // Use 'category' type for custom labels
      labels: labels,   // Set the labels to weekdays
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        fontColor: '#333',
        fontSize: 12,
      },
    };

    // Remove 'time' configuration if present, since we're using 'category' type
    delete chartConfig.options.scales.xAxes[0].time;
  }

  // Merge datasets and labels into the chart configuration
  chartConfig.data = {
    datasets: datasets.map((dataset, index) => ({
      ...dataset,
      backgroundColor: getBackgroundColor(index),
      borderColor: getBorderColor(index),
      borderWidth: 2,
      pointBackgroundColor: '#fff',
      pointBorderColor: getBorderColor(index),
      pointBorderWidth: 2,
      pointRadius: 4,
      fill: false, // Set to true if you want to fill the area under the line
    })),
    labels,
  };

  // Define and merge the default chart options with additional options
  chartConfig.options = {
    ...getDefaultChartOptions(getChartTitle(graphType), maxValue, preventUpdateAnimations),
    ...chartConfig.options,
    ...options,
  };

  // Create and return the chart instance
  return new Chart(ctx, chartConfig);
};
