export const removeNullOrZeroValues = (data: any): any => {
  // If the input is an array, process each item recursively
  if (Array.isArray(data)) {
    return data
      .map(item => removeNullOrZeroValues(item)) // Recursively clean each item in the array
      .filter(item => item !== null && item !== undefined && Object.keys(item).length > 0); // Remove null/undefined or empty objects
  }

  // If the input is an object, process its properties recursively
  else if (typeof data === 'object' && data !== null) {
    const cleanedData: any = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const cleanedValue = removeNullOrZeroValues(value); // Recursively clean nested properties

        // Include the property only if it's not null, undefined, or 0 (in case of numbers)
        if (
          cleanedValue !== null &&
          cleanedValue !== undefined &&
          !(typeof cleanedValue === 'number' && cleanedValue === 0)
        ) {
          cleanedData[key] = cleanedValue;
        }
      }
    }

    // Special case: Check if the object has only 'date' and 'language' and remove it if true
    const isDateAndLanguageOnly = Object.keys(cleanedData).every(key => ['date', 'language'].includes(key));

    // If the cleaned object only contains 'date' and 'language', return null; otherwise, return the object or null if it's empty
    return isDateAndLanguageOnly ? null : Object.keys(cleanedData).length > 0 ? cleanedData : null;
  }

  // For primitive values (string, number, etc.), return them if valid (not null, undefined, or 0 for numbers)
  else if (data !== null && data !== undefined && !(typeof data === 'number' && data === 0)) {
    return data; // Return valid primitive data
  }

  // If the value is null, undefined, or an invalid number (0), return null
  return null;
};
