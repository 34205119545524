import config from "../config"
import {postRequest} from "./Request";



export const AssociatedDevicesParameter = {
    NOT_ASSOCIATED: 0,
    ASSOCIATED: 1,
    ALL: 2
}

export enum StatusDevicesParameter {
    ACTIVE,
    INACTIVE,
    IN_STOCK,
    ARCHIVE
}

export enum LogtypeParameter {
    REALTIME,
    OFFLINE
}

/***
 * Get list of requested devices filtered with differents options in request
 * @param serial serial of device to filter (can be undefined to ignore)
 * @param reference reference of device to filter (can be undefined to ignore)
 * @param associated integer indicating if all devices have to be retrieved, or just associated, or just non-associated
 * @param types array of type string of types to get
 * (can be "NodeType.Mediaspot", "NodeType.Modac", "NodeType.Radiameter, NodeType.Probe"
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>}
 */
 export const getDevices = async(serial: string, reference: string, associated: string, types: any, clientUUID: string) => {
    const body = JSON.stringify({
        types: types,
        isDeployed: associated,
        serial: serial === undefined ? "" : serial,
        reference: reference === undefined ? "" : reference,
        clientUUID: clientUUID
    })
    return await postRequest(config.API_URL + "/getdevices", body)
}

/***
 * Get all modacs
 * @returns {Promise<any|{error: *}>} promise with error key or an array of modacs
 */
 export const getModacs = async() => {
    return await postRequest(config.API_URL + "/getmodacs")
}

/***
 * Get all mediaspots
 * @returns {Promise<any|{error: *}>} promise with error key or an array of mediaspots
 */
export const getMediaspots = async () => {
    try {
        console.log('Fetching Securispots...'); // Log before the request
        const response = await postRequest(config.API_URL + "/getsecurispots");
        console.log('Response received:', response); // Log the response
        return response;
    } catch (error) {
        console.error('Error fetching Securispots:', error); // Log any error
        // Optionally, handle the error further (e.g., throw a custom error, return a fallback response)
        throw new Error('Failed to fetch Securispots');
    }
};

/***
 * Associate device to another device
 * @param deviceId device primary key to associate
 * @param parentId device primary key of parent to associate device
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
 export const associateDevice = async(deviceId: string, parentId: string, clientUUID: string) => {

    const body = JSON.stringify({
        device_pk: deviceId,
        parent_pk: parentId,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/associatedevice", body)
}

/***
 * Associate mediaspot to an area
 * @param mediaspotId primary key of mediaspot to associate
 * @param path node path to associate mediaspot
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} promise with error key or object response
 */
export const associateSecurispot = async (mediaspotId: string, path: string, clientUUID: string): Promise<any> => {
    const body = JSON.stringify({
        securispot_pk: mediaspotId,
        dest_node: path,
        clientUUID: clientUUID
    });

    try {
        // Perform the request
        const response = await postRequest(config.API_URL + "/associatesecurispot", body);
        return response; // Return the successful response

    } catch (error) {
        // Handle and return any errors encountered
        console.error("Error during association:", error);
        return { error: error || "Unknown error occurred" };
    }
};

/***
 * Dissociate a device from its parent (node or device)
 * @param deviceId device primary key of device to dissociate
 * @param sourceNodePath node path of device to dissociate
 * @param targetNodePath node path of reserve to move device
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
 export const dissociateDevice = async(deviceId: string, sourceNodePath: string, targetNodePath: string, clientUUID: string) => {
    const body = JSON.stringify({
        device_pk: deviceId,
        targetNode: targetNodePath,
        sourceNode: sourceNodePath,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/dissociatedevice", body)
}

/***
 * Delete an inactive device
 * @param parentNodePath node path of device
 * @param deviceId device primary key of device to dissociate
 * @param clientUUID unique client identifier (retrieved from jwt token)
 * @returns {Promise<any|{error: *}>} returns promise with error key or object response
 */
 export const deleteDevice = async(parentNodePath: string, deviceId: string, clientUUID: string) => {
    const body = JSON.stringify({
        parentNode: parentNodePath,
        device_pk: deviceId,
        clientUUID: clientUUID
    })

    return await postRequest(config.API_URL + "/deletedevice", body)
}

export const getGenieacsDevicesSummary = async(cpName: string) => {
    const body = JSON.stringify({
        clientName: cpName
    })

    return await postRequest(config.API_URL + "/getgenieacsdevicessummary", body)
}


