import axios from "axios";
import config from "../config";
import { getToken, logout } from "./Auth";
import { isTokenExpired } from "./Auth";

const api = axios.create({
  baseURL: config.API_URL,
});

api.interceptors.request.use(
  async (axiosConfig) => {
    const token = getToken(); // Ensure getToken is awaited

    axiosConfig.headers["fp-token"] = token;
    axiosConfig.headers["x-api-key"] = config.API_KEY;

    // Check if token exists and is not expired
    if (token !== null && isTokenExpired(token)) {
      logout();
      window.location.replace("/");
      throw new axios.Cancel("Cancel request: Token expired.");
    }

    if (token === null) {
      console.warn("Request not executed because of expired or missing token.");
      throw new axios.Cancel("Cancel request: No token.");
    }

    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
