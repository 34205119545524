import axios from "./axios";

export const postRequest = async(url: string, body?:any) => {
    try{
        const response = await axios.post(url, body, {
            headers: {
                'Content-Type' : 'application/json'
            }
        })

        if(response.data === undefined){
            return {
                error: `undefined data response for request on ${url}`
            }
        }
        return response.data;
    }catch(err:any){
        return {
            error: err
        }
    }
}

export type ApiDataResponse<T> = {
    error: string|undefined
    data: T|undefined
}

export const post = async<T>(url: string, body?:any): Promise<ApiDataResponse<T>> => {
    try{
        const response = await axios.post<T>(url, body, {
            headers: {
                'Content-Type' : 'application/json'
            }
        })
        
        if(response.data === undefined){
            return {
                error: `undefined data response for request on ${url}`,
                data: undefined
                
            }
        }
        return {
            error: undefined,
            data: response.data
        };
    }catch(err:any){
        return {
            error: err,
            data: undefined
        }
    }
}