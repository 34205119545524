// src/ai/pdf/AIPDFUtils.ts

import jsPDF from 'jspdf';
import { Interval } from '../../navigation/tabs/TabsTypes';
import { GraphType } from '../../graph/GraphTypes';
import { generateHeaderPDF } from './generateHeaderPDF'; // Import the header function
import { generateAIText } from './AIText/generateAIText';
import { generateChart } from './generateChart/generateChart';
import { ApiDataResponse } from '../../api/Request';
import i18n from '../../i18n';

export const generatePDFReport = async (
  aiResponse: string | undefined,
  dataType: 'alarms' | 'chart',
  graphType?: GraphType,
  interval?: Interval,
  startDatePeriodTimestamp?: number,
  endDatePeriodTimestamp?: number,
  preventUpdateAnimations?: boolean,
  apiResponseData?: ApiDataResponse<any> | undefined
) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  const margin = 10;
  let yPosition = 20;

  // Generate header section (await since image loading is asynchronous)
  yPosition = await generateHeaderPDF(doc, margin, yPosition);

  // Generate AI text section
  yPosition = generateAIText(doc, aiResponse, yPosition, margin);

  // Handle chart data if applicable
  if (
    dataType === 'chart' &&
    apiResponseData && // Ensure apiResponseData is present
    Array.isArray(apiResponseData.data) &&
    apiResponseData.data.length > 0 &&
    graphType &&
    interval !== undefined &&
    startDatePeriodTimestamp !== undefined &&
    endDatePeriodTimestamp !== undefined
  ) {
    yPosition = await generateChart(
      doc,
      yPosition,
      margin,
      apiResponseData,
      graphType
    );
  } else if (dataType === 'chart') {
    console.error('No valid chart data available for the PDF generation.');
  }

  // Format the start and end dates for the filename
  let formattedStartDate = '';
  let formattedEndDate = '';

  if (startDatePeriodTimestamp && endDatePeriodTimestamp) {
    const startDate = new Date(startDatePeriodTimestamp);
    const endDate = new Date(endDatePeriodTimestamp);

    // Use Intl.DateTimeFormat for localization
    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });

    formattedStartDate = dateFormatter.format(startDate);
    formattedEndDate = dateFormatter.format(endDate);

    // Replace invalid filename characters
    formattedStartDate = formattedStartDate.replace(/[\/\\:]/g, '-');
    formattedEndDate = formattedEndDate.replace(/[\/\\:]/g, '-');
  }

  // Construct the filename
  const fileName =
    i18n.t('AI_Report') +
    (formattedStartDate && formattedEndDate
      ? `_${formattedStartDate}_to_${formattedEndDate}`
      : '') +
    '.pdf';

  // Save the PDF after all async tasks are done
  doc.save(fileName);
};
