export const countEntries = (data: unknown): number => {
  let totalEntries = 0;

  /**
   * Helper function to recursively count entries.
   * @param item - The current item being processed.
   * @returns Number of meaningful entries found in the item.
   */
  const helper = (item: unknown): number => {
    if (Array.isArray(item)) {
      // Process array recursively
      let arrayEntries = 0;
      item.forEach((element) => {
        arrayEntries += helper(element);
      });
      return arrayEntries;
    } else if (typeof item === 'object' && item !== null) {
      // Process object recursively
      if (isMeaningfulEntry(item)) {
        return 1;
      } else {
        let objectEntries = 0;
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            objectEntries += helper((item as any)[key]);
          }
        }
        return objectEntries;
      }
    } else {
      // For primitive types (string, number, etc.), do not count
      return 0;
    }
  };

  /**
   * Determines if an object is a "meaningful" entry based on specific keys.
   * @param obj - The object to check.
   * @returns True if the object is a meaningful entry, false otherwise.
   */
  const isMeaningfulEntry = (obj: object): boolean => {
    const alarmKeys = ['id', 'securispot', 'date', 'description', 'duration'];
    const dataPointKeys = ['date', 'count'];
    const ruleDataKeys = ['date']; // Must have 'date' and 'ruleX' keys

    const objKeys = Object.keys(obj);

    // Check if the object contains the keys for an alarm entry
    if (alarmKeys.every((key) => objKeys.includes(key))) {
      return true;
    }

    // Check if the object contains the keys for a data point entry
    if (dataPointKeys.every((key) => objKeys.includes(key))) {
      return true;
    }

    // Check if the object contains rule data (e.g., 'ruleX' keys)
    if (
      ruleDataKeys.every((key) => objKeys.includes(key)) &&
      objKeys.some((key) => /^rule\d+$/.test(key))
    ) {
      return true;
    }

    return false;
  };

  // Validate input data structure
  if (typeof data !== 'object' || data === null) {
    console.warn('Invalid input: Expected an object or array.');
    return 0;
  }

  // Start counting entries
  totalEntries = helper(data);

  return totalEntries;
};
