import React, { useState, useEffect, useRef } from 'react';
import styles from './styles/ConfirmationModal.module.css'; // Import your custom styles
import i18n from '../../../i18n';
interface ConfirmationModalProps {
  nodeName: string;
  fromCompany: string;
  toCompany: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  nodeName,
  fromCompany,
  toCompany,
  onConfirm,
  onCancel,
}) => {
  const [userInput, setUserInput] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Track the error message
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Prevent copy-pasting into the input field
  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    setErrorMessage(i18n.t('Pasting is not allowed.'));
  };

  // Handle clicking Confirm
  const handleConfirmClick = () => {
    const trimmedInput = userInput.trim().toLowerCase();
    if (trimmedInput === 'confirm') {
      setErrorMessage(''); // Clear any previous error message
      onConfirm(); // Resolve the promise with `true`
    } else {
      setErrorMessage(i18n.t('Please enter the word "Confirm" to proceed.')); // Set error message
    }
  };

  // Handle clicking Cancel
  const handleCancelClick = () => {
    setUserInput(''); 
    setErrorMessage(''); // Clear any error message when canceled
    onCancel(); // Resolve the promise with `false`
  };

  return (
    <div className={styles.modalOverlay} role="dialog" aria-labelledby="modal-title">
      <div className={styles.modalContent}>
        <h3 id="modal-title">Attention</h3>
        <p>
          {i18n.t("You are moving the node")} <strong>{nodeName}</strong> {i18n.t("from")} 
          <strong> {fromCompany}</strong> {i18n.t("to")} <strong>{toCompany}</strong>.
        </p>
        <p>{i18n.t('Type "Confirm" in the field below to proceed:')}</p>
        {/* Display error message if there is one */}
        {errorMessage && <p className={styles.errorText}>{errorMessage}</p>}
        <input
          type="text"
          value={userInput}
          ref={inputRef}
          onChange={(e) => setUserInput(e.target.value)}
          onPaste={handlePaste} // Prevent pasting
          className={styles.modalInput}
          aria-label="Confirmation Input"
          placeholder="Confirm" // Shadow input text
        />
        <div className={styles.buttonGroup}>
          <button onClick={handleConfirmClick} className={styles.confirmButton}>
            {i18n.t("Confirm")}
          </button>
          <button onClick={handleCancelClick} className={styles.cancelButton}>
            {i18n.t("Cancel")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
