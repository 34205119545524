// Add the function to handle sending the email using Gmail web interface
import i18n from '../i18n';

export function sendEmail(lastName: string, firstName: string, email: string, password: string) {
    const subject = encodeURIComponent(i18n.t("Creation user for SecuriSPOT SmartView website"));
    
    const body = encodeURIComponent(
        `${i18n.t('Dear')} ${lastName}, ${firstName},\n\n` +
        `${i18n.t('The tech team at FastPoint has successfully created an account for you on the SecuriSPOT SmartView website. Please find your login details below:')}\n\n` +
        `${i18n.t('Username')}: ${email}\n` +
        `${i18n.t('Password')}: ${password}\n\n` +
        `${i18n.t('Best regards')},\n` +
        `${i18n.t('The FastPoint Team')}`
    );

    // Gmail-specific link format
    const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${subject}&body=${body}`;
    
    // Open Gmail in a new tab
    window.open(gmailLink, '_blank');
}
