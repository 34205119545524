// Treeutils/searchMatch.ts
import { Node } from './types';
import { NodeApi } from 'react-arborist';

/**
 * Determines if a node matches the search term.
 * @param node - The node to check.
 * @param term - The search term.
 * @returns True if the node matches the search term, false otherwise.
 */
export const searchMatch = (node: NodeApi<Node>, term: string): boolean => {
  const lowerTerm = term.toLowerCase();
  const nameMatch = node.data.name.toLowerCase().includes(lowerTerm);
  const descriptionMatch = node.data.description
    ? node.data.description.toLowerCase().includes(lowerTerm)
    : false;
  const tagsMatch = node.data.tags
    ? node.data.tags.some((tag) => tag.toLowerCase().includes(lowerTerm))
    : false;
  return nameMatch || descriptionMatch || tagsMatch;
};
