import React from 'react';
import { FaLink, FaUnlink } from 'react-icons/fa';
import styles from '../../GlobaleView.module.css';

type NodeId = string;
type NodeHandler = (nodeId: NodeId) => void;

interface AssociateButtonProps {
  nodeId: NodeId;
  isAssociated: boolean;
  onAssociateToggle: NodeHandler;
  disabled?: boolean;
}

const AssociateButton: React.FC<AssociateButtonProps> = ({
  nodeId,
  isAssociated,
  onAssociateToggle,
  disabled = false,
}) => {
  return (
    <div className={styles.associateButtonContainer}>
      <button
        className={`${styles.associateButton} ${isAssociated ? styles.associated : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            onAssociateToggle(nodeId);
          }
        }}
        aria-label="Associate Node"
        disabled={disabled}
      >
        {isAssociated ? (
          <FaLink aria-hidden="true" /> // Show FaLink for associated
        ) : (
          <FaUnlink aria-hidden="true" /> // Show FaUnlink for dissociated
        )}
      </button>
    </div>
  );
};

export default AssociateButton;
