// src/GlobalVue/Treeutils/helpers/TreeInitialDataapi.ts
import config from "../../../../config";
import { postRequest } from "../../../../api/Request";
import { Node } from "../types";

const treebuilderUrl = config.API_URL + "/treebuild";

const payload = {
  message: "calling treebuild for init build",
};

// Make the POST request
export const callTreebuilder = async (): Promise<Node[] | null> => {
  try {
    const response = await postRequest(treebuilderUrl, payload);
    console.log(response.result)
    if (response.error) {
      console.error("Error:", response.message);
      return null;
    }

    if (response && response.status) {
      console.log("Tree structure:", response.result);
      return response.result; // Assuming the tree data is under 'result'
    } else {
      console.warn("Invalid response:", response);
      return null;
    }
  } catch (error) {
    console.error("Error fetching tree data:", error);
    return null;
  }
};
