// src/navigation/menu/components/MenuItemNode.tsx

import React from 'react';
import { NodeRendererProps } from 'react-arborist';
import { MenuItem } from '../rawMenuItems';
import { HeaderNode } from './NodesComponents/HeaderNode';
import { FooterNode } from './NodesComponents/FooterNode';
import { MenuItemContent } from './NodesComponents/MenuItemContent';

interface MenuItemNodeProps extends NodeRendererProps<MenuItem> {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const MenuItemNode: React.FC<MenuItemNodeProps> = (props) => {
  const { node, isCollapsed, toggleCollapse } = props;
  const item = node.data;

  if (item.type === 'header') {
    return <HeaderNode isCollapsed={isCollapsed} toggleCollapse={toggleCollapse} />;
  }

  if (item.type === 'footer') {
    return <FooterNode isCollapsed={isCollapsed} />;
  }

  // Render the standard menu item node
  return <MenuItemContent {...props} />;
};

export default MenuItemNode;
