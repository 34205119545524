// src/stats/CollisionStats.tsx

import { Graph } from "../graph/Graph";
import { Interval } from "../navigation/tabs/TabsTypes";
import classes from "./CollisionStats.module.css"; // Updated import
import { useEffect, useState } from "react";
import { timestampToDate } from "../helpers/DateHelper";
import { AlarmsList } from "./AlarmsList";
import i18n from "../i18n";
import { GraphType } from "../graph/GraphTypes";

interface IProps {
  startDate: number;
  endDate: number;
  serial: string;
  title: string;
}

export const CollisionStats = (props: IProps) => {
  const [lastLoadingTimestamp, setLastLoadingTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    // Set up interval for updating the timestamp
    const timer = setInterval(() => {
      setLastLoadingTimestamp(Date.now());
    }, 15000);

    // Cleanup the interval when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []); // Empty dependency array ensures this effect only runs on mount and unmount

  return (
    <>
      {/* Statistics for Per Hour */}
      <div className={classes.StatsContainer}>
        <label className={classes.StatsLabel}>
          {i18n.t("CollisionAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerHour")})
        </label>
        <div className={classes.TitleSeparator} />
        <div className={classes.GraphContainer}>
            <Graph
              startDatePeriodTimestamp={props.startDate}
              endDatePeriodTimestamp={props.endDate}
              interval={Interval.HOUR}
              graphType={GraphType.COLLISION_ALARM_EVOLUTION}
              preventUpdateAnimations={true}
              nodePath={""}
              securispot={props.serial}
              export={true}
            />
        </div>
      </div>

      {/* Statistics for Per Day */}
      <div className={classes.StatsContainer}>
        <label className={classes.StatsLabel}>
          {i18n.t("CollisionAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerDay")})
        </label>
        <div className={classes.TitleSeparator} />
        <div className={classes.GraphContainer}>
            <Graph
              startDatePeriodTimestamp={props.startDate}
              endDatePeriodTimestamp={props.endDate}
              interval={Interval.DAY}
              graphType={GraphType.COLLISION_ALARM_EVOLUTION}
              preventUpdateAnimations={true}
              nodePath={""}
              securispot={props.serial}
              export={true}
            />
        </div>
      </div>

      {/* Alarms List */}
      <AlarmsList
        numberDays={1}
        height={"25vh"} // Corrected spelling from "heigth" to "height"
        type={5}
        serial={props.serial}
        title={props.title}
      />
    </>
  );
};
