import React from "react";
import { NavLink } from "react-router-dom";
import { TabItem } from "../TabsTypes";
import classes from "./TabNavigation.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface TabNavigationProps {
  items: TabItem[];
  selectedTab: TabItem | undefined;
  onSelect: (tabItem: TabItem) => void;
}

const TabNavigation: React.FC<TabNavigationProps> = ({ items, selectedTab, onSelect }) => (
  <>
    {items.map((item) => (
      <NavLink
        key={item.path}
        to={item.path}
        className={classes.tabItem}
        onClick={() => onSelect(item)}
      >
        <label className={item.path === selectedTab?.path ? classes.selectedTab : classes.tab}>
          {item.icon && <FontAwesomeIcon icon={item.icon} />} {item.label}
        </label>
        {item.path === selectedTab?.path && <div className={classes.tabSelectedLine} />}
      </NavLink>
    ))}
  </>
);

export default TabNavigation;
