import { postRequest } from "./Request";
import config from "../config";

/**
 * Sends the data to the backend OpenAI API endpoint.
 * The backend mainly requires the data part, but we'll include a message in the payload for completeness.
 * @param data - The data required for processing by the backend.
 * @returns A promise resolving to the AI response or an error.
 */
export const getAIResponse = async (data: any) => {
  const payload = {
    message: "Analyze the following data",  // Optional message, can be adjusted if needed
    data: data,  // The important part of the payload
  };

  try {
    // Send the request and log response
    const response = await postRequest(config.API_URL + "/analyze-data", payload);
    console.log("AI Response received:", JSON.stringify(response, null, 2));

    return response;  // Return the response from the backend (likely the AI analysis)
  } catch (error: any) {
    // Log detailed error information
    console.error("Error during AI request:", error.message || error);
    
    // Optionally, log stack trace for debugging
    if (error.stack) {
      console.error("Stack trace:", error.stack);
    }
    
    throw new Error("Failed to fetch AI response");
  }
};
