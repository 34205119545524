import i18n from "../../i18n";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faChartArea,
    faCogs,
    faHome,
    faSlidersH,
    faBars,
    faUserPen
  } from '@fortawesome/free-solid-svg-icons';
  
export interface MenuItem {
    id: string; // Unique identifier required by react-arborist
    name: string;
    url?: string;
    adminRequired: boolean;
    superAdminRequired: boolean;
    icon?: IconDefinition;
    children?: MenuItem[];
    type: 'header' | 'footer' | 'menuItem';
  }

export const rawMenuItems: MenuItem[] = [
    {
        id: 'header',
        type: 'header',
        name: 'SecuriSpot',
        adminRequired:false,
        superAdminRequired:false
      },
    {
      id: 'home',
      name: i18n.t('Home'),
      url: '/supervision',
      adminRequired: false,
      icon: faHome,
      superAdminRequired: false,
      type:'menuItem',
      children: [
        {
          id: 'supervision',
          name: i18n.t('Supervision'),
          url: '/supervision',
          adminRequired: false,
          superAdminRequired: false,
          type:'menuItem',
        },
      ],
    },
    {
      id: 'administration',
      name: i18n.t('Administration'),
      url: '/admin/',
      adminRequired: true,
      icon: faSlidersH,
      superAdminRequired: false,
      type:'menuItem',
      children: [
        {
          id: 'assignment-management',
          name: i18n.t('AssignmentManagement'),
          url: '/admin/devices',
          adminRequired: true,
          superAdminRequired: false,
          type:'menuItem',
        },
        {
          id: 'equipment-management',
          name: i18n.t('EquipmentManagement'),
          url: '/admin/mediaspots',
          adminRequired: true,
          superAdminRequired: false,
          type:'menuItem',
        },
      ],
    },
    {
      id: 'provisioning',
      name: i18n.t('Provisioning'),
      url: '/provisioning/',
      adminRequired: true,
      icon: faCogs,
      superAdminRequired: true,
      type:'menuItem',
      children: [
        {
          id: 'mediaspots',
          name: i18n.t('Mediaspots'),
          url: '/provisioning/mediaspots',
          adminRequired: true,
          superAdminRequired: true,
          type:'menuItem',
        },
      ],
    },
    {
      id: 'signup',
      name: i18n.t('Signup'),
      url: '/signup/',
      adminRequired: true,
      icon: faUserPen,
      superAdminRequired: true,
      type:'menuItem',
      children: [
        {
          id: 'create-user',
          name: i18n.t('CreateUser'),
          url: '/signup/user',
          adminRequired: true,
          superAdminRequired: false,
          type:'menuItem',
        },
      ],
    },
    {
      id: 'global-view',
      name: i18n.t('GlobalView'),
      url: '/GlobalView/',
      adminRequired: true,
      icon: faSlidersH,
      superAdminRequired: true,
      type:'menuItem',
      children: [
        {
          id: 'global-view-child',
          name: i18n.t('GlobalView'),
          url: '/GlobalView',
          adminRequired: true,
          superAdminRequired: false,
          type:'menuItem',
        },
      ],
    },
    {
        id: 'footer',
        type: 'footer',
        name: 'Version',
        adminRequired:false,
        superAdminRequired:false
      },
  ];