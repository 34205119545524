// src/GlobalVue/Treeutils/helpers/handleAddNode.ts

import { Node } from '../types';
import { addChildNodeById } from '../helpers';
import { callNodeCommandApi } from '../api/callNodeCommandApi';
import { callGenerateNodeIdApi } from '../api/callGenerateNodeIdApi';

interface HandleAddNodeParams {
  parentId: string;
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  setEditNodeId: React.Dispatch<React.SetStateAction<string | null>>;
  setNewNodeName: React.Dispatch<React.SetStateAction<string>>;
  setNewlyAddedNodeId: React.Dispatch<React.SetStateAction<string | null>>;
}

export const handleAddNodeLogic = async ({
  parentId,
  data,
  setData,
  setEditNodeId,
  setNewNodeName,
  setNewlyAddedNodeId,
}: HandleAddNodeParams): Promise<void> => {
  // Call the backend API to get a new node ID
  const newNodeId = await callGenerateNodeIdApi(parentId);

  if (!newNodeId) {
    // Handle failure to get new node ID
    console.error('Failed to generate new node ID from server.');
    return;
  }

  const newNode: Node = {
    id: newNodeId,
    name: '',
    children: [],
  };

  // Call the backend API to add the new node
  const success = await callNodeCommandApi({
    nodeId: newNodeId,
    command: 'add',
    data: { parentId },
  });

  if (success) {
    // Proceed with local state update
    const updatedData = addChildNodeById(data, parentId, newNode);
    setData(updatedData);
    setEditNodeId(newNodeId);
    setNewNodeName('');
    setNewlyAddedNodeId(newNodeId);
  } else {
    // Handle failure
    console.error('Failed to add node on server.');
  }
};
