// Function to get background color for datasets
export const getBackgroundColor = (index: number): string => {
    const colors = [
      'rgba(31, 119, 180, 0.2)', // Blue with transparency
      'rgba(255, 127, 14, 0.2)', // Orange with transparency
      'rgba(148, 103, 189, 0.2)', // Purple with transparency
      'rgba(23, 190, 207, 0.2)', // Teal with transparency
      'rgba(127, 127, 127, 0.2)', // Gray with transparency
    ];
    return colors[index % colors.length];
  };
  
  // Function to get border color for datasets
  export const getBorderColor = (index: number): string => {
    const colors = [
      '#1f77b4', // Blue
      '#ff7f0e', // Orange
      '#9467bd', // Purple
      '#17becf', // Teal
      '#7f7f7f', // Gray
    ];
    return colors[index % colors.length];
  };
  