import React, { useRef } from 'react';
import classes from './Menu.module.css';
import { isAdmin, isSuperAdmin } from '../../api/Auth';
import { MenuItem, rawMenuItems } from './rawMenuItems';
import MenuTree, { MenuTreeHandle } from './components/MenuTree';

interface MenuProps {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const Menu: React.FC<MenuProps> = ({ isCollapsed, toggleCollapse }) => {
  const isUserAdmin = isAdmin();
  const isUserSuperAdmin = isSuperAdmin();

  const menuTreeRef = useRef<MenuTreeHandle>(null);

  // Filter menu items based on user roles
  const filterMenuItems = (items: MenuItem[]): MenuItem[] => {
    return items
      .filter((item) => {
        if (item.superAdminRequired && !isUserSuperAdmin) return false;
        if (item.adminRequired && !isUserAdmin) return false;
        return true;
      })
      .map((item) => ({
        ...item,
        children: item.children ? filterMenuItems(item.children) : undefined,
      }));
  };

  const menuItems = filterMenuItems(rawMenuItems);

  // Function to handle collapse and expand actions
  const handleToggleCollapse = () => {
    if (menuTreeRef.current) {
      // Collapse all nodes if collapsing the menu
      if (!isCollapsed) {
        menuTreeRef.current.collapseAllNodes();
      } else {
        // Expand all nodes if expanding the menu
        menuTreeRef.current.expandAllNodes();
      }
    }
    // Toggle the collapse state
    toggleCollapse();
  };

  return (
    <div
      className={`${classes.MenuContainer} ${
        isCollapsed ? classes.MenuContainerCollapsed : ''
      }`}
    >
      <div className={classes.TreeContainer}>
        <MenuTree
          ref={menuTreeRef}
          menuItems={menuItems}
          isCollapsed={isCollapsed}
          toggleCollapse={handleToggleCollapse} // Use the new handler here
        />
      </div>
    </div>
  );
};

export default Menu;
