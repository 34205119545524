// ActionButtons.tsx

import React, { memo } from 'react';
import { FaEdit, FaTrashAlt, FaPlus } from 'react-icons/fa';
import styles from '../../GlobaleView.module.css';

type NodeId = string;
type NodeHandler = (nodeId: NodeId) => void;
type ParentNodeHandler = (parentId: NodeId) => void;

interface ActionButtonsProps {
  nodeId: NodeId;
  onEdit: NodeHandler;
  onDelete: NodeHandler;
  onAddChild: ParentNodeHandler;
  icons?: {
    edit?: React.ReactNode;
    delete?: React.ReactNode;
    addChild?: React.ReactNode;
  };
  labels?: {
    edit?: string;
    delete?: string;
    addChild?: string;
  };
  disabled?: boolean;
}

const defaultIcons = {
  edit: <FaEdit aria-hidden="true" />,
  delete: <FaTrashAlt aria-hidden="true" />,
  addChild: <FaPlus aria-hidden="true" />,
};

const defaultLabels = {
  edit: 'Edit Node',
  delete: 'Delete Node',
  addChild: 'Add Child Node',
};

/**
 * ActionButtons Component
 * Renders action buttons for tree nodes: Edit, Delete, and Add Child.
 */
const ActionButtons: React.FC<ActionButtonsProps> = memo(
  ({
    nodeId,
    onEdit,
    onDelete,
    onAddChild,
    icons = defaultIcons,
    labels = defaultLabels,
    disabled = false,
  }) => {
    return (
      <div className={styles.actionButtons}>
        <button
          className={`${styles.actionButton} ${styles.editButton}`}
          onClick={(e) => {
            e.stopPropagation();
            onEdit(nodeId);
          }}
          aria-label={labels.edit}
          disabled={disabled}
        >
          {icons.edit}
        </button>
        <button
          className={`${styles.actionButton} ${styles.deleteButton}`}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(nodeId);
          }}
          aria-label={labels.delete}
          disabled={disabled}
        >
          {icons.delete}
        </button>
        <button
          className={`${styles.actionButton} ${styles.addButton}`}
          onClick={(e) => {
            e.stopPropagation();
            onAddChild(nodeId);
          }}
          aria-label={labels.addChild}
          disabled={disabled}
        >
          {icons.addChild}
        </button>
      </div>
    );
  }
);

export default ActionButtons;
