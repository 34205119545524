// src/cards/LastEventDateCard.tsx

import { useEffect, useState } from "react";
import { getLastEventDate } from "../api/DetectionsRequests";
import Card from "../card/Card";
import { secToTextWithoutUselessUnit } from "../helpers/DateHelper";
import classes from "./LastEventDateCard.module.css"; // Import the new CSS module
import i18n from "../i18n";
interface IProps {}

export const LastEventDateCard = (props: IProps) => {
  const [lastEventDate, setLastEventDate] = useState<string | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);

  const loadLastEventDate = async (isMounted: boolean) => {
    const response = await getLastEventDate();
    if (!isMounted) return; // Prevent state updates if the component is unmounted

    setLoading(false);

    if (response.error) {
      console.error("Error during get last event date loading", response.error);
      return;
    }

    setLastEventDate(response.data?.date);
  };

  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted

    // Load the last event date initially
    loadLastEventDate(isMounted);

    // Start the timer
    const intervalId = setInterval(() => {
      loadLastEventDate(isMounted);
    }, 15000);

    // Cleanup when the component is unmounted
    return () => {
      isMounted = false; // Set the flag to false when the component is unmounted
      clearInterval(intervalId); // Clear the interval
    };
  }, []);

  return (
    <div className={classes.LastEventDateCardContainer}>
      <Card
        forceLoadingDisplay={isLoading}
        title={i18n.t("LastDataReceived")} // Use translation if needed
        value={
          lastEventDate === undefined
            ? "-"
            : secToTextWithoutUselessUnit(
                ((Date.now() - new Date(lastEventDate).getTime()) / 1000).toString()
              )
        }
        backgroundColor={"#1F9BD9"}
        infoType={"lastEvent"}
      />
    </div>
  );
};
