// src/stats/OverloadedStats.tsx

import { Graph } from "../graph/Graph";
import { Interval } from "../navigation/tabs/TabsTypes";
import classes from "./OverloadedStats.module.css"; // Updated import
import { useEffect } from "react";
import { timestampToDate } from "../helpers/DateHelper";
import { AlarmsList } from "./AlarmsList";
import i18n from "../i18n";
import { GraphType } from "../graph/GraphTypes";

interface IProps {
  startDate: number;
  endDate: number;
  serial: string;
  title: string;
}

export const OverloadedStats = (props: IProps) => {
  useEffect(() => {
    const timer = setInterval(() => {
      // This doesn't need to update the state for now, just re-run some logic
      console.log("Refetching data every 15 seconds");
    }, 15000);

    // Cleanup interval on component unmount
    return () => {
      clearInterval(timer);
    };
  }, []); // No dependencies, runs only once when component mounts

  return (
    <>
      {/* Statistics for Per Hour */}
      <div className={classes.StatsContainer}>
        <label className={classes.StatsLabel}>
          {i18n.t("OverloadedAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")}{" "}
          {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerHour")})
        </label>
        <div className={classes.TitleSeparator} />
        <div className={classes.GraphContainer}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.HOUR}
            graphType={GraphType.OVERLOADED_AREA_ALARM}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={true}
          />
        </div>
      </div>

      {/* Statistics for Per Day */}
      <div className={classes.StatsContainer}>
        <label className={classes.StatsLabel}>
          {i18n.t("OverloadedAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")}{" "}
          {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerDay")})
        </label>
        <div className={classes.TitleSeparator} />
        <div className={classes.GraphContainer}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.DAY}
            graphType={GraphType.OVERLOADED_AREA_ALARM}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={true}
          />
        </div>
      </div>

      {/* Alarms List */}
      <AlarmsList
        numberDays={1}
        height={"25vh"} // Corrected spelling from "heigth" to "height"
        type={4}
        serial={props.serial}
        title={props.title}
      />
    </>
  );
};
