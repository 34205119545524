// src/GlobalVue/Treeutils/helpers/handleDeleteNode.ts

import { Node } from '../types';
import { removeNodesById } from '../helpers';
import { callNodeCommandApi } from '../api/callNodeCommandApi';

interface HandleDeleteNodeParams {
  nodeId: string;
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  editNodeId: string | null;
  handleCancelEdit: (nodeId?: string) => void;
}

export const handleDeleteNodeLogic = async ({
  nodeId,
  data,
  setData,
  editNodeId,
  handleCancelEdit,
}: HandleDeleteNodeParams): Promise<void> => {
  // Call the backend API
  const success = await callNodeCommandApi({ nodeId, command: 'delete' });

  if (success) {
    // Proceed with local state update
    const updatedData = removeNodesById(data, [nodeId]);
    setData(updatedData);
    if (editNodeId === nodeId) {
      handleCancelEdit();
    }
  } else {
    // Handle failure (e.g., show error message)
    console.error('Failed to delete node on server.');
  }
};
