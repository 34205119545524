// src/stats/AlarmsCountCard.tsx

import { useEffect, useState } from "react";
import { getAlerts } from "../api/DetectionsRequests";
import Card from "../card/Card";
import { Interval } from "../navigation/tabs/TabsTypes";
import classes from "./AlarmsCountCard.module.css"; // Import the new CSS module
import i18n from "../i18n"; // Import i18n for translations

interface IProps {}

export const AlarmsCountCard = (props: IProps) => {
  const [alarmsCount, setAlarmsCount] = useState<number | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(true);

  const loadAlarmsCount = async () => {
    const now = Date.now();
    const alertsResponse = await getAlerts(
      now - 1000 * 60 * 60 * 24 * 4,
      now,
      Interval.DAY
    );
    setLoading(false);
    if (alertsResponse.error) {
      console.error("An error occurred during the loading of alarms");
      return;
    }
    setAlarmsCount(alertsResponse.data?.length);
  };

  useEffect(() => {
    let isMounted = true;

    const intervalId = setInterval(async () => {
      if (isMounted) {
        await loadAlarmsCount();
      }
    }, 15000);

    // Initial load
    loadAlarmsCount();

    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={classes.AlarmsCountCardContainer}>
      <Card
        forceLoadingDisplay={isLoading}
        title={i18n.t("Number of Alerts in the Last 4 Days")}
        value={alarmsCount !== undefined ? alarmsCount.toString() : "-"}
        backgroundColor={"#1F9BD9"}
        interval={Interval.DAY}
        startDatePeriodTimestamp={Date.now() - 3600 * 1000 * 24 * 4}
        endDatePeriodTimestamp={Date.now()}
        infoType={"alarmsCount"} // Assuming Card component handles this prop
      />
    </div>
  );
};
