import {Component} from "react"
import classes from "./DevicesSearch.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

import DeviceRow from "./DeviceRow/DeviceRow";
import {NodeType} from "../navigation/tree/NodeType"
import Loader from "react-loader-spinner";
import {getI18n, Trans} from "react-i18next";
import PropTypes from "prop-types"
import {AssociatedDevicesParameter, getDevices} from "../api/DevicesRequests";
import {getClientUUID, isSuperAdmin} from "../api/Auth"

class DeviceSearch extends Component {

    state = {
        allDevices: undefined,
        devices: undefined,

        serial: undefined,
        reference: undefined,
        isDeployed: AssociatedDevicesParameter.NOT_ASSOCIATED,
        types: [NodeType.MEDIASPOT],

        searchingRequestInProgress: true,

        // Use to keep reference on requested association request
        isAssociatedInRequest: AssociatedDevicesParameter.NOT_ASSOCIATED,
        typesInRequest: [NodeType.MEDIASPOT],
        referenceInRequest: "",
        serialInRequest: ""
    }

    async componentDidMount() {
        const response = await getDevices(this.state.serial, this.state.reference, this.state.isDeployed, this.state.types, getClientUUID())
        this.setState({allDevices: response, devices: response, searchingRequestInProgress: false})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.toDissociateNodes !== this.props.toDissociateNodes && this.props.toDissociateNodes !== undefined){
            this.onDissociateDevices(this.props.toDissociateNodes)
        }
    }

    onDissociateDevices = (dissociatedDevices) => {
        const devices = {...this.state.devices}
        dissociatedDevices.forEach(device => {
            // Get key from getdevices response from dissociated device type
            const key = device.device_type
            // if dissociated device type has matching with one type
            if(key !== undefined && devices[key] && devices[key]["Items"]){
                const deviceIndex = devices[key]["Items"].findIndex(it => {
                    return it !== undefined && it.pk === device.pk
                })

                // If deviceIndex matching with one device list item, update its "isDeployed" attribute
                if(deviceIndex !== -1) {
                    devices[key]["Items"][deviceIndex].isDeployed = false
                }else {
                    device.isDeployed = false
                    device.status = "IN_STOCK"
                    devices[key]["Items"].push(device)
                }
            }
        })
        this.props.onDissociationFinished()
        this.forceUpdate()
    }


    onUpdateDevice = (newObject) => {
        // Ensure newObject is valid
        if (!newObject || !newObject.subtype || !newObject.id) {
            console.error("Invalid device object provided for update");
            return;
        }
    
        // Deep copy the state to prevent mutating the original state object
        let devicesStateCopy = {
            ...this.state.devices,
            securispot: {
                ...this.state.devices.securispot,
                Items: [...this.state.devices.securispot.Items]
            }
        };
    
        let devicesTypeCopy;
    
        switch (newObject.subtype) {
            case NodeType.MEDIASPOT:
                devicesTypeCopy = devicesStateCopy.securispot.Items;
                break;
            default:
                console.warn(`Unsupported subtype: ${newObject.subtype}`);
                return;  // Exit early if subtype is not supported
        }
    
        // Check if the devicesTypeCopy is valid and proceed
        if (Array.isArray(devicesTypeCopy)) {
            const editedDeviceIndex = devicesTypeCopy.findIndex(it => it && it.pk === newObject.id);
    
            // Ensure that a device with the matching id exists
            if (editedDeviceIndex !== -1) {
                const editedDevice = {
                    ...devicesTypeCopy[editedDeviceIndex],  // Create a new device object
                    isDeployed: newObject.isDeployed,
                    associatedPath: newObject.associatedPath
                };
    
                // Replace the old device in the copied array with the updated device
                devicesTypeCopy[editedDeviceIndex] = editedDevice;
    
                // Set the updated state
                this.setState({ devices: devicesStateCopy }, () => {
                    console.log("Device updated and state set successfully.");
                });
            } else {
                console.error(`Device with id ${newObject.id} not found.`);
            }
        } else {
            console.error("DevicesTypeCopy is not a valid array");
        }
    };
    

    onSerialChanged = (e) => {
        const serialValue = e.target.value
        this.setState({serial: serialValue})
    }

    onReferenceChanged = (e) => {
        const referenceValue = e.target.value
        this.setState({reference: referenceValue})
    }

    onAssociatedFilterChanged = (associatedValueToUpdate) => {
        this.setState({isDeployed: associatedValueToUpdate})
    }

    onTypeChanged = (type) => {
        let types = [...this.state.types]
        if(this.state.types.includes(type)){
            const indexToDelete = types.indexOf(type)
            if(indexToDelete > -1){
                types.splice(indexToDelete, 1)
            }
        }else {
            types.push(type)
        }
        this.setState({types: types})
    }

    onValidateSearch = async(event) => {
        if(event !== undefined){
            event.preventDefault()
        }
        if(this.state.types.length === 0){
            alert(getI18n().t("YouHaveToPickAtLeastOneTypeOfDevice"))
            return;
        }
        this.setState({searchingRequestInProgress: true, isAssociatedInRequest: this.state.isDeployed, typesInRequest: this.state.types, serialInRequest: this.state.serial, referenceInRequest: this.state.reference}, async() => {
            const devicesResponse = await getDevices(this.state.serial, this.state.reference, this.state.isDeployed, this.state.types, getClientUUID());
            if(devicesResponse.error !== undefined){
                alert(getI18n().t("AnErrorOccurred"))
                this.setState({searchingRequestInProgress: false})
                return;
            }
            this.setState({allDevices: devicesResponse, devices: devicesResponse, searchingRequestInProgress: false})
        })
    }
    
    render(){
        const isTouch = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0));
        return (

            <div className={classes.DeviceSearchContainer} style={isTouch ? {paddingLeft: "30px", paddingRight: "30px"} : undefined}>
                <form onSubmit={this.onValidateSearch}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <div className={classes.DeviceSearchInputContainer}>
                            <label><Trans>Serial</Trans> : </label>
                            <input onChange={this.onSerialChanged} value={this.state.serial} type="text"/>
                        </div>

                        <div className={classes.DeviceSearchInputContainer}>
                            <label><Trans>Reference</Trans> : </label>
                            <input onChange={this.onReferenceChanged} value={this.state.reference} type="text"/>
                        </div>

                        <div className={classes.DeviceSearchInputContainer}>
                            <label><Trans>Associated</Trans> : </label>
                            <div>
                                <input id={"associatedTrue"} onChange={() => this.onAssociatedFilterChanged(AssociatedDevicesParameter.ASSOCIATED) } type="radio" name="associated" value={AssociatedDevicesParameter.ASSOCIATED} checked={this.state.isDeployed === AssociatedDevicesParameter.ASSOCIATED}/>
                                <label htmlFor={"associatedTrue"}> <Trans>Yes</Trans></label>
                            </div>
                            <div>
                                <input id={"associatedFalse"} onChange={() => this.onAssociatedFilterChanged(AssociatedDevicesParameter.NOT_ASSOCIATED) } type="radio" name="associated" value={AssociatedDevicesParameter.NOT_ASSOCIATED} checked={this.state.isDeployed === AssociatedDevicesParameter.NOT_ASSOCIATED}/>
                                <label htmlFor={"associatedFalse"}> <Trans>No</Trans></label>
                            </div>
                            <div>
                                <input id={"associatedAll"} onChange={() => this.onAssociatedFilterChanged(AssociatedDevicesParameter.ALL) } type="radio" name="associated" value={AssociatedDevicesParameter.ALL} checked={this.state.isDeployed === AssociatedDevicesParameter.ALL}/>
                                <label htmlFor={"associatedAll"}> <Trans>All</Trans></label>
                            </div>
                        </div>

                        <div className={classes.DeviceSearchInputContainer}>
                            <label><Trans>Type</Trans> : </label>
                            <div>
                                <input id={"mediaspot"} onChange={(e) => this.onTypeChanged(NodeType.MEDIASPOT)} type="checkbox" name="devicetype" value={NodeType.MEDIASPOT} checked={this.state.types.includes(NodeType.MEDIASPOT)}/>
                                <label htmlFor={"mediaspot"}> Securispot</label>
                            </div>
                            
                        </div>

                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {
                            this.state.searchingRequestInProgress
                                ? <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                                :<button className={classes.DeviceSearchValidateButton} type={"submit"}><FontAwesomeIcon icon={faSearch}/> <Trans>Search</Trans></button>}

                    </div>
                    <br />
                    {this.state.searchingRequestInProgress === false ? <label>{this.state.allDevices !== undefined ? this.state.allDevices.NbDevices : ""} {this.state.typesInRequest.length === 1 ? getI18n().t("SearchResultsForTypes") : getI18n().t("SearchResultsForType")} <b>{this.state.typesInRequest.map(it => getI18n().t(it)).join(", ")}</b> <b>{this.state.isAssociatedInRequest === AssociatedDevicesParameter.ASSOCIATED ? getI18n().t("AssociatedOnly") : ""}</b> <b>{this.state.isAssociatedInRequest === AssociatedDevicesParameter.NOT_ASSOCIATED ? getI18n().t("NotAssociatedOnly") : ""}</b> {this.state.referenceInRequest !== undefined && this.state.referenceInRequest !== "" ? getI18n().t("WithReference") + " " + this.state.referenceInRequest : ""} {this.state.serialInRequest !== undefined && this.state.serialInRequest !== "" ? getI18n().t("WithSerial") + " " + this.state.serialInRequest : ""}  </label> : undefined}


                </form>
                <div className={classes.DeviceSearchResultsSeparator}/>

                <div className={classes.DeviceSearchResultsContainer}>

                    <table>
                        <thead>
                        <tr role={"tr"}>
                            <td></td>
                            <td><Trans>Type</Trans></td>
                            <td><Trans>Reference</Trans></td>
                            <td><Trans>Serial</Trans></td>
                            <td><Trans>Associated</Trans></td>
                            
                            {isSuperAdmin ? <td><Trans>Actions</Trans></td> : undefined}
                        </tr>
                        </thead>
                        <tbody>
                        {
                        
                            this.state.devices !== undefined &&
                            (this.state.devices.securispot === undefined || this.state.devices.securispot.Items.filter(it => it.status !== "ARCHIVE").length === 0)
                           ? <tr><td colSpan={5}><Trans>AnyDeviceMatchingWithSearchFilters</Trans></td></tr> : undefined}
                        {
                            this.state.devices === undefined? <></> :
                                <>
                                    {
                                        this.state.devices.securispot !== undefined && this.state.devices.securispot.Items !== undefined ? this.state.devices.securispot.Items.map(mediaspot => {
                                            if(mediaspot === undefined || mediaspot.status === "ARCHIVE" || (mediaspot.isDeployed === true && this.state.isAssociatedInRequest === AssociatedDevicesParameter.NOT_ASSOCIATED)){
                                                
                                                return undefined
                                            }
                                            return <DeviceRow onEditDeviceClick={this.props.onEditDeviceClick} onShowClick={this.props.onShowDeviceRequest} reference={mediaspot.reference} fullObject={mediaspot} key={`securispot-${mediaspot.pk}`} id={mediaspot.pk} nodeType={NodeType.MEDIASPOT} isDeployed={mediaspot.isDeployed} serial={mediaspot.serial} onUpdate={this.onUpdateDevice} associatedPath={mediaspot.associatedPath}/>
                                        }) : undefined
                                    }
                                </>
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}

DeviceSearch.propTypes = {
    toDissociateNodes: PropTypes.array,
    onDissociationFinished: PropTypes.func,
    onShowDeviceRequest: PropTypes.func
}

export default DeviceSearch
