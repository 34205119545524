import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { I18nextProvider } from "react-i18next";
import { Config } from "@fastpoint/mediaspot-management";
import { BrowserRouter, Route } from 'react-router-dom';
import Login from './account/login/Login';
import Logout from './account/logout/Logout';
import SignUp from './SignUp/SignUp'; // Import the SignUp component
import { getToken } from './api/Auth';

import { DndProvider } from "react-dnd";
import CustomDragLayer from "./devicesmapping/CustomDragLayer";
import { HTML5Backend } from "react-dnd-html5-backend";

import i18n from './i18n';
import { clearRangeEndDate } from './helpers/Storage';

const token = getToken();
if (token) {
    Config.getInstance().updateToken(token);
}

// Initialize any required tasks and clear storage dates
clearRangeEndDate();

ReactDOM.render(
    //@ts-ignore
    <DndProvider backend={HTML5Backend}>
        <I18nextProvider i18n={i18n}>
            <CustomDragLayer />
            <BrowserRouter>
                <>
                    <App />
                    <Route exact path="/login" render={() => <Login />} />
                    <Route exact path="/logout" render={() => <Logout />} />
                </>
            </BrowserRouter>
        </I18nextProvider>
    </DndProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
