import { MaterialColors } from "../../helpers/Colors";
import { ChartDataSets } from "chart.js";
import {
  GetAlarmsApiResponse,
  GetAlarmsPerTypeApiResponse,
} from "../../api/DetectionsRequests";

export const getAlarmsEvolutionDataset = (
  data: GetAlarmsPerTypeApiResponse
) => {
  const labels: Array<string> = new Array<string>();
  const datasets = Array<ChartDataSets>();

  const dates: Array<Date> = new Array<Date>();

  data.map((device) => {
    datasets.push({
      lineTension: 0,
      borderColor: MaterialColors[datasets.length + 1] + "66",
      backgroundColor: MaterialColors[datasets.length + 1] + "66", //add opacity,
      label: device.hostname,
      data: device.data.map((it) => {
        return {
          x: new Date(it.date),
          y: it.count,
        };
      }),
    });
  });

  return {
    datasets: datasets,
    dates: [],
    error: undefined,
    labels: labels,
    maxValue: undefined,
  };
};
