// src/GlobalVue/Treeutils/helpers/handlers/handleSortChildren.ts

import { Node } from '../types';

interface HandleSortChildrenParams {
  nodeId: string;
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  sortingOrders: { [key: string]: 'asc' | 'desc' };
  setSortingOrders: React.Dispatch<React.SetStateAction<{ [key: string]: 'asc' | 'desc' }>>;
}

export const handleSortChildrenLogic = ({
  nodeId,
  data,
  setData,
  sortingOrders,
  setSortingOrders,
}: HandleSortChildrenParams): void => {
  const currentOrder = sortingOrders[nodeId] || 'asc';
  const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';

  const sortChildren = (nodes: Node[]): Node[] => {
    return nodes.map((node) => {
      if (node.id === nodeId) {
        const sortedChildren = [...(node.children || [])].sort((a, b) => {
          if (a.name < b.name) return newOrder === 'asc' ? -1 : 1;
          if (a.name > b.name) return newOrder === 'asc' ? 1 : -1;
          return 0;
        });
        return { ...node, children: sortedChildren };
      } else if (node.children) {
        return { ...node, children: sortChildren(node.children) };
      }
      return node;
    });
  };

  const newData = sortChildren(data);

  setSortingOrders((prevOrders) => ({ ...prevOrders, [nodeId]: newOrder }));
  setData(newData);
};
