import { useState } from "react"
import classes from "./Mediaspots.module.css"
import Tree from "../../navigation/tree/Tree"
import { Area } from "../../navigation/tree/Area"
import MediaspotManagement from "./management/MediaspotManagement"

const Mediaspots = () => {
    console.log("Mediaspots called")
    const [selectedNodeId, setSelectedNodeId] = useState<string|undefined>(undefined)
    const [_, setSelectedArea] = useState<Area|undefined>(undefined)

    const [selectedMediaspotSerial, setSelectedMediaspotSerial] = useState<string|undefined>(undefined)
    
    const onSelectArea = (area: Area, nodeId: string, nodePath: string) => {
        setSelectedNodeId(nodeId)
        setSelectedArea(area)
        console.log("onSelectArea area.hasMediaspot", area.hasMediaspot, "area.securispot", area.securispot)
        if(area.hasMediaspot === true && area.securispot){
            setSelectedMediaspotSerial("FFFFFF-Mediaspot-" + area.securispot.serial)
        }else {
            setSelectedMediaspotSerial(undefined)
        }
    }

    return (
        <>
            <div className={classes.MediaspotsTreeContainer}>
                <Tree
                    autoExpandAllAreas={true}
                    isAreaManagement={false}
                    hideVisibleNodes={true}
                    onFactoriesLoaded={() => console.log("factories loaded")}
                    onModacFetched={() => {console.log("modac fetched")}}
                    onSelectModac={() => {console.log("select modac")}}
                    alreadySelectedNodeId={selectedNodeId}
                    onSelect={onSelectArea} />
            </div>


            <div className={classes.MediaspotsContainer}>

                <label>Type d'appareil : Caméra 3D</label> <br />
                <label>Référence : Realsense D30</label> <br />
                <label>Type de détection : Port du casque.</label> <br />
                <label>Etat : En ligne</label> <br />
                <label>Derniere communication : {new Date(Date.now()).toLocaleDateString()}</label> <br />
            </div>

            {/* <div className={classes.MediaspotsContainer}>
                {
                    selectedMediaspotSerial ? 
                    <MediaspotManagement mediaspotId={selectedMediaspotSerial}/>
                    : <label>Veuillez sélectionnez une zone équipée d'un équipement administrable.</label>
                }
            </div> */}
        </>
    )
}

export default Mediaspots