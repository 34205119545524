import React, {ChangeEvent, Component, FormEvent} from "react";
import classes from "./Provisionning.module.css"
import {Trans} from "react-i18next"
import { Router } from "../../models/Router";
import { getClientUUID } from "../../api/Auth";
import { addrouter, editRouter } from "../../api/ProvisionningRequests";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "react-loader-spinner";
import { ApiDataResponse } from "../../api/Request";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

interface IState {
    router: Router,
    creationRequestInProgress: boolean,
}

interface IProps {
    device?: Router
}

class RoutersProvisionning extends Component<IProps, IState> {

    constructor(props: IProps){
        super(props)
        this.state = {
            creationRequestInProgress: false,
            router: props.device !== undefined ? props.device : {
                brand: "Teltonika",
                configDetected: {
                    rutx_firmware: "RUTX_R_00.02.06.1",
                    rutx_mccmnc: "20801",
                    imsi: "0"
                },
                configSet: {
                    rutx_firmware: "RUTX_R_00.02.06.1",
                    rutx_mccmnc: "20801"
                },
                device_type: "router",
                gsi1sk: "26C7042F-BCE8-4C6D-9CF8-CAA2A5F6EB92|7A44E7EE-6AE3-4D70-9239-B50E7CA4B756|de8beba4-e112-4ae3-9836-6125b7680384",
                isDeployed: true,
                lastIndex: 0,
                log_type: "REALTIME",
                model: "RUTX 11",
                parent_key: "none",
                pk: "",
                reference: "",
                rutx_batch: "",
                searchRef: "",
                serial: "",
                sk: "router|" + getClientUUID(),
                status: "IN_STOCK",
                last_received_event: "",
                statusUpdatedOn: ""
            }
        }
    }

    onCreateClick = async(event: FormEvent) => {
        event.preventDefault()

        await this.setState({creationRequestInProgress: true})
        let response: ApiDataResponse<object>|undefined = undefined
        if(this.props.device){
            response = await editRouter(this.state.router)
        }else {
            response = await addrouter(this.state.router)
        }
        
        if(response.error){
            toast(this.props.device ? "Erreur lors de l'édition du routeur" : "Erreur lors de la création du routeur", {
                type: "error"
            })
        }else {
            toast("Routeur ajouté au référentiel", {
                type: "success"
            })
            
            const deviceCopy = {...this.state.router}
            deviceCopy.serial = ""
            await this.setState({router: deviceCopy})
        }
        
        await this.setState({creationRequestInProgress: false})
    }

    onSerialChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.serial = value
        if(this.props.device === undefined){
            routerCopy.reference = `router-${value}`
            routerCopy.searchRef = `router-${value}`
        }
        this.setState({
            router: routerCopy
        })
    }

    onBatchChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.rutx_batch = value
        this.setState({
            router: routerCopy
        })
    }

    onReferenceChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.reference = value
        this.setState({
            router: routerCopy
        })
    }
    
    onSearchReferenceChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.searchRef = value
        this.setState({
            router: routerCopy
        })
    }

    onBrandChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.brand = value
        this.setState({
            router: routerCopy
        })
    }

    onModelChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.model = value
        this.setState({
            router: routerCopy
        })
    }

    onFirmwareChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.configSet.rutx_firmware = value
        this.setState({
            router: routerCopy
        })
    }

    onRutxMccmncChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.configSet.rutx_mccmnc = value
        this.setState({
            router: routerCopy
        })
    }

    onPathChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.gsi1sk = value
        this.setState({
            router: routerCopy
        })
    }
    
    onLogtypeChanged = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value
        const routerCopy = {...this.state.router}
        routerCopy.log_type = value as "OFFLINE" | "REALTIME"
        this.setState({
            router: routerCopy
        })
    }

    render(){
        return (
            <div className={classes.ProvisionningContainer}>
                <h4>{this.props.device === undefined ? "Ajout d'un routeur au référentiel" : "Édition d'un routeur"}</h4>
                <form onSubmit={this.onCreateClick}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <table className={classes.ProvisionningFieldsTable}>
                            <tr>
                                <td><label><Trans>Serial</Trans> : </label></td>
                                <td><input required onChange={this.onSerialChanged} value={this.state.router.serial} type="text"/></td>
                            </tr>
                            <tr>
                                <td><label><Trans>Batch</Trans> : </label></td>
                                <td><input required onChange={this.onBatchChanged} value={this.state.router.rutx_batch} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Brand</Trans> : </label></td>
                                <td><input required onChange={this.onBrandChanged} value={this.state.router.brand} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Model</Trans> : </label></td>
                                <td><input required onChange={this.onModelChanged} value={this.state.router.model} type="text"/></td>
                            </tr>
                            
                            <tr>
                                <td><label><Trans>Firmware</Trans> : </label></td>
                                <td><input required onChange={this.onFirmwareChanged} value={this.state.router.configSet.rutx_firmware} type="text"/></td>
                            </tr>

                            <tr>
                                <td><label><Trans>Mcc mnc</Trans> : </label></td>
                                <td><input required onChange={this.onRutxMccmncChanged} value={this.state.router.configSet.rutx_mccmnc} type="text"/></td>
                            </tr>

                            {
                                this.props.device ? 
                                <>
                                    <tr>
                                        <td><label><Trans>Reference</Trans> : </label></td>
                                        <td><input required onChange={this.onReferenceChanged} value={this.state.router.reference} type="text"/></td>
                                    </tr>
                                    
                                    <tr>
                                        <td><label><Trans>Search reference</Trans> : </label></td>
                                        <td><input required onChange={this.onSearchReferenceChanged} value={this.state.router.searchRef} type="text"/></td>
                                    </tr>
                                </>
                                : null
                            }


                            <tr>
                                <td><label><Trans>LogType</Trans> : </label></td>
                                <td>
                                    <select onChange={this.onLogtypeChanged}>
                                        <option key="realtime" value="REALTIME">Realtime</option>
                                        <option key="offline" value="OFFLINE">Offline</option>
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td><label><Trans>Path {this.props.device ? "" : "(réserve)"}</Trans> : </label></td>
                                <td><input required onChange={this.onPathChanged} value={this.state.router.gsi1sk} type="text"/></td>
                            </tr>
                        </table>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {
                            this.state.creationRequestInProgress
                                ? <Loader type={"Oval"} width={25} height={25} color={"#0095ff"}/>
                                :<button className={this.props.device ? classes.ProvisionningEditButton : classes.ProvisionningValidateButton} type={"submit"}><FontAwesomeIcon icon={this.props.device ? faPen: faPlus}/> <Trans>{this.props.device ? "Edit" : "Create"}</Trans></button>}

                    </div>
                </form>
                <ToastContainer/>
            </div>
        )
    }
}

export default RoutersProvisionning