// ./src/graph/graphprops/IncidentsEvolutionProps.ts
import { ChartConfiguration, ChartDataSets } from "chart.js";
import { Interval } from "../../navigation/tabs/TabsTypes";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";
import { getDateRange } from "./helpers/minmaxDates";
import { sortDatasetsByMaxValue } from "./helpers/sortDatasetsByMaxValue";

export const graphProps = (
  dates: Date[],
  interval: Interval,
  startDateTimestamp: number,
  endDateTimestamp: number,
  datasets: ChartDataSets[]
): ChartConfiguration => {
  const { minDate, maxDate } = getDateRange(dates);
  const sortedDatasets = sortDatasetsByMaxValue(datasets);
  
  const props: ChartConfiguration = {
    type: 'line',
    data: { datasets: sortedDatasets }, // Use sorted datasets here
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: true,
        align: "center",
        fullWidth: true,
        labels: {
          fontColor: "black",
          usePointStyle: true,
        },
        position: "bottom",
      },
      scales: {
        xAxes: [
          xAxeTimeDefaultProps(
            minDate,
            maxDate,
            interval,
            startDateTimestamp,
            endDateTimestamp
          ),
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              stepSize: 1,
            },
          },
        ],
      },
    },
  };
  return props;
};
