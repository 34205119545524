// src/navigation/menu/components/NodesComponents/MenuItemContent.tsx

import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { NodeRendererProps } from 'react-arborist';
import classNames from 'classnames';
import { MenuItem } from '../../rawMenuItems';
import ReactTooltip from 'react-tooltip';
import styles from './MenuItemContent.module.css';

export interface MenuItemNodeProps extends NodeRendererProps<MenuItem> {
  isCollapsed: boolean;
}

export const MenuItemContent: React.FC<MenuItemNodeProps> = ({ node, style, isCollapsed }) => {
  const history = useHistory();
  const location = useLocation();
  const item = node.data;
  const isActive = item.url ? location.pathname.startsWith(item.url) : false;
  const hasChildren = node.isInternal;
  const isOpen = node.isOpen;

  const handleClick = () => {
    if (item.url) history.push(item.url);
  };

  return (
    <>
      <div
        style={style}
        className={classNames(
          styles.MenuItemContainer,
          styles[`MenuItemLevel${node.level}`],
          { [styles.MenuItemActive]: isActive },
          { [styles.Collapsed]: isCollapsed }
        )}
        onClick={handleClick}
        data-tip={isCollapsed ? item.name : undefined}
      >
        <div className={styles.MenuItemHeader}>
          {/* Expand/Collapse Arrow Icon */}
          {!isCollapsed && hasChildren && (
            <FontAwesomeIcon
              icon={isOpen ? faAngleDown : faAngleRight}
              className={styles.MenuItemToggleIcon}
              onClick={(e) => {
                e.stopPropagation(); // Prevent triggering parent click
                node.toggle();
              }}
            />
          )}
          {/* Node-specific Icon */}
          {item.icon && (
            <FontAwesomeIcon
              icon={item.icon}
              className={styles.MenuItemIcon}
            />
          )}
          {/* Display text when not collapsed */}
          {!isCollapsed && <span className={styles.MenuItemText}>{item.name}</span>}
        </div>
      </div>
      {isCollapsed && node.level === 0 && <ReactTooltip place="right" type="dark" effect="solid" />}
    </>
  );
};
