// src/stats/LyingPersonStats.tsx

import { Graph } from "../graph/Graph";
import { Interval } from "../navigation/tabs/TabsTypes";
import classes from "./LyingPersonStats.module.css"; // Updated import
import { useEffect, useState } from "react";
import { timestampToDate } from "../helpers/DateHelper";
import { AlarmsList } from "./AlarmsList";
import i18n from "../i18n";
import { GraphType } from "../graph/GraphTypes";

interface IProps {
  startDate: number;
  endDate: number;
  serial: string;
  title: string;
}

export const LyingPersonStats = (props: IProps) => {
  const [lastLoadingTimestamp, setLastLoadingTimestamp] = useState<number>(Date.now());

  useEffect(() => {
    // Set up interval
    const timer = setInterval(() => {
      setLastLoadingTimestamp(Date.now());
    }, 15000);

    // Cleanup function when component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []); // Empty dependency array to run only on mount

  return (
    <>
      {/* Statistics for Per Hour */}
      <div className={classes.StatsContainer}>
        <label className={classes.StatsLabel}>
          {i18n.t("LyingAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerHour")})
        </label>
        <div className={classes.TitleSeparator} />
        <div className={classes.GraphContainer}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.HOUR}
            graphType={GraphType.LYING_PERSON_ALARMS_EVOLUTION}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={true}
          />
        </div>
      </div>

      {/* Statistics for Per Day */}
      <div className={classes.StatsContainer}>
        <label className={classes.StatsLabel}>
          {i18n.t("LyingAlarms")} {timestampToDate(props.startDate)} {i18n.t("AlarmTo")} {timestampToDate(props.endDate)} - {props.title} ({i18n.t("PerDay")})
        </label>
        <div className={classes.TitleSeparator} />
        <div className={classes.GraphContainer}>
          <Graph
            startDatePeriodTimestamp={props.startDate}
            endDatePeriodTimestamp={props.endDate}
            interval={Interval.DAY}
            graphType={GraphType.LYING_PERSON_ALARMS_EVOLUTION}
            preventUpdateAnimations={true}
            nodePath={""}
            securispot={props.serial}
            export={true}
          />
        </div>
      </div>

      {/* Alarms List */}
      <AlarmsList
        numberDays={1}
        height={"25vh"} // Corrected spelling from "heigth" to "height"
        type={3}
        serial={props.serial}
        title={props.title}
      />
    </>
  );
};
