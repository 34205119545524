import React, { ChangeEvent, Component, FormEvent } from 'react';
import classes from './Login.module.css';
import { login } from '../../api/Auth';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import AppIcon from "../../assets/img/smartview.png";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import i18n from '../../i18n';

interface IProps {}
interface IState {
  username: string;
  password: string;
  error: object | undefined;
  isJwtTokenLoaded: boolean;
  requestInProgress: boolean;
  showPassword: boolean;
}

class Login extends Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: undefined,
      isJwtTokenLoaded: false,
      requestInProgress: false,
      showPassword: false
    };
  }

  componentDidMount() {
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    this.setState({ requestInProgress: true }, async () => {
      const loginResult = await login(this.state.username, this.state.password);
      this.setState({ requestInProgress: false }, async () => {
        if (!loginResult.success) {
          this.setState({ error: loginResult.error });
        } else {
          this.setState({
            isJwtTokenLoaded: true,
            error: undefined
          });
          window.location.replace('/');
        }
      });
    });
  };

  handleUsernameChanged = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ username: event.target.value });
  };

  handlePasswordChanged = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };

  render() {
    const validationSection = (this.state.requestInProgress || this.state.isJwtTokenLoaded) ? (
      <div className={classes.LoginSpinnerContainer}>
        <div className={classes.LoginSpinnerContent}>
          <Loader
            type="Oval"
            color="#FFFFFF"
            height={30}
            width={100}
          />
        </div>
      </div>
    ) : (
      <input className={classes.LoginValidateButton} type={"submit"} value={"CONNEXION"} />
    );

    return (
      <div className={classes.LoginContainer}>
        <div style={{ textAlign: "center", marginBottom: "20px", height: "150px" }}>
          <img src={AppIcon} alt={"App icon"} style={{ height: "100%" }} />
        </div>
        <form onSubmit={this.handleSubmit} className={classes.LoginForm}>
          <FormControl variant="outlined" style={{ marginBottom: "10px" }}>
            <InputLabel htmlFor="outlined-adornment-username">Email</InputLabel>
            <OutlinedInput
              id="outlined-adornment-username"
              type={'email'}
              onChange={this.handleUsernameChanged}
              label="Email"
            />
          </FormControl>

          <FormControl variant="outlined" style={{ marginBottom: "10px" }}>
            <InputLabel htmlFor="outlined-adornment-password">{i18n.t("Password")}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={this.state.showPassword ? 'text' : 'password'}
              onChange={this.handlePasswordChanged}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                    edge="end"
                  >
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          {this.state.error != null ? <p style={{ color: "red" }}>{this.state.error}</p> : null}

          {validationSection}
        </form>
        <label style={{ textAlign: "center", marginTop: "10px" }}>{i18n.t("ValidAccess")}</label>
        <br />
        <label className={classes.MenuAppVersion} style={{ textAlign: "center" }}>
          <br />
          <span className={classes.MenuCopyright}>
            {i18n.t("RightReserved")} {new Date(Date.now()).getFullYear()}
          </span>
          <br />
          <span>Version {process.env.REACT_APP_VERSION}</span>
        </label>
      </div>
    );
  }
}

export default Login; // Wrap withRouter to access routing props
