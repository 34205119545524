import React from 'react';
import styles from './SignUp.module.css'; // Reuse the styles from SignUp
import i18n from '../i18n';
import { sendEmail } from './sendbuttonclicked';

interface SignupModalProps {
  title: string;
  message: React.ReactNode; // Accepts JSX content
  lastName: string;
  firstName: string;
  email: string;
  password: string;
  onClose: () => void; // Function to close the modal and handle navigation
}

const SignupModal: React.FC<SignupModalProps> = ({ title, message, lastName, firstName, email, password, onClose }) => {
  const handleSendEmail = () => {
    sendEmail(lastName, firstName, email, password);
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <span className={styles.close} onClick={onClose}>&times;</span>
        <div className={styles.modalContent}>
          <h3>{title}</h3>
          {/* Directly render the message as JSX */}
          {typeof message === 'string' ? <p>{message}</p> : message}
          <button className={styles.backButton} onClick={onClose}>
            {i18n.t('Back')}
          </button>
          <button className={styles.sendEmailButton} onClick={handleSendEmail}>
            {i18n.t('Send Email')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignupModal;
