import { DateTime } from "luxon";
import saveAs from "file-saver";
import { findHost } from "../../helpers/MappingSecurispot";
import { extractDescriptionAndPeople } from "./alarmHelpers";

interface Alarm {
  securispot: string;
  date: string;
  description: any;
  duration: number;
}

export const downloadAlarmsCSV = (alarms: Alarm[], serial: string | undefined, start: number, end: number): void => {
  if (!alarms || alarms.length === 0) return;

  const csvHeader = "Securispot;Date;Description;Nombre de personnes;Durée (sec)\n";
  const csvContent =
    csvHeader +
    alarms
      .map((alarm: Alarm) => {
        const { statusDescription, nbPersons } = extractDescriptionAndPeople(alarm.description);
        
        // Exclude rows where both duration and nbPersons are 0
        if (nbPersons === 0 || Math.round(alarm.duration) === 0) {
          return ''; // Return an empty string for rows to be excluded
        }
        
        return `${alarm.securispot};${DateTime.fromISO(alarm.date).toFormat("yyyy-MM-dd HH:mm:ss")};${statusDescription};${nbPersons};${Math.round(alarm.duration)}`;
      })
      .filter(row => row !== '') // Filter out empty rows
      .join("\n");

  const securi = serial ? findHost(serial) : "tous-les-SecuriSpots";
  const startDateString = DateTime.fromMillis(start).toFormat("yyyy-MM-dd");
  const endDateString = DateTime.fromMillis(end).toFormat("yyyy-MM-dd");
  const fileName = `${securi}_${startDateString}${startDateString !== endDateString ? "_" + endDateString : ""}_liste.csv`;

  const csvBlob = new Blob(["\ufeff" + csvContent], { type: "text/csv;charset=utf-8" });
  saveAs(csvBlob, fileName);
};
