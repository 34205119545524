export function extractDescriptionAndPeople(description: any): { statusDescription: string; nbPersons: number } {
    let descString: string;
    if (typeof description === 'string') {
      descString = description;
    } else if (Array.isArray(description) && description.length > 0 && typeof description[0] === 'string') {
      descString = description[0];
    } else {
      console.warn('Description is not a string or array of strings:', description);
      return { statusDescription: '', nbPersons: 0 };
    }
  
    const match = descString.match(/^(\d+)\s+(.*)/);
    if (match) {
      const nbPersons = parseInt(match[1], 10);
      const statusDescription = match[2].trim();
      return { statusDescription, nbPersons };
    } else {
      return { statusDescription: descString, nbPersons: 0 };
    }
  }
  