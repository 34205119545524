// src/ai/pdf/generateHeaderPDF.ts

import jsPDF from 'jspdf';
import i18n from '../../i18n';

/**
 * Generates a styled PDF header with a logo, centered title, current date, and additional styling elements.
 * @param doc - jsPDF instance
 * @param margin - Margin to apply for the header
 * @param yPosition - Initial Y position on the PDF
 * @returns Promise<number> - Updated Y position after rendering the header
 */
export const generateHeaderPDF = (
  doc: jsPDF,
  margin: number,
  yPosition: number
): Promise<number> => {
  return new Promise((resolve, reject) => {
    try {
      const pageWidth = doc.internal.pageSize.getWidth();
      const headerHeight = 50; // Increased height for more content
      const imgWidth = 30;
      const imgHeight = 30;
      const logoYPosition = yPosition + 10; // Adjusted to center the logo vertically

      // Draw header background
      doc.setFillColor('#004aac'); // Header background color
      doc.rect(0, yPosition, pageWidth, headerHeight, 'F');

      // Load the logo image
      const img = new Image();
      img.src = '/logo512.png'; // Adjust the path to your logo image

      img.onload = () => {
        try {
          if (!doc || typeof doc.addImage !== 'function') {
            throw new Error('Invalid jsPDF document instance.');
          }

          // Add the logo to the PDF
          doc.addImage(
            img,
            'PNG',
            margin + 5,
            logoYPosition,
            imgWidth,
            imgHeight
          );

          // Add site name centered with respect to the page width
          const centerXPosition = pageWidth / 2;
          doc.setTextColor('#FFFFFF'); // White color for header text
          doc.setFontSize(24);
          doc.setFont('Helvetica', 'bold');
          doc.text('SecuriSPOT SmartView', centerXPosition, yPosition + 20, {
            align: 'center',
          });

          // Add a tagline or subtitle
          doc.setFontSize(12);
          doc.setFont('Helvetica', 'italic');
          doc.text(i18n.t('AI-Generated Report'), centerXPosition, yPosition + 30, {
            align: 'center',
          });

          // Add current date at the right
          const currentDate = new Date().toLocaleDateString();
          doc.setFontSize(12);
          doc.setFont('Helvetica', 'normal');
          doc.text(`Date: ${currentDate}`, pageWidth - margin - 5, yPosition + 15, {
            align: 'right',
          });

          // Draw a bottom border (optional)
          doc.setDrawColor('#FFFFFF');
          doc.setLineWidth(0.5);
          doc.line(
            margin,
            yPosition + headerHeight,
            pageWidth - margin,
            yPosition + headerHeight
          );

          // Resolve with updated yPosition after the header
          resolve(yPosition + headerHeight + 10);
        } catch (error) {
          console.error('Error while generating the header with image:', error);
          reject(new Error('Failed to generate PDF header.'));
        }
      };

      img.onerror = (err) => {
        console.error('Error loading image, proceeding without the logo:', err);

        // If logo fails to load, still add the text and date
        try {
          // Add header background
          doc.setFillColor('#004aac'); // Header background color
          doc.rect(0, yPosition, pageWidth, headerHeight, 'F');

          // Add the site name in the center
          const centerXPosition = pageWidth / 2;
          doc.setTextColor('#FFFFFF'); // White color for header text
          doc.setFontSize(24);
          doc.setFont('Helvetica', 'bold');
          doc.text('SecuriSPOT SmartView', centerXPosition, yPosition + 20, {
            align: 'center',
          });

          // Add a tagline or subtitle
          doc.setFontSize(12);
          doc.setFont('Helvetica', 'italic');
          doc.text(i18n.t('AI-Generated Report'), centerXPosition, yPosition + 30, {
            align: 'center',
          });

          // Add current date at the right
          const currentDate = new Date().toLocaleDateString();
          doc.setFontSize(12);
          doc.setFont('Helvetica', 'normal');
          doc.text(`Date: ${currentDate}`, pageWidth - margin - 5, yPosition + 15, {
            align: 'right',
          });

          // Draw a bottom border (optional)
          doc.setDrawColor('#FFFFFF');
          doc.setLineWidth(0.5);
          doc.line(
            margin,
            yPosition + headerHeight,
            pageWidth - margin,
            yPosition + headerHeight
          );

          // Resolve with updated yPosition after the header
          resolve(yPosition + headerHeight + 10);
        } catch (error) {
          reject(new Error('Failed to generate PDF header without image.'));
        }
      };
    } catch (error) {
      console.error('Unexpected error in generating the PDF header:', error);
      reject(new Error('Error generating PDF header.'));
    }
  });
};
