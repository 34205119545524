import Loader from "react-loader-spinner";
import classes from "../AlarmsList.module.css";

const LoaderComponent = () => (
  <div className={classes.LoaderContainer}>
    <Loader type={"Oval"} width={40} height={40} color={"#0095ff"} />
  </div>
);

export default LoaderComponent;
