// src/ai/pdf/AIText/processElement.ts

import jsPDF from 'jspdf';
import { renderText } from './renderText';
import { estimateTextHeight } from './utils';

/**
 * Recursively processes an HTML element and renders it into the PDF.
 * Handles various tags like h4, h5, p, ul, ol, and others.
 * @param doc - jsPDF instance
 * @param element - The HTML element to process
 * @param yPosition - Current Y position in the PDF
 * @param margin - Margin to apply
 * @returns Updated Y position after processing the element
 */
export const processElement = (
  doc: jsPDF,
  element: HTMLElement,
  yPosition: number,
  margin: number
): number => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  switch (element.tagName.toLowerCase()) {
    case 'h4': {
      // Render the h4 element (Title)
      yPosition += 6; // Space before h4
      doc.setFontSize(16);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor('#004aac'); // Set color for h4
      doc.text(element.textContent || '', margin, yPosition);
      yPosition += 10; // Space after h4
      break;
    }

    case 'h5': {
      // Render the h5 element (Subtitle)
      yPosition += 4; // Space before h5
      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      doc.setTextColor('#005bd1'); // Set color for h5
      doc.text(element.textContent || '', margin + 5, yPosition);
      yPosition += 8; // Space after h5
      break;
    }

    case 'p': {
      // Render paragraph content
      doc.setTextColor('#333333'); // Set color for paragraphs
      yPosition = renderText(doc, element.textContent || '', 12, false, yPosition, margin, pageHeight);
      break;
    }

    case 'ul': {
      // For unordered lists, process each list item with proper margin
      const listItems = element.querySelectorAll('li');
      listItems.forEach((li: HTMLElement) => {
        doc.setTextColor('#333333'); // Set color for list items
        yPosition = renderText(doc, `• ${li.textContent}`, 12, false, yPosition, margin + 10, pageHeight);
      });
      yPosition += 4; // Add extra space after the list
      break;
    }

    case 'ol': {
      // For ordered lists, process each list item with numbering
      const orderedListItems = element.querySelectorAll('li');
      orderedListItems.forEach((li: HTMLElement, index: number) => {
        doc.setTextColor('#333333'); // Set color for list items
        yPosition = renderText(doc, `${index + 1}. ${li.textContent}`, 12, false, yPosition, margin + 10, pageHeight);
      });
      yPosition += 4; // Add extra space after the list
      break;
    }

    case 'blockquote': {
      // Render blockquote with styling
      yPosition += 6; // Space before blockquote
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'italic');
      doc.setTextColor('#666666');
      doc.setDrawColor('#dddddd');
      doc.setLineWidth(0.5);
      doc.rect(margin, yPosition - 4, pageWidth - margin * 2, estimateTextHeight(doc, element.textContent || '', 12, margin) + 8, 'S');
      yPosition = renderText(doc, element.textContent || '', 12, false, yPosition, margin + 5, pageHeight);
      yPosition += 6; // Space after blockquote
      break;
    }

    default: {
      // Render unrecognized tags as paragraphs
      doc.setTextColor('#333333');
      yPosition = renderText(doc, element.textContent || '', 12, false, yPosition, margin, pageHeight);
      break;
    }
  }

  return yPosition;
};
