import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import classes from "../Tabs.module.css";

interface FilterIconButtonProps {
  onClick: () => void;
}

const FilterIconButton: React.FC<FilterIconButtonProps> = ({ onClick }) => (
  <div className={classes.FilterIcon}>
    <FontAwesomeIcon icon={faFilter} onClick={onClick} />
  </div>
);

export default FilterIconButton;
