// processApiResponseData.ts

import { aggregateRulesData } from './aggregateRulesData';
import { removeNullOrZeroValues } from './removeNullOrZeroValues';
import { ApiDataResponse } from '../../api/Request';
import i18n from '../../i18n';
import { GraphType } from '../../graph/GraphTypes';
import { aggregateSpecificRuleData } from './aggregateSpecificRuleData';
type AggregatedRule = {
  rule: string;
  dates: string[];
  count: number;
  language: string;
};

/**
 * Processes the API response data, removing null/zero values, adding language info, and aggregating rules.
 * @param apiResponseData - API response data to be processed.
 * @returns A Promise that resolves to an array of AggregatedRule objects.
 */
export const processApiResponseData = async (
  apiResponseData: ApiDataResponse<any>,
  graphType?: GraphType
): Promise<AggregatedRule[] | undefined> => {
  // Early return if apiResponseData or apiResponseData.data is undefined
  if (!apiResponseData || !apiResponseData.data) {
    console.error('apiResponseData is undefined or contains no data');
    return [];
  }

  // Step 1: Remove null or 0 values from apiResponseData.data
  const cleanedData = removeNullOrZeroValues(apiResponseData.data);

  // Step 2: Get the current language setting
  const userLanguage = i18n.language || 'fr'; // Default to 'fr' if not set

  // Step 3: Add the language to each object before aggregation
  if (Array.isArray(cleanedData)) {
    cleanedData.forEach((item) => {
      if (typeof item === 'object' && item !== null) {
        item.language = userLanguage;
      }
    });
  } else if (typeof cleanedData === 'object' && cleanedData !== null) {
    cleanedData.language = userLanguage;
  } else {
    console.error('cleanedData is neither an array nor an object.');
    return [];
  }

  // Step 3: Aggregate data
  let aggregatedDataArray: AggregatedRule[] | undefined;
  const firstItem = cleanedData[0];

  if ('date' in firstItem) {
    // General aggregation
    aggregatedDataArray = aggregateRulesData(cleanedData);
  } else if ('hostname' in firstItem) {
    // Specific aggregation
    aggregatedDataArray = aggregateSpecificRuleData(cleanedData, graphType, userLanguage);
  } else {
    console.error('Unexpected data structure in apiResponseData');
    return [];
  }

  return aggregatedDataArray;
};
