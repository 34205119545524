import { useEffect, useState } from "react";
import { getAlerts } from "../../api/DetectionsRequests";
import { Interval } from "../../navigation/tabs/TabsTypes";
import { Alarm } from "../AlarmsListcomponents/AlarmsTable"; // Import Alarm interface

export const useAlarms = (start: number, end: number, serial?: string, type?: number) => {
  const [alarms, setAlarms] = useState<Alarm[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const loadDetections = async () => {
      setLoading(true);
      const response = await getAlerts(start, end, Interval.DAY, serial, type);
      setLoading(false);
      if (response.error) {
        console.error("Error loading detections: ", response.error);
        return;
      }

      // Check if response.data is defined before mapping
      if (response.data) {
        const transformedAlarms: Alarm[] = response.data.map(alarm => ({
          id: alarm.id.toString(), // Convert id from number to string
          securispot: alarm.securispot,
          date: alarm.date,
          description: alarm.description,
          duration: alarm.duration,
        }));

        setAlarms(transformedAlarms);
      } else {
        setAlarms([]); // Set an empty array if data is undefined
      }
    };

    loadDetections();
  }, [start, end, serial, type]);

  return { alarms, isLoading };
};
