// src/ai/pdf/generateChart/utils.ts

import jsPDF from 'jspdf';

/**
 * Adds a horizontal line to the PDF.
 * @param doc - jsPDF instance.
 * @param yPosition - Y position for the line.
 * @param margin - Margin for the line.
 * @param pageWidth - Width of the PDF page.
 * @param color - Color of the line.
 * @returns Updated Y position after rendering the line.
 */
export const addHorizontalLine = (
  doc: jsPDF,
  yPosition: number,
  margin: number,
  pageWidth: number,
  color: string = '#000000' // Default color: black
): number => {
  doc.setDrawColor(...hexToRgb(color));
  doc.setLineWidth(0.5);
  doc.line(margin, yPosition, pageWidth - margin, yPosition); // Draw horizontal line across the page
  return yPosition + 7; // Return updated yPosition with some space after the line
};

/**
 * Adds a section title to the PDF.
 * @param doc - jsPDF instance.
 * @param yPosition - Y position to start rendering the title.
 * @param margin - Margin to apply for the title.
 * @param title - Title text.
 * @param color - Text color.
 * @returns Updated Y position after rendering the title.
 */
export const addSectionTitle = (
  doc: jsPDF,
  yPosition: number,
  margin: number,
  title: string,
  color: string = '#004aac' // Default color: dark blue
): number => {
  doc.setFontSize(16);
  doc.setFont('Helvetica', 'bold');
  doc.setTextColor(...hexToRgb(color));
  doc.text(title, margin, yPosition);
  return yPosition + 10;
};

/**
 * Converts a hex color code to RGB array.
 * @param hex - Hex color code.
 * @returns Array containing RGB values.
 */
const hexToRgb = (hex: string): [number, number, number] => {
  // Remove '#' if present
  hex = hex.replace(/^#/, '');
  
  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  
  return [r, g, b];
};