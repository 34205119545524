export type AggregatedRule = {
    rule: string;
    count: number;
    language: string;
    dates: string[];
  };
  
  export const aggregateSpecificRuleData = (
    data: any[],
    graphType: number | undefined,
    userLanguage: string
  ): AggregatedRule[] => {
    // Mapping `graphType` to the corresponding rule
    const ruleMapping: { [key: number]: string } = {
      2: 'rule1',
      3: 'rule2',
      4: 'rule3',
      7: 'rule4',
      8: 'rule5',
    };
  
    const rule = ruleMapping[graphType || 0] || 'rule';
    const dates: string[] = [];
    let count = 0;
  
    // Iterate over specific data format to aggregate
    data.forEach((item) => {
      item.data.forEach((entry: { date: string; count: number }) => {
        if (entry.count > 0) {
          dates.push(entry.date);
          count += entry.count;
        }
      });
    });
  
    return [
      {
        rule,
        count,
        language: userLanguage,
        dates,
      },
    ];
  };
  