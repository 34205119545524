import React, { useState, useCallback, useEffect } from 'react';
import { Tree } from 'react-arborist';
import styles from './GlobaleView.module.css';
import { Node } from './Treeutils/helpers/types';
import { searchMatch } from './Treeutils/helpers/searchMatch';
import { useTreeHandlers } from './Treeutils/helpers/useTreeHandlers';
import TreeNode from './Treeutils/components/TreeNode';
import { NodeId } from './Treeutils/helpers/types';
import ConfirmationModal from './Treeutils/components/ConfirmationModal'; // Import the modal

interface TreeComponentProps {
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  searchTerm: string;
}

const TreeComponent: React.FC<TreeComponentProps> = ({
  data,
  setData,
  searchTerm,
}) => {
  const [editNodeId, setEditNodeId] = useState<NodeId | null>(null);
  const [newNodeName, setNewNodeName] = useState('');
  const [newlyAddedNodeId, setNewlyAddedNodeId] = useState<NodeId | null>(null);
  const [treeHeight, setTreeHeight] = useState<number>(window.innerHeight - 150);

  const [showModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState<any>(null);

  // Define showModalPromise before useTreeHandlers
  const showModalPromise = (props: any): Promise<boolean> => {
    return new Promise((resolve) => {
      setModalProps({
        ...props,
        onConfirm: () => {
          resolve(true);
          setShowModal(false);
        },
        onCancel: () => {
          resolve(false);
          setShowModal(false);
        },
      });
      setShowModal(true);
    });
  };

  useEffect(() => {
    const updateHeight = () => setTreeHeight(window.innerHeight);
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  // Call useTreeHandlers after showModalPromise is declared
  const {
    handleCancelEdit,
    handleDeleteNode,
    handleSaveEdit,
    handleEditNode,
    handleAddNode,
    handleAssociateToggle,
    handleMoveNode,
    handleSortChildren,
    sortingOrders,
  } = useTreeHandlers({
    data,
    setData,
    editNodeId,
    setEditNodeId,
    newNodeName,
    setNewNodeName,
    newlyAddedNodeId,
    setNewlyAddedNodeId,
    showModal: showModalPromise, // Pass the modal function as showModal
  });

  const onMove = useCallback(
    async (params) => {
      await handleMoveNode(params);
    },
    [handleMoveNode]
  );

  return (
    <>
      <div className={styles.treeContainer}>
        <Tree
          data={data}
          onMove={onMove}
          openByDefault={true}
          rowHeight={30}
          width={'100%'}
          height={treeHeight}
          indent={20}
          searchTerm={searchTerm}
          searchMatch={searchMatch}
          padding={10}
          paddingTop={15}
          paddingBottom={15}
          className={styles.treeComponent}
        >
          {(nodeProps) => (
            <TreeNode
              {...nodeProps}
              editNodeId={editNodeId}
              newNodeName={newNodeName}
              newlyAddedNodeId={newlyAddedNodeId}
              setNewNodeName={setNewNodeName}
              handleSaveEdit={handleSaveEdit}
              handleCancelEdit={handleCancelEdit}
              handleEditNode={handleEditNode}
              handleDeleteNode={handleDeleteNode}
              handleAddNode={handleAddNode}
              handleAssociateToggle={handleAssociateToggle}
              handleSortChildren={handleSortChildren}
              sortingOrder={sortingOrders[nodeProps.node.id]}
            />
          )}
        </Tree>
      </div>

      {showModal && (
        <ConfirmationModal
          nodeName={modalProps.nodeName}
          fromCompany={modalProps.fromCompany}
          toCompany={modalProps.toCompany}
          onConfirm={modalProps.onConfirm}
          onCancel={modalProps.onCancel}
        />
      )}
    </>
  );
};

export default TreeComponent;
