import { DateTime } from "luxon";
import { GraphType } from "../graph/GraphTypes";
import { convertUTCToLocal } from "./DateHelper";
import { Interval } from "../navigation/tabs/TabsTypes";
import { ApiDataResponse } from "../api/Request";
type DataAlarmEvolutionItem = {
  date: string;
  rule1: number;
  rule2: number;
  rule3: number;
  rule4: number;
  rule5: number;
};

export type DataAlarmEvolutionType = {
  error?: string;
  data?: DataAlarmEvolutionItem[];
};

type DataByHostnameItem = {
  date: string;
  count: number;
};

type DataByHostname = {
  hostname: string;
  data: DataByHostnameItem[];
};

export type DataDefaultItem = {
  error?: string;
  data?: DataByHostname[];
};

const processAlarmsEvolutionData = (
  data: DataAlarmEvolutionType,
  interval: Interval,
  startDate: number,
  endDate: number
) => {
  if (!data.data || data.data.length === 0) {
    // Handle empty data case
    return { error: "No data to process" };
  }
  if (interval === Interval.HOUR) {
    data.data = data.data.map((item) => ({
      ...item,
      date: convertUTCToLocal(item.date) || "",
    }));
  }
  if (interval === Interval.DAY) {
    const firstDateTimestamp = DateTime.fromISO(data.data[0].date).toMillis();
    const lastDateTimestamp = DateTime.fromISO(
      data.data[data.data.length - 1].date
    ).toMillis();

    if (firstDateTimestamp < startDate) {
      data.data[1].rule1 += data.data[0].rule1;
      data.data[1].rule2 += data.data[0].rule2;
      data.data[1].rule3 += data.data[0].rule3;
      data.data[1].rule4 += data.data[0].rule4;
      data.data[1].rule5 += data.data[0].rule5;
      data.data.shift();
    }

    if (lastDateTimestamp > endDate) {
      const lastIndex = data.data.length - 1;
      data.data[lastIndex - 1].rule1 += data.data[lastIndex].rule1;
      data.data[lastIndex - 1].rule2 += data.data[lastIndex].rule2;
      data.data[lastIndex - 1].rule3 += data.data[lastIndex].rule3;
      data.data[lastIndex - 1].rule4 += data.data[lastIndex].rule4;
      data.data[lastIndex - 1].rule5 += data.data[lastIndex].rule5;
      data.data.pop();
    }
  }
  return data;
};

const processDefaultData = (
  data: DataDefaultItem,
  interval: Interval,
  startDate: number,
  endDate: number
) => {
  if (!data.data || data.data.length === 0) return data;
  if (interval === Interval.HOUR) {
    data.data = data.data.map((item) => ({
      ...item,
      data: item.data.map((dataItem) => ({
        ...dataItem,
        date: convertUTCToLocal(dataItem.date) || "",
      })),
    }));
  }
  if (interval === Interval.DAY) {
    data.data.forEach((element) => {
      const firstDateTimestamp = DateTime.fromISO(
        element.data[0].date
      ).toMillis();
      const lastDateTimestamp = DateTime.fromISO(
        element.data[element.data.length - 1].date
      ).toMillis();

      if (firstDateTimestamp < startDate) {
        element.data[1].count += element.data[0].count;
        element.data.shift();
      }

      if (lastDateTimestamp > endDate) {
        const lastIndex = element.data.length - 1;
        element.data[lastIndex - 1].count += element.data[lastIndex].count;
        element.data.pop();
      }
    });
  }
  return data;
};

const dataProcessingConfig = {
  evolution: processAlarmsEvolutionData,
  default: processDefaultData,
};

export const processData = (
  graphType: GraphType,
  data: DataAlarmEvolutionType | DataDefaultItem,
  interval: Interval,
  startDate: number,
  endDate: number
): ApiDataResponse<any>  => { // <-- Updated return type to ApiDataResponse<any>
  const dataProcessor =
    graphType === GraphType.ALARMS_EVOLUTION
      ? dataProcessingConfig.evolution
      : dataProcessingConfig.default;

  const processedResult = dataProcessor(
    data as DataAlarmEvolutionType & DataDefaultItem,
    interval,
    startDate,
    endDate
  );

  return {
    error: processedResult?.error || '',
    data: processedResult?.data || [], // Always provide data as an array
  };
};
