
import i18n from "../../../../i18n";
// Helper to format date
export const formatDate = (date: Date): string => {
  const days = [i18n.t("Sunday"), i18n.t("Monday"), i18n.t("Tuesday"), i18n.t("Wednesday"), i18n.t("Thursday"), i18n.t("Friday"), i18n.t("Saturday")];
  
  // JavaScript's getDay() method returns 0 for Sunday, 1 for Monday, etc.
  // To adjust for your calendar starting on Monday:
  const dayIndex = date.getDay(); // Get the day of the week
  return `${days[dayIndex]}<br>${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
};

/**
 * Calculates the week number for a given date.
 * @param dateString - The date string to calculate the week number from.
 * @returns The week number of the year.
 */
export const calculateWeekNumber = (dateString: string): number => {
  const startDate = dateString ? new Date(dateString) : new Date();

  const firstDayOfYear = new Date(startDate.getFullYear(), 0, 1);
  const dayOfYear = Math.floor((startDate.getTime() - firstDayOfYear.getTime()) / 86400000) + 1;
  const weekNumber = Math.ceil((dayOfYear + firstDayOfYear.getDay()) / 7);

  return weekNumber;
};
