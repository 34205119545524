import { formatDate } from './dateshelpers/formatDate';

/**
 * Prepares the heatmap data based on cleaned input data.
 * Converts the counts to percentages and dynamically calculates the range of hours based on the data.
 * Percentages are calculated based on the distribution of alerts within each day.
 * @param cleanedData - Array of cleaned data objects.
 * @returns An object containing populated heatmapData (as percentages), formatted dates, hours, zmin, and zmax.
 */
export const prepareHeatmapData = async (
  cleanedData: { rule: string | undefined; dates: string[] | undefined; count: number; language: string }[]
): Promise<{ heatmapData: number[][]; dates: string[]; hours: string[]; zmin: number; zmax: number }> => {

  // Validate cleanedData input
  if (!Array.isArray(cleanedData) || cleanedData.length === 0) {
    throw new Error('Invalid or empty cleanedData array.');
  }

  // Initialize variables for tracking the min/max hours and dates
  let minHour = 24; // Arbitrarily large
  let maxHour = 0;  // Arbitrarily small
  const dateSet = new Set<string>(); // Set to store unique date strings
  const heatmapData: number[][] = [];

  let globalMin = Infinity;
  let globalMax = -Infinity;
  const dailySums: { [date: string]: number } = {}; // Object to track total counts for each day

  // Iterate through cleanedData and process each rule
  cleanedData.forEach(({ rule, dates: ruleDates, count }) => {
    if (!rule || !Array.isArray(ruleDates) || ruleDates.length === 0) {
      console.warn(`Skipping entry for rule "${rule ?? 'undefined'}" due to missing or invalid dates.`);
      return;
    }
    // Iterate through each date in ruleDates
    ruleDates.forEach((dateStr) => {
      if (!dateStr || isNaN(new Date(dateStr).getTime())) {
        console.warn(`Invalid date encountered for rule "${rule}":`, dateStr);
        return;
      }

      // Parse the date and add to the set of unique dates
      const date = new Date(dateStr);
      const formattedDate = formatDate(date);
      dateSet.add(formattedDate); // Collect unique dates

      const hour = date.getHours();
      minHour = Math.min(minHour, hour); // Track earliest hour
      maxHour = Math.max(maxHour, hour); // Track latest hour

      // Initialize the dailySums object for this date if it doesn't exist
      if (!dailySums[formattedDate]) {
        dailySums[formattedDate] = 0;
      }
      dailySums[formattedDate] += count; // Track the sum of counts for each day

      const hourIndex = hour - minHour; // Shift hour index dynamically based on minHour

      // Ensure heatmapData rows are initialized to accommodate all date columns
      if (!heatmapData[hourIndex]) {
        heatmapData[hourIndex] = [];
      }

      const dayIndex = Array.from(dateSet).indexOf(formattedDate); // Calculate the day index dynamically

      // Ensure the dayIndex is accounted for in the heatmapData
      while (heatmapData[hourIndex].length <= dayIndex) {
        heatmapData[hourIndex].push(0);
      }

      // Add count to the heatmap data for the appropriate hour and date index
      heatmapData[hourIndex][dayIndex] += count;

      // Update global min and max for z-scaling
      globalMin = Math.min(globalMin, heatmapData[hourIndex][dayIndex]);
      globalMax = Math.max(globalMax, heatmapData[hourIndex][dayIndex]);
    });
  });

  // Define the dynamic range of hours based on the data
  const hours = Array.from({ length: maxHour - minHour + 1 }, (_, i) => `${i + minHour}:00`);

  // Convert heatmap data to percentages based on the total for each day
  heatmapData.forEach((hourData, hourIndex) => {
    hourData.forEach((value, dayIndex) => {
      const dateKey = Array.from(dateSet)[dayIndex];
      const totalForDay = dailySums[dateKey];
      if (totalForDay > 0) {
        heatmapData[hourIndex][dayIndex] = (value / totalForDay) * 100; // Convert to percentage per day
      }
    });
  });

  // If no valid data points, set default values for zmin and zmax
  const zmin = globalMin === Infinity ? 0 : 0; // Since we are working with percentages, zmin is 0
  const zmax = 100; // Since we are converting to percentages, zmax is always 100

  // Sort the unique dates in ascending order and return them as the dates array
  const dates = Array.from(dateSet).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

  // Return the heatmap data along with the calculated min and max for the z-axis
  return { heatmapData, dates, hours, zmin, zmax }; // zmin and zmax are now percentage-based
};
