// ./src/graph/graphprops/StatusEvolutionProps.ts
import { ChartConfiguration, ChartDataSets } from "chart.js";
import { Interval } from "../../navigation/tabs/TabsTypes";
import xAxeTimeDefaultProps from "./XAxeTimeDefaultProps";
import { getDateRange } from "./helpers/minmaxDates";
import { sortDatasetsByMaxValue } from "./helpers/sortDatasetsByMaxValue";

export const graphProps = (
  dates: Date[],
  interval: Interval,
  startDateTimestamp: number,
  endDateTimestamp: number,
  datasets: ChartDataSets[]
): ChartConfiguration => {
  const { minDate, maxDate } = getDateRange(dates);
  const sortedDatasets = sortDatasetsByMaxValue(datasets);
  
  const props: ChartConfiguration = {
    type: "line",
    data: { datasets: sortedDatasets }, // Use sorted datasets
    options: {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: true,
        text: "Status Evolution", // Update as needed
        fontSize: 18,
        fontColor: '#333',
        fontStyle: 'bold',
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: '#333',
          fontSize: 12,
          usePointStyle: true,
        },
      },
      layout: {
        padding: {
          left: 10,
          right: 10,
          top: 15,
          bottom: 15,
        },
      },
      scales: {
        xAxes: [
          xAxeTimeDefaultProps(
            minDate,
            maxDate,
            interval,
            startDateTimestamp,
            endDateTimestamp
          ),
        ],
        yAxes: [
          {
            gridLines: {
              color: '#e0e0e0',
              zeroLineColor: '#e0e0e0',
              borderDash: [2, 2],
            },
            ticks: {
              min: 0,
              max: 1,
              stepSize: 1,
              fontColor: '#333',
            },
            display: false, // Hide y-axis labels as required by default
          },
        ],
      },
      tooltips: {
        enabled: true,
        mode: 'index',
        intersect: false,
        backgroundColor: '#004aac',
        titleFontColor: '#fff',
        bodyFontColor: '#fff',
        footerFontColor: '#fff',
      },
    },
  };

  return props;
};
