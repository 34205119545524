import Plotly from 'plotly.js';

/**
 * Renders the heatmap using Plotly.
 * @param container - The HTML element to render the heatmap in.
 * @param trace - The trace configuration for the heatmap.
 * @param layout - The layout configuration for the heatmap.
 * @param config - The configuration for Plotly behavior.
 * @returns Promise<void>
 */
export const renderPlotlyHeatmap = async (
  container: HTMLElement,
  trace: Partial<Plotly.PlotData>,
  layout: Partial<Plotly.Layout>,
  config: Partial<Plotly.Config>
): Promise<void> => {
  try {
    await Plotly.newPlot(container, [trace], layout, config);
    console.log("Plotly rendering completed");
  } catch (error) {
    console.error('Error during Plotly rendering:', error);
    throw error;
  }
};

/**
 * Converts the rendered Plotly chart to a base64 image.
 * @param container - The container with the rendered Plotly chart.
 * @returns A base64 image string.
 */
export const convertPlotlyToImage = async (container: HTMLElement): Promise<string> => {
    try {
      const chartImage = await Plotly.toImage(container, {
        format: 'png',
        width: 800,
        height: 600,
      });
      console.log("Image generated from Plotly chart");
      return chartImage;
    } catch (error) {
      console.error('Error converting Plotly chart to image:', error);
      throw error;
    }
  };
  