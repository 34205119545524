import React, { useState, useMemo, useEffect, useRef } from "react";
import { ColumnInstance, Row } from "react-table";
import classes from "./DropdownFilter.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faFilter,
  faSearch,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { Alarm } from "./AlarmsTable";
import i18n from "../../i18n";
import { createPortal } from "react-dom";

interface DropdownFilterProps {
  column: ColumnInstance<Alarm> & {
    preFilteredRows: Row<Alarm>[];
    filterValue: any;
    setFilter: (filterValue: any) => void;
    toggleSortBy?: (desc: boolean) => void;
    dataType?: string;
  };
  showFilterButton: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

const DropdownFilter: React.FC<DropdownFilterProps> = ({
  column,
  showFilterButton,
  onOpenChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const portalContainer = useRef<HTMLDivElement | null>(null);

  const dataType = column.dataType;

  // For time filter
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  // For text and number filters
  const [searchValue, setSearchValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  // Synchronize startTime and endTime with column.filterValue
  useEffect(() => {
    if (dataType === "time") {
      const currentFilter = column.filterValue || {};
      setStartTime(currentFilter.min || "");
      setEndTime(currentFilter.max || "");
    }
  }, [column.filterValue, dataType]);

  // Get unique values for text and number columns
  const options = useMemo(() => {
    if (dataType === "text" || dataType === "number") {
      const optionsSet = new Set<string>();
      column.preFilteredRows.forEach((row) => {
        const value = row.values[column.id];
        optionsSet.add(value != null ? String(value) : "");
      });
      return Array.from(optionsSet);
    }
    return [];
  }, [column.preFilteredRows, column.id, dataType]);

  // Filtered options based on searchValue
  const filteredOptions = useMemo(() => {
    return options.filter((option) =>
      option.toLowerCase().includes(searchValue.toLowerCase())
    );
  }, [options, searchValue]);

  const handleCheckboxChange = (value: string) => {
    setSelectedValues((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((val) => val !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  // Apply filter when selectedValues change
  useEffect(() => {
    if (dataType === "text" || dataType === "number") {
      column.setFilter(selectedValues.length ? selectedValues : undefined);
    }
  }, [selectedValues, column, dataType]);

  const handleFilterChange = () => {
    if (dataType === "time") {
      column.setFilter(
        startTime || endTime ? { min: startTime, max: endTime } : undefined
      );
    }
    setIsOpen(false); // Close the dropdown after applying the filter
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Notify parent when isOpen changes
  useEffect(() => {
    if (onOpenChange) {
      onOpenChange(isOpen);
    }
  }, [isOpen, onOpenChange]);

  // Create portal container
  useEffect(() => {
    portalContainer.current = document.createElement("div");
    document.body.appendChild(portalContainer.current);
    return () => {
      if (portalContainer.current) {
        document.body.removeChild(portalContainer.current);
        portalContainer.current = null;
      }
    };
  }, []);

  // Calculate the position of the dropdown
  const [dropdownStyles, setDropdownStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (isOpen && buttonRef.current && portalContainer.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownHeight = 300; // approximate height
      const dropdownWidth = 250; // same as in CSS

      let top = buttonRect.bottom + window.scrollY;
      let left = buttonRect.left + window.scrollX;

      if (buttonRect.bottom + dropdownHeight > window.innerHeight) {
        top = buttonRect.top - dropdownHeight + window.scrollY;
      }

      if (buttonRect.left + dropdownWidth > window.innerWidth) {
        left = buttonRect.right - dropdownWidth + window.scrollX - dropdownWidth;
      }

      setDropdownStyles({
        position: "absolute",
        top: `${top}px`,
        left: `${left}px`,
        zIndex: 1000,
      });
    }
  }, [isOpen]);

  // Dropdown content
  const dropdownContent = isOpen ? (
    <div
      className={`${classes.FilterDropdown}`}
      style={dropdownStyles}
      ref={dropdownRef}
      onMouseLeave={() => setIsOpen(false)} // Close on mouse leave
    >
      {/* Close Button */}
      <button
        className={classes.CloseButton}
        onClick={() => setIsOpen(false)}
        aria-label="Close Filter Dropdown"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {/* Sorting Buttons */}
      {column.toggleSortBy && (
        <div className={classes.SortButtons}>
          <button
            onClick={() => column.toggleSortBy!(false)}
            className={classes.SortButton}
            title="Sort Ascending"
            aria-label="Sort Ascending"
          >
            <FontAwesomeIcon
              icon={
                dataType === "text" ? faSortAlphaDown : faSortNumericDown
              }
            />
          </button>
          <button
            onClick={() => column.toggleSortBy!(true)}
            className={classes.SortButton}
            title="Sort Descending"
            aria-label="Sort Descending"
          >
            <FontAwesomeIcon
              icon={dataType === "text" ? faSortAlphaUp : faSortNumericUp}
            />
          </button>
        </div>
      )}

      {dataType === "time" && (
        <div className={classes.TimeFilter}>
          <div className={classes.TimeInputContainer}>
            <label>{i18n.t("Start Time:")}</label>
            <input
              type="time"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className={classes.TimeInput}
            />
          </div>
          <div className={classes.TimeInputContainer}>
            <label>{i18n.t("End Time:")}</label>
            <input
              type="time"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className={classes.TimeInput}
            />
          </div>
          <button
            onClick={handleFilterChange}
            className={classes.ApplyButton}
          >
            {i18n.t("Apply Filters")}
          </button>
          <button
            onClick={() => {
              setStartTime("");
              setEndTime("");
              column.setFilter(undefined);
              setIsOpen(false); // Close the dropdown after clearing the filter
            }}
            className={classes.ClearButton}
          >
            {i18n.t("Clear Filter")}
          </button>
        </div>
      )}

      {(dataType === "text" || dataType === "number") && (
        <div className={classes.CheckboxFilter}>
          {/* Search bar */}
          <div className={classes.SearchInputContainer}>
            <FontAwesomeIcon
              icon={faSearch}
              className={classes.SearchIcon}
            />
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Search..."
              className={classes.SearchInput}
              aria-label="Search Options"
            />
          </div>
          {/* Checkbox list */}
          <div className={classes.CheckboxList}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <label key={option} className={classes.CheckboxLabel}>
                  <input
                    type="checkbox"
                    value={option}
                    checked={selectedValues.includes(option)}
                    onChange={() => handleCheckboxChange(option)}
                  />
                  {option}
                </label>
              ))
            ) : (
              <div className={classes.NoOptions}>
                {i18n.t("No options found.")}
              </div>
            )}
          </div>
          <button
            onClick={() => {
              setSelectedValues([]);
              setSearchValue("");
              column.setFilter(undefined);
              setIsOpen(false); // Close the dropdown after clearing the filter
            }}
            className={classes.ClearButton}
          >
            <FontAwesomeIcon icon={faTimes} /> {i18n.t("Clear Filter")}
          </button>
        </div>
      )}
    </div>
  ) : null;

  return (
    <div className={classes.FilterContainer}>
      {showFilterButton && (
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          className={classes.FilterButton}
          aria-label="Toggle Filter Dropdown"
          aria-expanded={isOpen}
          ref={buttonRef}
        >
          <FontAwesomeIcon icon={faFilter} />
        </button>
      )}
      {portalContainer.current &&
        createPortal(dropdownContent, portalContainer.current)}
    </div>
  );
};

export default DropdownFilter;
