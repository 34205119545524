import {useDrop} from 'react-dnd'
import {NodeType} from "../NodeType";
import classes from "./DroppableArea.module.css"
import {associateDevice, associateSecurispot} from "../../../api/DevicesRequests"
import {getClientUUID} from "../../../api/Auth"
import {getI18n} from "react-i18next";

/*
Drag and drop :

- Can be done between a mediaspot a area node
    - If not is allowed for current user
    - If node doesn't already has an associated mediaspot

- Can be done between any device (except mediaspot) and its parent device
    - Modac => mediaspot
        - Number of modac/mediaspot associations is not limited
    - Radiameter => modac
        - Only one radiameter can be associated to modac
    - Probe => Radiameter
        Number of probe is limited with maxNbProbes property of radiameter

- Can be done between two devices of same type for a replacement

/!\ Association with archive node is not possible
/!\ Association with device is store node is not possible

 */

export default function DroppableArea({node, isArchive, deviceId, hasMediaspot, dropAllowed, nodeType, leftPadding, path, onItemDropped, onItemDroppedForReplacement, reference }) {

    const [ {draggingItem, isOver, canDrop, isReplacement}, drop] = useDrop({
        accept: "device",
        canDrop:(dragObject, monitor) => {
            // If drop is node allowed (not in root node user for example), disable drop
            if(dropAllowed !== undefined && !dropAllowed){
                return false
            }

            // If droppable area is an archive node, disable drop
            if(isArchive !== undefined && isArchive === true){
                return false
            }

            // Allow mediaspot droppping on area only if this area doesn't have mediaspot yet
            if(dragObject.subtype === NodeType.MEDIASPOT && nodeType === NodeType.AREA){
                return !hasMediaspot
            }
            // If drag object on another object of the same type to replace it
            if(dragObject.subtype === nodeType){
                return true
            }
            return false
        },
        collect: monitor => ({
            dropHandlerId: monitor.getHandlerId(),
            draggingItem: monitor.getItem(),
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
            isReplacement: (monitor.getItem() !== null && monitor.getItem().subtype === nodeType)
        }),
        drop:async(dragObject, monitor) => {
            console.log("drop called ") 
            // Replacement
            if(dragObject.subtype === nodeType){
                if(window.confirm(`${getI18n().t("AreYouSureYouWantToReplace")} ${reference} ${getI18n().t("With")} ${dragObject.fullObject.reference} ?`)) {
                    onItemDroppedForReplacement(dragObject.fullObject.pk, deviceId)
                }
            }else{ // Association
                let response = undefined;
                if(dragObject.subtype === NodeType.MEDIASPOT){
                    console.log("associate 1 ")
                    const lastPathComponent = path.split("|").pop()
                    response = await associateSecurispot(dragObject.id, lastPathComponent, getClientUUID())
                }else {
                    response = await associateDevice(dragObject.id, deviceId, getClientUUID())
                }
                if(response !== undefined && response.error !== undefined){
                    alert(getI18n().t("AnErrorOccurred"))
                }else {
                    dragObject.isDeployed = true
                    dragObject.associatedPath = path
                    dragObject.update(dragObject)
                    onItemDropped(dragObject.subtype, dragObject.fullObject)

                    // When drag object is removed from devices a list, dragend is not called because of source removing. Call it manually to be sure, it'll be called
                    document.dispatchEvent(new Event("dragend"))

                }
            }

        }
    })

    // useImperativeHandle(drop, () => ({
    //     getDropHandlerId: () => dropHandlerId
    // }))
    let overlayColor = "transparent"
    if(isOver){
        if(isReplacement === true){
            overlayColor = canDrop === true ? "rgba(255,122,0,0.3)" : "rgba(229,8,8,0.30)"
        }else {
            overlayColor = canDrop === true ? "rgba(67,229,8, 0.30)" : "rgba(229,8,8,0.30)"
        }
    }
    return draggingItem === null ? <></> : <div className={classes.DroppableAreaOverlay} ref={drop} style={{backgroundColor: overlayColor, width:`calc(100% - ${leftPadding}px)` }}/>
}
