// src/GlobalVue/Treeutils/helpers/treeData.ts

import { callTreebuilder } from "./api/TreeInitialDataapi";
import { Node } from "./types";
export const initialData = async (): Promise<Node[]> => {
    // Fetch the tree data from the backend
    const fetchedTreeData = await callTreebuilder();

    if (fetchedTreeData) {
      return fetchedTreeData;
      } else {
    // Fallback data in case fetching fails
    return [
      {
        id: '1',
        name: 'Parent Node 1',
        hello:"Hello",
        children: [
          {
            id: '1-1',
            name: 'Child Node 1.1',
          },
          {
            id: '1-2',
            name: 'Child Node 1.2',
            children: [
              {
                id: '1-2-1',
                name: 'Child Node 1.2.1',
                children: [
                  {
                    id: '1-2-1-1',
                    name: 'Child Node 1.2.1.1',
                  },
                  {
                    id: '1-2-1-2',
                    name: 'Child Node 1.2.1.2',
                  },
                  {
                    id: '1-2-1-3',
                    name: 'Child Node 1.2.1.3',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: '2',
        name: 'Parent Node 2',
      },
    ];
  };
}