import i18n from '../../../i18n';

/**
 * Creates the trace configuration for the Plotly heatmap.
 * @param heatmapData - The heatmap data to be plotted.
 * @param dates - The dates for the x-axis.
 * @param hours - The hours for the y-axis.
 * @param zmin - The minimum z value for the heatmap.
 * @param zmax - The maximum z value for the heatmap.
 * @param showAlertCount - Whether to show alert count or percentage.
 * @returns Partial<Plotly.PlotData> - The trace configuration for the heatmap.
 */
export const createHeatmapTrace = (
  heatmapData: number[][],
  dates: string[],
  hours: string[],
  zmin: number,
  zmax: number,
  showAlertCount: boolean
): Partial<Plotly.PlotData> => {
  return {
    z: heatmapData,
    x: dates,
    y: hours,
    type: 'heatmap',
    colorscale: 'Reds',
    zmin,
    zmax,
    xgap: 2, // Adds gaps between columns
    ygap: 2, // Adds gaps between rows
    colorbar: {
      title: showAlertCount ? i18n.t("Number of alerts") : i18n.t("Percentage of alerts"),
      titleside: 'right',
    },
  };
};

/**
 * Creates the layout configuration for the Plotly heatmap.
 * @param dates - The dates for the x-axis.
 * @param hours - The hours for the y-axis.
 * @returns Partial<Plotly.Layout> - The layout configuration for the heatmap.
 */
export const createHeatmapLayout = (
  dates: string[],
  hours: string[]
): Partial<Plotly.Layout> => {
  return {
    title: i18n.t("Alerts by day and time"),
    xaxis: {
      title: i18n.t("Days of the week"),
      tickmode: 'array',
      tickvals: dates,
    },
    yaxis: {
      title: i18n.t("Hours of the day"),
      tickmode: 'array',
      tickvals: hours,
      autorange: 'reversed', // Reverses the y-axis to make hours descending
    },
    plot_bgcolor: 'white', // Set the plot background color to white
    paper_bgcolor: 'white', // Set the paper background color to white
  };
};

/**
 * Creates the configuration for Plotly behavior.
 * @returns Partial<Plotly.Config> - The configuration for Plotly behavior.
 */
export const createHeatmapConfig = (): Partial<Plotly.Config> => {
  return {
    displayModeBar: false,
    staticPlot: true,
  };
};
