import React, {
  useMemo,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  Column,
  Row,
  FilterTypes,
} from "react-table";
import { DateTime } from "luxon";
import i18n from "../../i18n";
import classes from "../AlarmsList.module.css";
import { extractDescriptionAndPeople } from "../AlarmsListUtils/alarmHelpers";
import DropdownFilter from "./DropdownFilter";
import { removeLeadingNumber } from "../AlarmsListUtils/removeLeadingNumber";

export interface Alarm {
  id: string;
  securispot: string;
  date: string;
  description: any;
  duration: number;
}

export interface AlarmsTableHandle {
  clearAllFilters: () => void;
}

interface AlarmsTableProps {
  alarms: Alarm[];
  onFiltersChange?: (hasFilters: boolean) => void;
}

export const AlarmsTable = forwardRef<AlarmsTableHandle, AlarmsTableProps>(
  ({ alarms, onFiltersChange }, ref) => {
    // State to manage hover over column headers
    const [hoveredColumnId, setHoveredColumnId] = useState<string | null>(null);

    // State to manage if any dropdown is open
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    // Define the columns for the table
    const columns: Column<Alarm>[] = useMemo(
      () => [
        {
          Header: i18n.t("Securispot"),
          accessor: "securispot",
          canFilter: true,
          dataType: "text",
          filter: "includes",
        },
        {
          Header: i18n.t("Date"),
          accessor: "date",
          Cell: ({ value }) => (
            <>{DateTime.fromISO(value).toFormat("HH:mm")}</>
          ),
          canFilter: true,
          dataType: "time",
          filter: "timeRange",
        },
        {
          Header: i18n.t("Nombre de personnes"),
          accessor: (row) => {
            const { nbPersons } = extractDescriptionAndPeople(row.description);
            return nbPersons;
          },
          id: "nbPersons",
          Cell: ({ value }: { value: number }) => <>{value}</>,
          canFilter: true,
          dataType: "number",
          filter: "includes",
        },
        {
          Header: i18n.t("Description"),
          accessor: (row) => {
            const { statusDescription } = extractDescriptionAndPeople(
              row.description
            );
            return removeLeadingNumber(statusDescription);
          },
          id: "description",
          Cell: ({ value }: { value: string }) => <>{value}</>,
          canFilter: true,
          dataType: "text",
          filter: "includes",
        },
        {
          Header: `${i18n.t("Duration")} (sec)`,
          accessor: "duration",
          Cell: ({ value }) => <>{value.toFixed(2)}</>,
          canFilter: true,
          dataType: "number",
          filter: "includes",
        },
      ],
      []
    );

    // Prepare the data for the table
    const data = useMemo(
      () => alarms.filter((alarm) => alarm.duration > 0),
      [alarms]
    );

    // Define custom filter types
    const filterTypes: FilterTypes<Alarm> = useMemo(
      () => ({
        timeRange: (
          rows: Array<Row<Alarm>>,
          columnIds: string[],
          filterValue: { min: string; max: string }
        ) => {
          return rows.filter((row) => {
            // Extract time from the 'date' field
            const rowTime = DateTime.fromISO(row.original.date).toFormat("HH:mm");

            const minTime = filterValue.min ? filterValue.min : null;
            const maxTime = filterValue.max ? filterValue.max : null;

            if (minTime && rowTime < minTime) {
              return false;
            }
            if (maxTime && rowTime > maxTime) {
              return false;
            }
            return true;
          });
        },
        includes: (
          rows: Array<Row<Alarm>>,
          columnIds: string[],
          filterValue: string[]
        ) => {
          return rows.filter((row) => {
            const id = columnIds[0];
            const rowValue = row.values[id];
            if (filterValue.length === 0) {
              return true;
            }
            return filterValue.includes(String(rowValue));
          });
        },
      }),
      []
    );

    // Initialize the table instance
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      setAllFilters,
      state: { filters },
    } = useTable(
      { columns, data, filterTypes },
      useFilters,
      useSortBy
    );

    // Expose methods to parent via ref
    useImperativeHandle(ref, () => ({
      clearAllFilters: () => {
        setAllFilters([]);
      },
    }));

    // Notify parent when filters change
    useEffect(() => {
      if (onFiltersChange) {
        onFiltersChange(Object.keys(filters).length > 0);
      }
    }, [filters, onFiltersChange]);

    return (
      <table
        {...getTableProps()}
        className={`${classes.Table} ${
          isFilterOpen ? classes.TableBlurred : ""
        }`}
      >
        {/* Render table headers */}
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  className={classes.HeaderCell}
                  onMouseEnter={() => setHoveredColumnId(column.id)}
                  onMouseLeave={() => setHoveredColumnId(null)}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {column.render("Header")}
                    {column.canFilter ? (
                      <DropdownFilter
                        column={column}
                        showFilterButton={hoveredColumnId === column.id}
                        onOpenChange={setIsFilterOpen}
                      />
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {/* Render table body */}
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            // If there are rows, render them
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })
          ) : (
            // If no data, render 20 empty rows
            [...Array(20)].map((_, rowIndex) => (
              <tr key={`empty-row-${rowIndex}`}>
                {columns.map((column, colIndex) => (
                  <td key={`empty-cell-${colIndex}-${rowIndex}`}></td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    );
  }
);
