import { DatePicker } from "rsuite";
import i18n from "../../i18n";
import { startOfDay, subDays } from "date-fns";
import styles from "./DatePicker.module.css"; // Import DatePicker-specific styles
import classes from "../AlarmsList.module.css"; // Import AlarmsList styles for cohesion

interface DatePickerComponentProps {
  start?: number;
  onDateChange: (date: Date | undefined) => void;
}

const DatePickerComponent = ({ start, onDateChange }: DatePickerComponentProps) => {
  const customLocale = {
    sunday: i18n.t("Su"),
    monday: i18n.t("Mo"),
    tuesday: i18n.t("Tue"),
    wednesday: i18n.t("Wed"),
    thursday: i18n.t("Thu"),
    friday: i18n.t("Fri"),
    saturday: i18n.t("Sat"),
    ok: "OK",
    today: i18n.t("Today"),
    yesterday: i18n.t("Yesterday"),
    hours: i18n.t("Hours"),
    minutes: i18n.t("Minutes"),
    seconds: i18n.t("Seconds"),
  };

  return (
    <div className={`${classes.HeadRow} ${styles.datePickerWrapper}`}>
      <DatePicker
        className={`${styles.datePickerInput} ${classes.DatePicker}`}
        locale={customLocale}
        disabledDate={(date) => {
          const firstAllowed = new Date(2023, 0, 1);
          return date!.getTime() > Date.now() || date!.getTime() < firstAllowed.getTime();
        }}
        placement="bottomEnd"
        cleanable={false}
        value={start !== undefined ? new Date(start) : undefined}
        placeholder={i18n.t("SelectPeriod")}
        onChange={onDateChange}
        ranges={[
          { label: i18n.t("Today"), value: startOfDay(new Date()) },
          { label: i18n.t("Yesterday"), value: startOfDay(subDays(new Date(), 1)) },
        ]}
      />
    </div>
  );
};

export default DatePickerComponent;
