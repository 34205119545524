import React, { useMemo } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  Column,
  Row,
  TableInstance,
} from 'react-table';
import styles from './GlobaleView.module.css'
import { tableData, tableColumns } from './Tableutils/tableData'; // Correct path
import { GlobalFilter } from './Tableutils/filters'; // Correct path
import { TableData } from './Tableutils/types'; // Correct path


const TableComponent: React.FC = () => {
  const data = useMemo(() => tableData, []);
  const columns: Column<TableData>[] = useMemo(() => tableColumns, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { globalFilter },
  } = useTable<TableData>(
    { columns, data },
    useFilters,
    useGlobalFilter
  );

  return (
    <div className={styles.tableContainer}>
      {/* Global search filter */}
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows as Row<TableData>[]}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  {/* Render the column filter UI */}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })
          ) : (
            // No data message
            <tr>
              <td colSpan={columns.length} className={styles.noData}>
                No matching records found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent;
