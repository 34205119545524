import {
  faBell,
  faBinoculars,
  faHardHat,
  faPersonFalling,
  faWarehouse,
  faTruck
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import Tabs from "../navigation/tabs/Tabs";
import { Interval, TabItem } from "../navigation/tabs/TabsTypes";
import { AlarmsCountCard } from "../stats/AlarmsCountCard";
import { AlarmsList } from "../stats/AlarmsList";
import { AreaInStats } from "../stats/AreaInStats";
import { GlobalDashboardStats } from "../stats/GlobalDashboard";
import { HatWearingStats } from "../stats/HatWearingStats";
import { LastEventDateCard } from "../stats/LastEventDateCard";
import { LyingPersonStats } from "../stats/LyingPersonStats";
import classes from "./Home.module.css";
import { getClientSerials, getToken } from "../api/Auth";
import { subDays } from "date-fns";
import { findHost } from "../helpers/MappingSecurispot";
import { OverloadedStats } from "../stats/OverloadedStats";
import { CollisionStats } from "../stats/CollisionStats";
import i18n from "../i18n";
interface HomeProps {
  isMenuCollapsed: boolean;
}

const Home: React.FC<HomeProps> = ({ isMenuCollapsed }) => {
  const tabItems: TabItem[] = [
    { label: i18n.t("GlobalTab"), path: "/supervision/global", icon: faBinoculars },
    { label: i18n.t("AreaTab"), path: "/supervision/area", icon: faWarehouse },
    { label: i18n.t("HelmetTab"), path: "/supervision/helmet", icon: faHardHat },
    { label: i18n.t("LyingTab"), path: "/supervision/lying", icon: faPersonFalling },
    { label: i18n.t("OverloadedTab"), path: "/supervision/overloaded", icon: faWarehouse },
    { label: i18n.t("collisionTab"), path: "/supervision/collision", icon: faTruck },
    { label: i18n.t("AlarmsTab"), path: "/supervision/alarms", icon: faBell },
  ];

  const getSelectedTabFromPath = (): TabItem => {
    switch (window.location.pathname) {
      case "/supervision/global": return tabItems[0];
      case "/supervision/area": return tabItems[1];
      case "/supervision/helmet": return tabItems[2];
      case "/supervision/lying": return tabItems[3];
      case "/supervision/overloaded": return tabItems[4];
      case "/supervision/collision": return tabItems[5];
      case "/supervision/alarms": return tabItems[6];
      default: return tabItems[0];
    }
  };

  // State for dates and selected tab
  const now = Date.now();
  const oneDayAgo = subDays(now, 1).setHours(0, 0, 0, 0);
  const [startDatePeriodTimestamp, setStartDatePeriodTimestamp] = useState<number>(oneDayAgo);
  const [endDatePeriodTimestamp, setEndDatePeriodTimestamp] = useState<number>(now);
  const [selectedTab, setSelectedTab] = useState<TabItem | undefined>(getSelectedTabFromPath());
  const [serials, setSerials] = useState([]);
  const [selectedSerials, setSelectedSerials] = useState<string[]>([]);
  const [serialRequest, setSerialRequest] = useState<string>("");
  const [title, setTitle] = useState<string>(i18n.t("AllSecurispots"));

  const [, setDashboardKey] = useState<number>(0);

  const refreshDashboard = () => setDashboardKey(prevKey => prevKey + 1);

  useEffect(() => {
    let isMounted = true;
    if (selectedSerials.length > 1 && isMounted) {
      setSerialRequest("");
      setTitle(i18n.t("AllSecurispots"));
    } else if (selectedSerials.length === 1 && isMounted) {
      setSerialRequest(selectedSerials[0]);
      setTitle(findHost(selectedSerials[0]));
    }
    return () => { isMounted = false; };
  }, [selectedSerials]);

  const fetchSerials = () => {
    const userSerials = getClientSerials();
    if (userSerials) {
      setSerials(userSerials);
      setSelectedSerials(userSerials);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) fetchSerials();
    return () => { isMounted = false; };
  }, []);

  return (
    <>
      <Tabs
        interval={Interval.DAY}
        items={tabItems}
        onIntervalChanged={() => console.log("interval changed")}
        onPeriodChange={(dates) => {
          setStartDatePeriodTimestamp(dates[0]);
          setEndDatePeriodTimestamp(dates[1]);
          refreshDashboard();
        }}
        onSelect={(tab) => setSelectedTab(tab)}
        selectedTab={selectedTab}
        startDatePeriodTimestamp={startDatePeriodTimestamp}
        endDatePeriodTimestamp={endDatePeriodTimestamp}
        serials={serials}
        selectSerials={(serials) => {
          setSelectedSerials(serials);
          refreshDashboard();
        }}
        selectedSerials={selectedSerials}
        isMenuCollapsed={isMenuCollapsed} // Pass the isMenuCollapsed prop to Tabs
      />

      <div className={classes.HomeContainer}>
        <Switch>
          <Route exact path={"/supervision/alarms"} render={() => (
            <>
              <LastEventDateCard />
              <AlarmsCountCard />
              <AlarmsList numberDays={4} height={undefined} type={undefined} serial={undefined} title={title} />
            </>
          )} />
          
          <Route exact path={"/supervision/global"} render={() => (
            <GlobalDashboardStats
              startDate={startDatePeriodTimestamp}
              endDate={endDatePeriodTimestamp}
              serial={serialRequest}
              title={title}
            />
          )} />

          <Route exact path={"/supervision/helmet"} render={() => (
            <HatWearingStats
              startDate={startDatePeriodTimestamp}
              endDate={endDatePeriodTimestamp}
              serial={serialRequest}
              title={title}
            />
          )} />

          <Route exact path={"/supervision/overloaded"} render={() => (
            <OverloadedStats
              startDate={startDatePeriodTimestamp}
              endDate={endDatePeriodTimestamp}
              serial={serialRequest}
              title={title}
            />
          )} />

          <Route exact path={"/supervision/area"} render={() => (
            <AreaInStats
              startDate={startDatePeriodTimestamp}
              endDate={endDatePeriodTimestamp}
              serial={serialRequest}
              title={title}
            />
          )} />

          <Route exact path={"/supervision/lying"} render={() => (
            <LyingPersonStats
              startDate={startDatePeriodTimestamp}
              endDate={endDatePeriodTimestamp}
              serial={serialRequest}
              title={title}
            />
          )} />

          <Route exact path={"/supervision/collision"} render={() => (
            <CollisionStats
              startDate={startDatePeriodTimestamp}
              endDate={endDatePeriodTimestamp}
              serial={serialRequest}
              title={title}
            />
          )} />
        </Switch>
      </div>
    </>
  );
};

export default Home;
