/**
 * Returns the minimum and maximum timestamps from a date array.
 * @param dates Array of Date objects.
 * @returns An object containing minDate and maxDate, or undefined if the array is empty or invalid.
 */
export const getDateRange = (dates: Date[]): { minDate?: number; maxDate?: number } => {
    if (Array.isArray(dates) && dates.length > 0 && dates[0] instanceof Date) {
      return {
        minDate: dates[0].getTime(),
        maxDate: dates[dates.length - 1].getTime(),
      };
    }
    return { minDate: undefined, maxDate: undefined };
  };
  