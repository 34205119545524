// src/ai/AIModal.tsx

import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faTimes } from '@fortawesome/free-solid-svg-icons';
import classes from './AIComponents.module.css';
import { generatePDFReport } from './pdf/AIPDFUtils';
import i18n from '../i18n';
import { Interval } from '../navigation/tabs/TabsTypes';
import { GraphType } from '../graph/GraphTypes';
import { ApiDataResponse } from '../api/Request';
import { createHeatmap } from './pdf/createheatmap/createMatrixChart';
import { processApiResponseData } from './datahelpers/processApiResponseData';
import { calculateWeekNumber } from './pdf/createheatmap/dateshelpers/formatDate';

interface DataItem {
  rule: string;
  dates: string[];
  count: number;
  language: string;
}

interface AIModalProps {
  showAIModal: boolean;
  isRequestingAI: boolean;
  aiResponse: string | undefined;
  setShowAIModal: (show: boolean) => void;
  apiResponseData: ApiDataResponse<any> | undefined;
  dataType: 'alarms' | 'chart';
  handleAIRequest: () => void;
  graphType?: GraphType;
  interval?: Interval;
  startDatePeriodTimestamp?: number;
  endDatePeriodTimestamp?: number;
  preventUpdateAnimations?: boolean;
}

const AIModal: React.FC<AIModalProps> = ({
  showAIModal,
  isRequestingAI,
  aiResponse,
  setShowAIModal,
  apiResponseData,
  dataType,
  handleAIRequest,
  graphType,
  interval,
  startDatePeriodTimestamp,
  endDatePeriodTimestamp,
  preventUpdateAnimations,
}) => {
  const [heatmapImage, setHeatmapImage] = useState<string | null>(null);

  // Close modal on ESC key press
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowAIModal(false);
      }
    };
    if (showAIModal) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
    }
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [showAIModal, setShowAIModal]);

  // Function to generate heatmap when the modal is opened
  useEffect(() => {
    const generateHeatmap = async () => {
      if (showAIModal && apiResponseData && Array.isArray(apiResponseData.data)) {
        try {
          // Process the API response data
          const aggregatedDataArray: DataItem[] | undefined = await processApiResponseData(apiResponseData, graphType);
          if(!aggregatedDataArray) {
              console.error("aggregatedDataArray is undefined check processApiResponseData")
            }
          else {
          // Determine the week number for the heatmap
          let weekNumber = 0;
          if (aggregatedDataArray.length > 0 && aggregatedDataArray[0].dates.length > 0) {
            weekNumber = calculateWeekNumber(aggregatedDataArray[0].dates[0]);
          } else {
            weekNumber = calculateWeekNumber('');
          }
          console.log("aggregatedDataArray:", aggregatedDataArray)
          if (aggregatedDataArray.length !== 0) {
            const image = await createHeatmap(aggregatedDataArray, weekNumber, false);
            setHeatmapImage(image); // Set the heatmap image in the state
          }
        }
        } catch (error) {
          console.error('Error generating heatmap:', error);
        }
      }
    };
  
    generateHeatmap();
  }, [showAIModal, apiResponseData,graphType]);

  return (
    <>
      {showAIModal && (
        <>
          <div
            className={classes.ModalOverlay}
            onClick={() => setShowAIModal(false)}
          ></div>
          <div
            className={classes.AIModal}
            role="dialog"
            aria-modal="true"
            aria-labelledby="ai-modal-title"
          >
            <button
              className={classes.CloseButton}
              onClick={() => setShowAIModal(false)}
              aria-label={i18n.t('Close')}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <div className={classes.AIContent}>
              <h3 id="ai-modal-title" className={classes.ModalTitle}>
                <FontAwesomeIcon icon={faRobot} /> {i18n.t('Analysis')}
              </h3>
              <hr />

              {/* Show loader or AI response */}
              {isRequestingAI ? (
                <div className={classes.Loader}>
                  <Loader type="Oval" color="#004aac" height={50} width={50} />
                </div>
              ) : (
                <div
                  className={classes.AIResponse}
                  dangerouslySetInnerHTML={{
                    __html: aiResponse || i18n.t('No response'),
                  }}
                />
              )}

              {/* Show the generated heatmap */}
              {heatmapImage && (
                <div className={classes.HeatmapContainer}>
                  <img src={heatmapImage} alt="Heatmap" />
                </div>
              )}

              {/* Modal buttons */}
              <div className={classes.ButtonContainer}>
                <button
                  className={classes.PrimaryButton}
                  onClick={() =>
                    generatePDFReport(
                      aiResponse,
                      dataType,
                      graphType,
                      interval,
                      startDatePeriodTimestamp,
                      endDatePeriodTimestamp,
                      preventUpdateAnimations,
                      apiResponseData
                    )
                  }
                >
                  {i18n.t('Download Report')}
                </button>
                <button
                  className={classes.SecondaryButton}
                  onClick={handleAIRequest}
                  disabled={isRequestingAI}
                >
                  {isRequestingAI ? i18n.t('Retrying') : i18n.t('Retry AI')}
                </button>
                <button
                  className={classes.SecondaryButton}
                  onClick={() => setShowAIModal(false)}
                >
                  {i18n.t('Close')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AIModal;
