import { MaterialColors } from "../../helpers/Colors"
import { ChartDataSets } from "chart.js"


export const getIncidentsEvolutionDataset = () => {
    const labels:Array<string> = new Array<string>()
    const datasets = Array<ChartDataSets>()
    
    const dates:Array<Date> = new Array<Date>()

    datasets.push({
        lineTension: 0,
        borderColor: MaterialColors[0],
        backgroundColor: MaterialColors[0] + "33", //add opacity,
        label: "# incidents",
        data: [
            {
                x: new Date(Date.now() - 3600 * 24 * 5 * 1000),
                y: 2
            },
            {
                x: new Date(Date.now() - 3600 * 24 * 2 * 1000),
                y: 6
            },
            {
                x: new Date(Date.now() - 3600 * 24 * 1 * 1000),
                y: 3
            },
            {
                x: new Date(Date.now()),
                y: 4
            }
    ]
    })
        
    return {
        // datasets: datasets,
        datasets: datasets,
        dates: [],
        error: undefined,
        labels: labels,
        maxValue: undefined
    }
}