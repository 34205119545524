import { t } from "i18next";
import i18n from "../i18n";
import { subDays, addDays } from "date-fns";
import { DateTime } from "luxon";

export const secToText = (sec: string): string => {
  let sec_num: number = parseInt(sec, 10); // don't forget the second param
  let days: number | string = Math.floor(sec_num / (3600 * 24));
  let hours: number | string = Math.floor((sec_num % (3600 * 24)) / 3600);

  const divisorForMinutes = sec_num % (60 * 60);
  let minutes: number | string = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  let seconds: number | string = Math.ceil(divisorForSeconds);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (
    (days > 0 ? days + " " + i18n.t("d") + " " : "") +
    hours +
    "h " +
    minutes +
    "min " +
    seconds +
    "sec"
  );
};

export const secToTextWithoutUselessUnit = (sec: string): string => {
  let sec_num = parseInt(sec, 10); // don't forget the second param
  let days: number | string = Math.floor(sec_num / (3600 * 24));
  let hours: number | string = Math.floor((sec_num % (3600 * 24)) / 3600);

  const divisorForMinutes = sec_num % (60 * 60);
  let minutes: number | string = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  let seconds: number | string = Math.ceil(divisorForSeconds);

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (
    (days > 0 ? days + " " + i18n.t("d") + " " : "") +
    (hours > 0 ? hours + " h " : "") +
    (minutes > 0 ? minutes + " min " : "") +
    seconds +
    " sec"
  );
};

export const secToMin = (sec: string): string => {
  let sec_num = parseInt(sec, 10); // don't forget the second param
  const divisorForMinutes = sec_num % (60 * 60);
  let minutes: number | string = Math.floor(divisorForMinutes / 60);

  const divisorForSeconds = divisorForMinutes % 60;
  let seconds: number | string = Math.ceil(divisorForSeconds);

  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return (minutes === 0 ? "" : `${minutes} min `) + seconds + " sec";
};

export const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const isTomorrow = (someDate: Date): boolean => {
  const today = new Date().getTime();
  return someDate.getTime() > today;
};

export const isValidTimestamp = (
  timestamp: number | string | undefined
): boolean => {
  if (timestamp) {
    const newTimestamp = new Date(timestamp).getTime();
    return isNumeric(newTimestamp);
  }
  return false;
};

function isNumeric(n: any): boolean {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const timeAgoInSeconds = (timestamp: number): number => {
  return Date.now() / 1000 - timestamp;
};

export const getLastWeekInterval = (): [Date, Date] => {
  const now = new Date(Date.now());

  const firstDayOfLastWeek = subDays(now, 7 + (now.getDay() - 1));
  firstDayOfLastWeek.setHours(0, 0, 0, 0);

  const lastDayOfLastWeek = addDays(firstDayOfLastWeek, 4);
  lastDayOfLastWeek.setHours(23, 59, 59, 0);

  return [firstDayOfLastWeek, lastDayOfLastWeek];
};

export const timestampToDate = (timestamp: number): string => {
  const date = new Date(timestamp); // Convertir le timestamp Unix en millisecondes
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formatter = new Intl.DateTimeFormat("fr-FR", options);
  return formatter.format(date);
};

export const getDaysBetweenTimestamps = (timestamp1: number, timestamp2: number) => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}


export const convertUTCToLocal = (utcDateString: string): string | null => {
  return DateTime.fromISO(utcDateString, { zone: 'utc' }).toLocal().toISO();
};