// src/ai/pdf/AIText/generateAIText.ts

import jsPDF from 'jspdf';
import { processSection } from './processSection';
import i18n from '../../../i18n';

/**
 * Process HTML content and render the AI-generated insights into the PDF document.
 * Ensures that each section stays on the same page and enhances the visual layout.
 * @param doc - jsPDF instance
 * @param aiResponse - The AI-generated HTML response to be rendered
 * @param yPosition - Initial Y position on the PDF
 * @param margin - Margin to apply
 * @returns Updated Y position after rendering
 */
export const generateAIText = (
  doc: jsPDF,
  aiResponse: string | undefined,
  yPosition: number,
  margin: number
): number => {
  const pageHeight = doc.internal.pageSize.getHeight();

  // Early return if there's no AI response
  if (!aiResponse) {
    console.warn('No AI response provided.');
    return yPosition;
  }

  // Parse the HTML content
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = aiResponse;

  // Add a title "AI-Generated Insights"
  yPosition += 10; // Add spacing before the title
  doc.setFontSize(18);
  doc.setFont('Helvetica', 'bold');
  doc.text(i18n.t('AI-Generated Insights'), margin, yPosition);
  yPosition += 14; // Space after the title

  // Process the content by grouping sections
  const sections: HTMLElement[][] = []; // Explicitly declare the type
  let currentSection: HTMLElement[] = []; // Explicitly declare the type

  Array.from(tempDiv.childNodes).forEach((node) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as HTMLElement;
      if (element.tagName.toLowerCase() === 'h4' && currentSection.length > 0) {
        // Save the previous section
        sections.push(currentSection);
        // Start a new section
        currentSection = [element];
      } else {
        currentSection.push(element);
      }
    }
  });

  // Add the last section
  if (currentSection.length > 0) {
    sections.push(currentSection);
  }

  // Process each section
  sections.forEach((sectionElements) => {
    yPosition = processSection(doc, sectionElements, yPosition, margin);
  });

  return yPosition + 10; // Add some spacing after the text
};
