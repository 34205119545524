// src/SignUp/validation.ts
import i18n from "../i18n";

export const validatePassword = (password: string): string => {
    const minLength = /.{8,}/;
    const hasUpperCase = /[A-Z]/;
    const hasLowerCase = /[a-z]/;
    const hasNumbers = /\d/;
    const hasSpecialChar = /[!@#$%^&*]/;
  
    if (!minLength.test(password)) {
      return i18n.t('Password must be at least 8 characters long.'); // Translation for minimum length
    }
    if (!hasUpperCase.test(password)) {
      return i18n.t('Password must contain at least one uppercase letter.'); // Translation for uppercase letter
    }
    if (!hasLowerCase.test(password)) {
      return i18n.t('Password must contain at least one lowercase letter.'); // Translation for lowercase letter
    }
    if (!hasNumbers.test(password)) {
      return i18n.t('Password must contain at least one number.'); // Translation for number
    }
    if (!hasSpecialChar.test(password)) {
      return i18n.t('Password must contain at least one special character (!@#$%^&*).'); // Translation for special character
    }
    return '';
  };
  
  export const validateEmail = (email: string): boolean => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };