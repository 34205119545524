// ./src/helpers/sortDatasetsByMaxValue.ts
import { ChartDataSets } from "chart.js";

/**
 * Sorts datasets by the maximum value of their data points to control overlay order.
 * Each dataset is assigned an 'order' property based on the sorted order.
 * @param datasets - Array of ChartDataSets to sort.
 * @returns Array of ChartDataSets sorted by max value with 'order' property assigned.
 */
export const sortDatasetsByMaxValue = (datasets: ChartDataSets[]): ChartDataSets[] => {
  // Calculate and sort datasets based on the maximum value in each dataset
  const sortedDatasets = datasets.map((dataset) => {
    const maxValue = dataset.data && Array.isArray(dataset.data)
      ? Math.max(...dataset.data.map((point: any) => (typeof point === 'number' ? point : point.y)))
      : 0; // Default to 0 if `dataset.data` is undefined or empty
    return { ...dataset, maxValue };
  }).sort((a, b) => a.maxValue - b.maxValue);

  // Assign 'order' property to each dataset based on sorted order
  sortedDatasets.forEach((dataset, index) => {
    dataset.order = index;
  });

  return sortedDatasets;
};
