import React, {Component} from "react";
import {
    getConfirm,
    getToken,
    loggedIn,
    logout,
    refreshToken,
    willTokenExpire,
    isTokenExpired
} from "../api/Auth";

import {Redirect} from 'react-router-dom'


/* A higher order component is frequently written as a function that returns a class. */
export const withAuth = (AuthComponent:any) => {

    return class AuthWrapped extends Component {
        state = {
            confirm: null,
            loaded: false,
            refreshTokenFunctionId: null
        };

        /* In the componentDidMount, we would want to do a couple of important tasks in order to verify the current users authentication status
        prior to granting them enterance into the app. */
        componentDidMount() {
            if (!loggedIn()) {
                this.setState({ loaded: true })
            } else {
                /* Try to get confirmation message from the Auth helper. */
                try {
                    const confirm = getConfirm();
                    this.setState({
                        confirm: confirm,
                        loaded: true
                    });
                    const intervalId = setInterval(() => this.refreshTokenIfNeeded(), 60000)
                    this.setState({ refreshTokenFunctionId : intervalId })
                } catch (err:any) {
                    /* Oh snap! Looks like there's an error so we'll print it out and log the user out for security reasons. */
                    console.error(err);
                    logout();
                    this.setState({ loaded: true })
                }
            }
        }

        componentDidUpdate(_:any, prevState:any, snapshot:any) {
            if(prevState.confirm !== getToken() && !isTokenExpired(getToken()!)){
                this.setState({confirm: getToken()})
            }
        }

        async refreshTokenIfNeeded(){
            if(willTokenExpire()){
                const lastUserClick = localStorage.getItem("last_user_click_timestamp")
                if(lastUserClick !== null && !isNaN(parseInt(lastUserClick))){
                    const now = Date.now()
                    const lastActionTimestamp = parseInt(lastUserClick)
                    // If user doesn't make click on website since 15 min, it should be disconnected
                    const oneHourAgo = now - (1000 * 60 * 15)
                    if(oneHourAgo > lastActionTimestamp){
                        logout()
                        window.location.replace("/")
                    }

                    return
                }

                const refreshTokenResult = await refreshToken()
            }
        }

        render() {
            if (this.state.loaded === true) {
                if (this.state.confirm != null) {
                    /* component that is currently being wrapper(App.js) */
                    //@ts-ignore
                    return <AuthComponent history={this.props.history} confirm={this.state.confirm} />
                } else {
                    return <Redirect to="/login" />
                }
            } else {
                return null;
            }
        }
    };
}
