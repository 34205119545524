import i18n from '../../i18n';
import { Interval } from "../../navigation/tabs/TabsTypes";

export const getTimeUnit = (interval: Interval): string => {
  switch (interval) {
    case Interval.HOUR:
      return 'hour';
    case Interval.DAY:
      return 'day';
    default:
      return 'day';
  }
};

export const getWeekdays = (): string[] => [
  i18n.t('Monday'),
  i18n.t('Tuesday'),
  i18n.t('Wednesday'),
  i18n.t('Thursday'),
  i18n.t('Friday'),
  i18n.t('Saturday'),
  i18n.t('Sunday'),
];
