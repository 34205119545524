import jsPDF from "jspdf";

/**
 * Adds an image to the PDF with specified dimensions and positions, centers it with an optional caption and border.
 * @param doc - jsPDF instance.
 * @param chartImage - Image data as a Base64 string.
 * @param margin - Margin to apply for vertical alignment.
 * @param yPosition - Current Y position on the PDF.
 * @param imgWidth - Width of the image.
 * @param imgHeight - Height of the image.
 * @param caption - Optional caption for the image.
 * @returns Updated Y position after rendering the image and caption.
 */
export const addImageToPDF = (
  doc: jsPDF,
  chartImage: string,
  margin: number,
  yPosition: number,
  imgWidth: number,
  imgHeight: number,
  caption: string = ''
): number => {
  try {
    const pageWidth = doc.internal.pageSize.getWidth();
    const xPosition = (pageWidth - imgWidth) / 2; // Center image horizontally

    if (imgWidth > 0 && imgHeight > 0) {
      // Draw border
      doc.setDrawColor(180, 180, 180); // Light gray for border
      doc.setLineWidth(0.5);
      doc.rect(xPosition, yPosition, imgWidth, imgHeight, 'S'); // 'S' for stroke only

      // Add image
      doc.addImage(chartImage, 'PNG', xPosition, yPosition, imgWidth, imgHeight);

      // Add caption if provided
      if (caption) {
        doc.setFontSize(10);
        doc.setFont('Helvetica', 'italic');
        doc.setTextColor(85, 85, 85); // Dark gray for captions
        const captionWidth = doc.getTextWidth(caption);
        const captionX = (pageWidth - captionWidth) / 2; // Center caption horizontally
        doc.text(caption, captionX, yPosition + imgHeight + 5);
        yPosition += imgHeight + 15; // Space after the caption
      } else {
        yPosition += imgHeight + 10; // Space after the image
      }

      return yPosition;
    } else {
      throw new Error('Invalid image dimensions');
    }
  } catch (error) {
    console.error('Failed to add image to PDF:', error);
    throw new Error('Error adding image to PDF.');
  }
};
