/**
 * Creates a temporary div element to serve as a canvas for Plotly rendering.
 * @returns The created div element.
 */
export const createCanvas = (): HTMLDivElement => {
    const canvas = document.createElement('div');
    document.body.appendChild(canvas);
    return canvas;
  };
  
  /**
   * Removes the canvas element from the DOM.
   * @param canvas - The canvas element to be removed.
   */
  export const removeCanvas = (canvas: HTMLDivElement): void => {
    if (canvas && canvas.parentNode) {
      document.body.removeChild(canvas);
    }
  };
  