// src/GlobalVue/Treeutils/helpers/NodeCommandApi.ts

import config from "../../../../config";
import { postRequest } from "../../../../api/Request";
import { error } from "console";

const NodeCommandURL = `${config.API_URL}/nodecommand`;

export const callNodeCommandApi = async (nodeInfo: { nodeId: string; command: string; data?: any }): Promise<boolean> => {
  const payload = {
    message: "Node command",
    data: {
      nodeId: nodeInfo.nodeId,
      command: nodeInfo.command,
      ...nodeInfo.data, // Include additional data if needed
    },
  };

  try {
    // const response = await postRequest(NodeCommandURL, payload);
    const response = {
        status : 'ok',
        message : 'ok',
        error : undefined
    }
    if (response.error) {
      console.error("Error:", response.message);
      return false;
    }

    if (response && response.status) {
      console.log("Node command executed successfully:", response);
      return true;
    } else {
      console.warn("Invalid response:", response);
      return false;
    }
  } catch (error) {
    console.error("Error while executing node command:", error);
    return false;
  }
};
