// src/navigation/menu/components/MenuTree.tsx

import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import { Tree, TreeApi } from 'react-arborist';
import MenuItemNode from './MenuItemNode';
import { MenuItem } from '../rawMenuItems';

export interface MenuTreeHandle {
  collapseAllNodes: () => void;
  expandAllNodes: () => void;
}

interface MenuTreeProps {
  menuItems: MenuItem[];
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const MenuTree = forwardRef<MenuTreeHandle, MenuTreeProps>((props, ref) => {
  const { menuItems, isCollapsed, toggleCollapse } = props;
  const treeRef = useRef<TreeApi<MenuItem> | null>(null);

  useImperativeHandle(ref, () => ({
    collapseAllNodes: () => {
      if (treeRef.current) {
        const rootNode = treeRef.current.root;
        const collapseNode = (node: any) => {
          if (node.isOpen) {
            node.close();
          }
          node.children?.forEach((childNode: any) => {
            collapseNode(childNode);
          });
        };
        collapseNode(rootNode);
      }
    },
    expandAllNodes: () => {
      if (treeRef.current) {
        const rootNode = treeRef.current.root;
        const expandNode = (node: any) => {
          if (!node.isOpen) {
            node.open();
          }
          node.children?.forEach((childNode: any) => {
            expandNode(childNode);
          });
        };
        expandNode(rootNode);
      }
    },
  }));

  return (
    <div style={{ width: '100%', height: '100%', overflowX: 'hidden' }}>
      <Tree<MenuItem>
        ref={treeRef}
        data={menuItems}
        disableDrag
        width="100%"
        height={window.innerHeight}
        overscanCount={5}
        indent={isCollapsed ? 8 : 30}
        rowHeight={isCollapsed ? 40 : 48}
      >
        {(nodeProps) => (
          <MenuItemNode
            {...nodeProps}
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
          />
        )}
      </Tree>
    </div>
  );
});

export default MenuTree;
