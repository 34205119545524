export enum GraphType {
    INCIDENTS_COUNT,
    ALARMS_COUNT,
    AREA_IN_ALARMS_EVOLUTION,
    HAT_ALARMS_EVOLUTION,
    LYING_PERSON_ALARMS_EVOLUTION,
    ALARMS_EVOLUTION,
    STATUS_EVOLUTION,
      OVERLOADED_AREA_ALARM,
    COLLISION_ALARM_EVOLUTION,
  }