import config from "../config";
import { getIntervalString, Interval } from "../navigation/tabs/TabsTypes";
import { ApiDataResponse, post } from "./Request";

export const getDetections = async (
  path: string
): Promise<ApiDataResponse<GetDetectionsApiResponse>> => {
  const body = JSON.stringify({
    nodePath: path,
  });

  console.log('getDetections called with path:', path);
  console.log('Request body:', body);

  try {
    const response = await post<GetDetectionsApiResponse>(
      config.API_URL + '/getdetections',
      body
    );
    console.log('getDetections response:', response);
    return response;
  } catch (error) {
    console.error('getDetections error:', error);
    throw error;
  }
};

export const getAlerts = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  serial?: string,
  type?: number
): Promise<ApiDataResponse<GetAlarmsApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    serial: serial,
    type: type,
  });

  console.log('getAlerts called with:', {
    startDateTimestamp,
    endDateTimestamp,
    interval,
    serial,
    type,
  });
  console.log('Request body:', body);

  try {
    const response = await post<GetAlarmsApiResponse>(
      config.API_URL + '/getalarms',
      body
    );
    console.log('getAlerts response:', response);
    return response;
  } catch (error) {
    console.error('getAlerts error:', error);
    throw error;
  }
};

export const getAlertsPerType = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  type: number,
  serial?: string | null
): Promise<ApiDataResponse<GetAlarmsPerTypeApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    type: type,
    serial: serial,
  });

  console.log('getAlertsPerType called with:', {
    startDateTimestamp,
    endDateTimestamp,
    interval,
    type,
    serial,
  });
  console.log('Request body:', body);

  try {
    const response = await post<GetAlarmsPerTypeApiResponse>(
      config.API_URL + '/getalarmspertype',
      body
    );
    console.log('getAlertsPerType response:', response);
    return response;
  } catch (error) {
    console.error('getAlertsPerType error:', error);
    throw error;
  }
};

export const getAlertsEvolution = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  serial?: string | null
): Promise<ApiDataResponse<GetAlarmsEvolutionApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    serial: serial,
  });

  console.log('getAlertsEvolution called with:', {
    startDateTimestamp,
    endDateTimestamp,
    interval,
    serial,
  });
  console.log('Request body:', body);

  try {
    const response = await post<GetAlarmsEvolutionApiResponse>(
      config.API_URL + '/getalarmsevolution',
      body
    );
    console.log('getAlertsEvolution response:', response);
    return response;
  } catch (error) {
    console.error('getAlertsEvolution error:', error);
    throw error;
  }
};
export const getLastEventDate = async (): Promise<
  ApiDataResponse<GetLastEventDateApiResponse>
> => {
  console.log('getLastEventDate called');

  try {
    const response = await post<GetLastEventDateApiResponse>(
      config.API_URL + '/getlasteventdate'
    );
    console.log('getLastEventDate response:', response);
    return response;
  } catch (error) {
    console.error('getLastEventDate error:', error);
    throw error;
  }
};

export const getAvailabilityEvolution = async (
  startDateTimestamp: number,
  endDateTimestamp: number,
  interval: Interval,
  serial?: string | null
): Promise<ApiDataResponse<GetAvailabilityEvolutionApiResponse>> => {
  const body = JSON.stringify({
    startDateTimestamp: startDateTimestamp,
    endDateTimestamp: endDateTimestamp,
    interval: getIntervalString(interval),
    serial: serial,
  });

  console.log('getAvailabilityEvolution called with:', {
    startDateTimestamp,
    endDateTimestamp,
    interval,
    serial,
  });
  console.log('Request body:', body);

  try {
    const response = await post<GetAvailabilityEvolutionApiResponse>(
      config.API_URL + '/getavailabilityevolution',
      body
    );
    console.log('getAvailabilityEvolution response:', response);
    return response;
  } catch (error) {
    console.error('getAvailabilityEvolution error:', error);
    throw error;
  }
};

export type GetDetectionsApiResponse = Array<{
  gsi1sk: string;
  pk: string;
  sk: string;
  date: string;
  nb_frames: number;
  fps: number;
}>;

export type GetAlarmsApiResponse = Array<{
  date: string;
  id: number;
  duration: number;
  description: string;
  securispot: string;
}>;

export type GetAlarmsPerTypeApiResponse = Array<{
  hostname: string;
  data: Array<{
    date: string;
    count: number;
  }>;
}>;

// type DeviceAlarmData = {
//   rule1: number;
//   rule2: number;
//   rule3: number;
// };

// type DeviceData = {
//   [deviceName: string]: DeviceAlarmData;
// };

// export type GetAlarmsEvolutionApiResponse = Array<{
//   date: string;
//   devices: DeviceData;
// }>;

export type GetAlarmsEvolutionApiResponse = Array<{
  date: string;
  rule1: number;
  rule2: number;
  rule3: number;
  rule4: number;
  rule5: number;
}>;

export type GetAvailabilityEvolutionApiResponse = Array<{
  hostname: string;
  data: Array<{
    date: string;
    online: boolean;
  }>;
}>;

export type GetLastEventDateApiResponse = {
  date: string;
};
