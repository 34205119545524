//src/SignUp/signupapi.ts

import axios from 'axios';
import config from "../config";
import { postRequest } from "./Request";

export const signUpUser = async (userData: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  fpAdmin: boolean;
  isAdmin: boolean;
}) => {
  try {
    const response = await postRequest(config.API_URL +'/signup', userData);
    return response;
  } catch (err: any) {
    return {
      success: false,
      message: err.response?.data?.message || 'An error occurred.',
    };
  }
};


export const getClients = async () => {
    try {
      // Payload to send
      const payload = {
        message: "getClientsnames" // Sending a message to the server
      };
  
      // Making the POST request
      const response = await axios.post(config.API_URL + '/fetchclients', payload, {
        headers: {
          "Content-Type": "application/json", // Setting Content-Type correctly
        }
      });

      return response.data;
  
    } catch (err: any) {
      console.error('Error response:', err.response); // Logging the error
      return {
        success: false,
        message: err.response?.data?.message || 'Failed to fetch clients.',
      };
    }
  };
  