import React from 'react';
import { NodeRendererProps } from 'react-arborist';
import styles from '../../GlobaleView.module.css';
import { Node } from '../helpers/types';
import { FaSortAlphaDown, FaSortAlphaUp } from 'react-icons/fa'; // Import icons
import { NodeId, NodeHandler, ParentNodeHandler } from '../helpers/types';
import { useNodeProperties, getNodeClasses } from './TreeNodehelpers/helpers';
import {
  renderExpandCollapseArrow,
  renderNodeIcon,
  renderNodeLabelOrEditInput,
  renderActionButtons,
} from './TreeNodehelpers/componentshelpers';

interface TreeNodeProps extends NodeRendererProps<Node> {
  editNodeId: NodeId | null;
  newNodeName: string;
  newlyAddedNodeId: NodeId | null;
  setNewNodeName: React.Dispatch<React.SetStateAction<string>>;
  handleSaveEdit: (nodeId: NodeId) => void;
  handleCancelEdit: (nodeId?: NodeId) => void;
  handleEditNode: NodeHandler;
  handleDeleteNode: NodeHandler;
  handleAddNode: ParentNodeHandler;
  handleAssociateToggle: NodeHandler;
  sortingOrder?: 'asc' | 'desc';
  handleSortChildren: (nodeId: string) => void;
}

// Main TreeNode Component
const TreeNode: React.FC<TreeNodeProps> = ({
  node,
  style,
  dragHandle,
  editNodeId,
  newNodeName,
  setNewNodeName,
  handleSaveEdit,
  handleCancelEdit,
  handleEditNode,
  handleDeleteNode,
  handleAddNode,
  handleAssociateToggle,
  sortingOrder,
  handleSortChildren,
}) => {
  const {
    hasChildren,
    isSecurispotDevice,
    isCompanyNode,
    isLeafNode,
    showAssociateButton,
    isUnderSecurispotNA,
    isEditing,
  } = useNodeProperties(node, editNodeId, setNewNodeName);

  const isRootNode = node.parent?.parent == undefined;

  // Compute CSS classes for the node
  const nodeClasses = getNodeClasses(
    styles,
    hasChildren,
    node.isOpen,
    isLeafNode,
    node.willReceiveDrop,
    isSecurispotDevice
  );

  return (
    <div className={nodeClasses} style={style} ref={dragHandle}>
      {renderExpandCollapseArrow(hasChildren, node, styles)}
      {renderNodeIcon(isCompanyNode, isSecurispotDevice, isLeafNode, styles)}
      <div className={styles.nodeContent}>
        <div className={styles.nodeLabelContainer}>
          {renderNodeLabelOrEditInput(
            isEditing,
            newNodeName,
            setNewNodeName,
            node.data.name,
            node.id,
            handleSaveEdit,
            handleCancelEdit,
            styles
          )}
          {isRootNode && (
            <button
              className={styles.sortButton}
              onClick={() => handleSortChildren(node.id)}
              disabled={false} // We'll handle disabling via CSS
            >
              {sortingOrder === 'asc' ? <FaSortAlphaDown /> : <FaSortAlphaUp />}
            </button>
          )}
        </div>
        {renderActionButtons(
          showAssociateButton,
          node.id,
          isUnderSecurispotNA,
          handleAssociateToggle,
          handleEditNode,
          handleDeleteNode,
          handleAddNode
        )}
      </div>
    </div>
  );
};

export default TreeNode;
