// src/GlobalVue/Treeutils/helpers/helpers.ts
import { Node } from "./types";
import { callNodeCommandApi } from "./api/callNodeCommandApi";
/**
 * Removes nodes with specified IDs from the tree data.
 * @param nodes - The tree nodes.
 * @param ids - The IDs of the nodes to remove.
 * @returns The updated tree nodes.
 */
export const removeNodesById = (nodes: Node[], ids: string[]): Node[] => {
  return nodes
    .filter((node) => !ids.includes(node.id))
    .map((node) => ({
      ...node,
      children: node.children ? removeNodesById(node.children, ids) : undefined,
    }));
};

/**
 * Finds a node by its ID in the tree data.
 * @param nodes - The tree nodes.
 * @param id - The ID of the node to find.
 * @returns The node if found, otherwise null.
 */
export const findNodeById = (nodes: Node[], id: string): Node | null => {
  for (const node of nodes) {
    if (node.id === id) return node;
    if (node.children) {
      const childNode = findNodeById(node.children, id);
      if (childNode) return childNode;
    }
  }
  return null;
};

/**
 * Updates the name of a node by its ID.
 * @param nodes - The tree nodes.
 * @param id - The ID of the node to update.
 * @param name - The new name for the node.
 * @returns The updated tree nodes.
 */
export const updateNodeNameById = (
  nodes: Node[],
  id: string,
  name: string
): Node[] => {
  return nodes.map((node) => {
    if (node.id === id) {
      return { ...node, name };
    }
    if (node.children) {
      return { ...node, children: updateNodeNameById(node.children, id, name) };
    }
    return node;
  });
};

/**
 * Adds a child node to a parent node by the parent's ID.
 * @param nodes - The tree nodes.
 * @param parentId - The ID of the parent node.
 * @param newNode - The new node to add.
 * @returns The updated tree nodes.
 */
export const addChildNodeById = (
  nodes: Node[],
  parentId: string,
  newNode: Node
): Node[] => {
  return nodes.map((node) => {
    if (node.id === parentId) {
      return {
        ...node,
        children: node.children ? [...node.children, newNode] : [newNode],
      };
    }
    if (node.children) {
      return {
        ...node,
        children: addChildNodeById(node.children, parentId, newNode),
      };
    }
    return node;
  });
};


export function removeNodeAndGetParentId(
  nodes: Node[],
  nodeId: string
): { updatedData: Node[]; parentId: string | null } {
  let parentId: string | null = null;

  function recursiveRemove(nodes: Node[], parent: Node | null): Node[] {
    return nodes.filter((node) => {
      if (node.id === nodeId) {
        parentId = parent ? parent.id : null;
        return false; // Remove this node
      } else if (node.children) {
        node.children = recursiveRemove(node.children, node);
      }
      return true;
    });
  }

  const updatedData = recursiveRemove(nodes, null);
  return { updatedData, parentId };
}

/**
 * Function to check if a node is a Securispot device and prevent adding children
 * @param parentNode The parent node being checked
 * @returns boolean - Returns true if adding is prevented, false otherwise
 */
export const preventAddToSecurispot = (parentNode: Node | null): boolean => {
  if (parentNode) {
    const isParentSecurispot = parentNode.id.includes('securispot');
    
    // Check if the parent node is a Securispot and doesn't have children
    if (isParentSecurispot && parentNode.children && parentNode.children.length === 0) {
      console.warn("Cannot add child nodes to a Securispot device.");
      return true;  // Prevent the operation
    }
  }
  return false;  // Allow the operation
};


// Collect dragged nodes from the data
export const collectDraggedNodes = (nodes: Node[], ids: string[]): Node[] => {
  const draggedNodes: Node[] = [];
  const traverseNodes = (nodes: Node[]) => {
    nodes.forEach((node) => {
      if (ids.includes(node.id)) {
        draggedNodes.push(node);
      } else if (node.children) {
        traverseNodes(node.children);
      }
    });
  };
  traverseNodes(nodes);
  return draggedNodes;
};

// Insert dragged nodes into the new parent or root
export const insertDraggedNodes = (
  data: Node[], 
  draggedNodes: Node[], 
  parentId: string | null, 
  index: number
) => {
  if (parentId === null || parentId === undefined) {
    // Insert at the root level
    data.splice(index, 0, ...draggedNodes);
  } else {
    const parentNode = findNodeById(data, parentId);
    if (parentNode) {
      if (!parentNode.children) parentNode.children = [];
      parentNode.children.splice(index, 0, ...draggedNodes);
    }
  }
};

// Function to update the association and dissociation status of nodes
export const updateNodeAssociations = async (
  draggedNodes: Node[], 
  parentId: string | null, 
  data: Node[],
): Promise<void> => {
  for (const node of draggedNodes) {
    const newParentNode = parentId ? findNodeById(data, parentId) : null;
    let isAssociated: boolean;

    // Determine the new association status based on the new parent node
    if (newParentNode && newParentNode.name === 'Securispot NA') {
      isAssociated = false; // Node is now under "Securispot NA", meaning dissociated
    } else {
      isAssociated = true; // Node is not under "Securispot NA", meaning associated
    }

    // Determine the command based on the association status
    const command = isAssociated ? 'associate' : 'disassociate';

    // Step 2: Call the API to update the association status
    const apiSuccess = await callNodeCommandApi({
      nodeId: node.id,
      command,
    });

    if (apiSuccess) {
      // Step 3: Only update local state if the API call was successful
      node.isAssociated = isAssociated;
      node.isDissociated = !isAssociated;
    } else {
      console.error(`Failed to update association status via API for node ${node.id}`);
    }
  }
};


// Helper function to find the parent of a node
export function findParentNode(data: Node[], nodeId: string): Node | null {
  for (const node of data) {
    if (node.children && node.children.some((child) => child.id === nodeId)) {
      return node; // Found the parent node
    }
    // Recursively check the children
    if (node.children) {
      const parent = findParentNode(node.children, nodeId);
      if (parent) return parent;
    }
  }
  return null; // No parent found
}

// Helper function to find the root node (company or client node)
export function findRootNode(data: Node[], node: Node): Node | null {
  let parentNode = findParentNode(data, node.id);
  let currentNode: Node | null = parentNode;

  // Traverse upwards until we find a node with no parent (root node)
  while (currentNode) {
    const nextParent = findParentNode(data, currentNode.id);
    if (!nextParent) break;
    currentNode = nextParent;
  }

  return currentNode || node; // Return the root node or the current node if no parent was found
}

// Helper function to get company name from the node
export function getCompanyNameFromNode(node: Node): string {
  // Assuming you store the company name as 'name' in each company node
  return node.name;
}

// Helper function to find the path of a node from the root to its parent
export const getNodePath = (nodeId: string, data: Node[]): { path: string, nodeId: string } | null => {
  const path: string[] = [];
  let currentNode = findNodeById(data, nodeId); // Assuming findNodeById is already defined

  if (!currentNode) {
    return null; // If the node is not found, return null
  }

  // Traverse upwards to collect the path, starting from the current node's parent
  let parentNode = findParentNode(data, nodeId);
  
  while (parentNode) {
    path.unshift(parentNode.id); // Add parent node ID to the beginning of the path
    parentNode = findParentNode(data, parentNode.id); // Traverse upwards to find the next parent
  }

  // Join the path array into a string separated by "|"
  const pathString = path.join('|');

  return {
    path: pathString,
    nodeId: currentNode.id,
  };
};


// Helper to find or create "Securispot NA" node
export const findOrCreateSecurispotNode = (updatedData: Node[]): Node => {
  let securispotNode = updatedData.find((n) => n.name === 'Securispot NA');
  
  if (!securispotNode) {
    securispotNode = {
      id: 'securispot-na',
      name: 'Securispot NA',
      children: [], // Initialize children as an empty array
    };
    updatedData.push(securispotNode);
  } else if (!securispotNode.children) {
    securispotNode.children = []; // Ensure children is initialized
  }

  return securispotNode;
};

// Helper to update the association status via API and handle errors
export const updateAssociationStatus = async (nodeId: string, isAssociated: boolean): Promise<boolean> => {
  // Determine the command based on the association status
  const command = isAssociated ? 'associate' : 'disassociate';

  // Call the backend API using callNodeCommandApi
  const apiSuccess = await callNodeCommandApi({
    nodeId,
    command,
  });

  if (!apiSuccess) {
    console.error(`Failed to update association status via API for node ${nodeId}`);
  }

  return apiSuccess;
};
