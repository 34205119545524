// src/ai/pdf/AIText/renderText.ts

import jsPDF from 'jspdf';

/**
 * Helper function to render text with proper font size and page break handling.
 * Ensures text does not overflow the page and handles multi-line text.
 * @param doc - jsPDF instance
 * @param text - The text to render
 * @param fontSize - Font size to use
 * @param isBold - Whether the text should be bold
 * @param yPosition - Current Y position in the PDF
 * @param margin - Margin to apply to the text
 * @param pageHeight - Height of the PDF page
 * @returns Updated Y position after rendering the text
 */
export const renderText = (
  doc: jsPDF,
  text: string,
  fontSize: number,
  isBold: boolean,
  yPosition: number,
  margin: number,
  pageHeight: number
): number => {
  if (!text) return yPosition;

  doc.setFontSize(fontSize);
  doc.setFont('Helvetica', isBold ? 'bold' : 'normal');

  const pageWidth = doc.internal.pageSize.getWidth();
  const textWidth = pageWidth - margin * 2;
  const lines = doc.splitTextToSize(text, textWidth);

  lines.forEach((line: string) => {
    if (yPosition + 7 > pageHeight - margin) {
      doc.addPage();
      yPosition = margin;
    }
    doc.text(line, margin, yPosition, { maxWidth: textWidth, align: 'justify' });
    yPosition += 7;
  });

  return yPosition;
};
