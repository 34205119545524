// src/navigation/menu/components/NodesComponents/FooterNode.tsx

import React from 'react';
import i18n from '../../../../i18n';
import styles from './FooterNode.module.css';

interface FooterNodeProps {
  isCollapsed: boolean;
}

export const FooterNode: React.FC<FooterNodeProps> = ({ isCollapsed }) => (
  <div className={styles.FooterContainer}>
    <label
      className={`${styles.MenuAppVersion} ${
        isCollapsed ? styles.MenuAppVersionCollapsed : ''
      }`}
    >
      <span>Version {process.env.REACT_APP_VERSION}</span>
      <br />
      <br />
      <span className={styles.MenuCopyright}>
        {i18n.t('RigthReserved')} {new Date().getFullYear()}
      </span>
    </label>
  </div>
);
