import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signUpUser, getClients } from '../api/signupapi'; // Import API functions
import { validatePassword, validateEmail } from './validation'; // Import validation functions
import styles from './SignUp.module.css';
import i18n from '../i18n';
import SignupModal from './SignupModal'; // Import the new modal component

const SignUp: React.FC = () => {
  const history = useHistory(); // Move useHistory hook to the top level

  // State variables
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [fpAdmin, setFpAdmin] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false); // For toggling password visibility
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state
  const [modalContent, setModalContent] = useState<{ title: string, message: React.ReactNode } | null>(null); // Modal content

  // New state for clients and selected client
  const [clients, setClients] = useState<{ name: string, uuid: string }[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>('');

  // Fetch the clients when the component mounts
  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const fetchClients = async () => {
      try {
        const response = await getClients();
        if (isMounted) { // Only update state if the component is still mounted
          if (response.status === 'ok') {
            setClients(response.clients); // Assuming the response has a "clients" array
            setSelectedClient(response.clients[0]?.name || ''); // Set the first client name as default
          } else {
            setError(response.message);
            console.error('Error fetching clients:', response.message);
          }
        }
      } catch (error) {
        console.error('Error during fetchClients execution:', error);
      }
    };

    fetchClients();

    // Cleanup function to mark the component as unmounted
    return () => {
      isMounted = false;
    };
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Show loading spinner when submitting the form

    // Basic email validation
    if (!validateEmail(email)) {
      setError(i18n.t('Please enter a valid email address.'));
      setIsLoading(false);
      return;
    }

    // Password validation
    const passwordValidationMessage = validatePassword(password);
    if (passwordValidationMessage) {
      setPasswordError(passwordValidationMessage);
      setIsLoading(false);
      return;
    } else {
      setPasswordError('');
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError(i18n.t('Passwords do not match.'));
      setIsLoading(false);
      return;
    }

    // Clear previous error
    setError('');

    // Prepare data
    const userData = {
      firstName,
      lastName,
      email,
      password,
      fpAdmin,
      isAdmin,
      clientname: selectedClient, // Add the selected client to the data
    };

    // Call the API to sign up the user
    const response = await signUpUser(userData);
    setIsLoading(false); // Stop loading spinner

    if (response.status === 'ok') {
      setSuccess(true);
      setModalContent({
        title: i18n.t('Signup successful!'),
        message: (
          <>
            <p><strong>Username:</strong> {email}</p>
            <p><strong>Password:</strong> {password}</p>
          </>
        ),
      });
    } else {
      setModalContent({
        title: i18n.t('Error'),
        message: response.message,
      });
    }
  };


  // Close modal and reset form state
  const closeModal = () => {
    setModalContent(null);
    if (success) {
      history.push('/signup'); // Redirect to login if successful
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>SecuriSPOT Smart View</h1>
      {error && <p className={styles.errorMessage}>{error}</p>}
      {modalContent ? (
        <SignupModal
          title={modalContent.title}
          message={modalContent.message}
          lastName={lastName}
          firstName={firstName}
          email={email}
          password={password}
          onClose={closeModal}
        />
      ) : (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.row}>
            <div className={styles.inputContainer}>
              <label className={styles.label}>{i18n.t('First Name')}</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
                className={styles.input}
              />
            </div>
            <div className={styles.inputContainer}>
              <label className={styles.label}>{i18n.t('Last Name')}</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
                className={styles.input}
              />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.label}>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className={styles.input}
            />
          </div>

          {/* Client selection dropdown */}
          <div className={styles.inputContainer}>
            <label className={styles.label}>{i18n.t('Select Client')}</label>
            <select
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)} // Update selected client by name
              required
              className={styles.input}
            >
              {clients.map((client, index) => (
                <option key={index} value={client.name}> {/* Use client name for value */}
                  {client.name} {/* Display client name */}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.inputContainer}>
            <label className={styles.label}>{i18n.t('Password')}</label>
            <div className={styles.passwordContainer}>
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className={styles.input}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className={styles.toggleButton}
              >
                {showPassword ? i18n.t('Hide') : i18n.t('Show')}
              </button>
            </div>
            {passwordError && <p className={styles.errorMessage}>{passwordError}</p>}
          </div>
          <div className={styles.inputContainer}>
            <label className={styles.label}>{i18n.t('Confirm Password')}</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onPaste={(e) => e.preventDefault()}
              required
              className={styles.input}
            />
          </div>
          <div className={styles.checkboxContainer}>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={fpAdmin}
                onChange={(e) => setFpAdmin(e.target.checked)}
                className={styles.checkbox}
              />
              {i18n.t('Site Owner (fpAdmin)')}
            </label>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
                className={styles.checkbox}
              />
              {i18n.t('Company Admin (isAdmin)')}
            </label>
          </div>
          <button
          type="submit"
          className={styles.submitButton}
          disabled={isLoading} // Disable button when loading
          >
            {isLoading ? (
              <span className={styles.spinner}></span> // Spinner while loading
            ) : (
              i18n.t('Sign Up')
            )}
        </button>
        </form>
      )}
    </div>
  );
};

export default SignUp;
