//src/GlobalVue/Treeutils/helpers/handleMove.ts

import { Node } from '../types';
import {
  removeNodesById,
  findNodeById,
  preventAddToSecurispot,
  collectDraggedNodes,
  updateNodeAssociations,
  insertDraggedNodes,
  findRootNode,
  getNodePath
} from '../helpers';
import { callNodeCommandApi } from '../api/callNodeCommandApi';

export const handleMove = async (
  params: { dragIds: string[]; parentId: string | null; index: number },
  data: Node[],
  setData: React.Dispatch<React.SetStateAction<Node[]>>,
  showModal: (props: {
    nodeName: string;
    fromCompany: string;
    toCompany: string;
    onConfirm: () => void;
    onCancel: () => void;
  }) => Promise<boolean> // Modal is async and returns a boolean promise
): Promise<boolean> => {
  const { dragIds, parentId, index } = params;

  // 1. Collect the dragged nodes
  const draggedNodes = collectDraggedNodes(data, dragIds);
  if (!draggedNodes.length) {
    return false; // If no nodes are dragged, exit early
  }

  // 6. Remove dragged nodes from the data
  const newData = removeNodesById(data, dragIds);

  // 7. Prevent adding to Securispot if necessary
  const newParentNode = parentId ? findNodeById(newData, parentId) : null;
  if (preventAddToSecurispot(newParentNode)) {
    return false; // Stop the operation if adding to a Securispot is not allowed
  }
  
  let draggedNode = draggedNodes[0];

  // 2. Find the current root node (company/client node)
  const currentRootNode = findRootNode(data, draggedNode);

  // 3. Find the new parent node and its root (if provided)
  let newRootNode: Node | null = null;
  if (parentId) {
    const parentNode = findNodeById(data, parentId);
    if (parentNode) {
      newRootNode = findRootNode(data, parentNode);
    }
  }

  // Only proceed with the modal if the currentRootNode and newRootNode are different by their ids
  if (currentRootNode?.id !== newRootNode?.id) {
    const companyChangeConfirmed = await showModal({
      nodeName: draggedNode.name,
      fromCompany: currentRootNode ? currentRootNode.name : '',
      toCompany: newRootNode ? newRootNode.name : '',
      onConfirm: () => Promise.resolve(true),
      onCancel: () => Promise.resolve(false)
    });

    if (!companyChangeConfirmed) {
      // If user cancels the move, return early
      return false;
    }
  }

  // 8. Update node properties (e.g., isAssociated and isDissociated)
  updateNodeAssociations(draggedNodes, parentId, newData);

  // 9. Insert the dragged nodes into the new parent node
  insertDraggedNodes(newData, draggedNodes, parentId, index);
  
  // Find the path of the dragged node (from root to direct parent)
  const nodePathInfo = getNodePath(draggedNodes[0].id, data);
  
  if (nodePathInfo) {
    const { path, nodeId } = nodePathInfo;
    
    // Make the API call to update the node path
    const success = await callNodeCommandApi({
      nodeId,
      command: 'move',
      data: { path }
    });
    
    if (!success) {
      console.error('Node move command failed');
      return false;
    }
  } else {
    console.error('Node not found in the tree');
    return false;
  }
  
  // 10. Update the state with the new data structure
  setData(newData);

  return true; // Return true as the operation was successful
};
