// src/GlobalVue/Treeutils/helpers/api/callGenerateNodeIdApi.ts

import config from "../../../../config";
import { postRequest } from "../../../../api/Request";

// Define the API URL for generating a new node ID
const GenerateNodeIdURL = `${config.API_URL}/generateNodeId`;

// Function to call the backend API to generate a new node ID
export const callGenerateNodeIdApi = async (parentId: string): Promise<string | null> => {
  const payload = {
    message: "Generate new node ID",
    data: {
      parentId,
    },
  };

  try {
    // Make the POST request to the backend
    const response = await postRequest(GenerateNodeIdURL, payload);

    // Check if there's an error in the response
    if (response.error) {
      console.error("Error:", response.message);
      return null;
    }

    // If the response is successful and contains the new node ID
    if (response && response.data && response.data.newNodeId) {
      console.log("Received new node ID from server:", response.data.newNodeId);
      return response.data.newNodeId;
    } else {
      console.warn("Invalid response:", response);
      return null;
    }
  } catch (error) {
    console.error("Error while generating new node ID:", error);
    return null;
  }
};
