// src/GlobalVue/Treeutils/helpers/handleSaveEdit.ts

import { Node } from '../types';
import { updateNodeNameById } from '../helpers';
import { callNodeCommandApi } from '../api/callNodeCommandApi';

interface HandleSaveEditParams {
  nodeId: string;
  data: Node[];
  setData: React.Dispatch<React.SetStateAction<Node[]>>;
  newNodeName: string;
  newlyAddedNodeId: string | null;
  handleCancelEdit: (nodeId?: string) => void;
  handleDeleteNode: (nodeId: string) => Promise<void>;
}

export const handleSaveEditLogic = async ({
  nodeId,
  data,
  setData,
  newNodeName,
  newlyAddedNodeId,
  handleCancelEdit,
  handleDeleteNode,
}: HandleSaveEditParams): Promise<void> => {
  if (newNodeName.trim() !== '') {
    // Call the backend API
    const success = await callNodeCommandApi({
      nodeId,
      command: 'save',
      data: { name: newNodeName.trim() },
    });

    if (success) {
      // Proceed with local state update
      const updatedData = updateNodeNameById(data, nodeId, newNodeName.trim());
      setData(updatedData);
    } else {
      // Handle failure
      console.error('Failed to save node on server.');
    }
  } else if (newlyAddedNodeId === nodeId) {
    // Remove the node if it's new and the name is empty
    await handleDeleteNode(nodeId);
  }
  handleCancelEdit();
};
