import { GraphType } from '../../graph/GraphTypes';
import { graphProps as incidentsEvolutionProps } from "../../graph/graphprops/IncidentsEvolutionProps";
import { graphProps as alertsEvolutionProps } from "../../graph/graphprops/AlertsEvolutionProps";
import { graphProps as alertsEvolutionWithLegendProps } from "../../graph/graphprops/AlertsEvolutionWithLegendProps";
import { graphProps as statusEvolutionProps } from "../../graph/graphprops/StatusEvolutionProps";
import { Interval } from '../../navigation/tabs/TabsTypes';
import { ChartDataSets } from 'chart.js';

export const selectChartConfig = (
  graphType: GraphType,
  dates: Date[],
  interval: Interval,
  startDatePeriodTimestamp: number,
  endDatePeriodTimestamp: number,
  datasets: ChartDataSets[]
) => {
  switch (graphType) {
    case GraphType.INCIDENTS_COUNT:
      return incidentsEvolutionProps(dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp, datasets);
    case GraphType.OVERLOADED_AREA_ALARM:
    case GraphType.AREA_IN_ALARMS_EVOLUTION:
    case GraphType.HAT_ALARMS_EVOLUTION:
    case GraphType.LYING_PERSON_ALARMS_EVOLUTION:
    case GraphType.COLLISION_ALARM_EVOLUTION:
      return alertsEvolutionProps(dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp, datasets);
    case GraphType.ALARMS_EVOLUTION:
      return alertsEvolutionWithLegendProps(dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp,datasets);
    case GraphType.STATUS_EVOLUTION:
      return statusEvolutionProps(dates, interval, startDatePeriodTimestamp, endDatePeriodTimestamp,datasets);
    default:
      throw new Error('Invalid graph type');
  }
};
