import { prepareHeatmapData } from './prepareHeatmapData';
import { createCanvas, removeCanvas } from './canvasHelpers';
import { renderPlotlyHeatmap, convertPlotlyToImage } from './plotlyHelpers';
import { createHeatmapTrace, createHeatmapLayout, createHeatmapConfig } from './heatmapHelpers'; // Refactored helper imports

/**
 * Creates a heatmap chart and returns it as a base64 image string.
 * @param cleanedData - The cleaned data used for plotting the heatmap.
 * @param weekNumber - The week number for which the heatmap is generated.
 * @param showAlertCount - Whether to show alert count (optional).
 * @returns Promise<string> - A base64 image string of the generated heatmap.
 */
export const createHeatmap = async (
  cleanedData: { rule: string, dates: string[], count: number, language: string }[],
  weekNumber: number,
  showAlertCount: boolean = false
): Promise<string> => {
  if (!Array.isArray(cleanedData) || cleanedData.length === 0) {
    const errorMessage = 'Invalid or empty cleanedData input';
    console.error(errorMessage);
    throw new Error(errorMessage);
  }

  let canvas: HTMLDivElement | null = null;

  try {
    // Prepare the heatmap data using the helper function
    const { heatmapData, dates, hours, zmin, zmax } = await prepareHeatmapData(cleanedData);

    // Create the Plotly heatmap configurations
    const trace = createHeatmapTrace(heatmapData, dates, hours, zmin, zmax, showAlertCount);
    const layout = createHeatmapLayout(dates, hours);
    const config = createHeatmapConfig();

    // Create canvas, render heatmap, and convert to image
    canvas = createCanvas();
    await renderPlotlyHeatmap(canvas, trace, layout, config);
    const chartImage = await convertPlotlyToImage(canvas);

    return chartImage; // Return the base64 image URL
  } catch (error) {
    console.error('Error generating heatmap:', error);
    throw new Error('Failed to generate heatmap with Plotly: ' + error);
  } finally {
    // Ensure the canvas is removed after processing
    if (canvas) {
      removeCanvas(canvas);
    }
  }
};
