// src/ai/pdf/AIText/utils.ts

import jsPDF from 'jspdf';
import { renderText } from './renderText';

/**
 * Estimates the height required to render a section based on its content.
 * @param doc - jsPDF instance
 * @param sectionElements - Array of HTML elements in the section
 * @param margin - Margin to apply
 * @returns Estimated height in units
 */
export const estimateSectionHeight = (
  doc: jsPDF,
  sectionElements: HTMLElement[],
  margin: number
): number => {
  let estimatedHeight = 0;

  sectionElements.forEach((element) => {
    switch (element.tagName.toLowerCase()) {
      case 'h4':
        estimatedHeight += 18; // Approximate height for h4
        break;
      case 'h5':
        estimatedHeight += 14; // Approximate height for h5
        break;
      case 'p':
        estimatedHeight += estimateTextHeight(doc, element.textContent || '', 12, margin);
        break;
      case 'ul':
      case 'ol':
        const listItems = element.querySelectorAll('li');
        estimatedHeight += listItems.length * 7; // Approximate height per list item
        break;
      default:
        estimatedHeight += estimateTextHeight(doc, element.textContent || '', 12, margin);
        break;
    }
  });

  return estimatedHeight;
};

/**
 * Estimates the height of the text block based on the text content and font size.
 * @param doc - jsPDF instance
 * @param text - The text to estimate height for
 * @param fontSize - Font size used for the text
 * @param margin - Margin to apply
 * @returns Estimated height in units
 */
export const estimateTextHeight = (
  doc: jsPDF,
  text: string,
  fontSize: number,
  margin: number
): number => {
  doc.setFontSize(fontSize);
  const lines = doc.splitTextToSize(text, doc.internal.pageSize.getWidth() - margin * 2);
  return lines.length * 7; // Approximate line height
};
