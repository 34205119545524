import React from "react";
import { DateRangePicker } from "rsuite";
import { startOfWeek, endOfWeek, subWeeks, startOfDay, subDays, format } from "date-fns";
import i18n from "../../../i18n";
import classes from "./DateRangeSelector.module.css";
import { ValueType } from "rsuite/lib/DateRangePicker";
import { Interval } from "../TabsTypes";

interface DateRangeSelectorProps {
  dateFormat: string;
  startDatePeriodTimestamp: number;
  endDatePeriodTimestamp: number;
  onPeriodChange: (value: any, event: React.SyntheticEvent) => void;
  onIntervalChanged: (interval: Interval) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  dateFormat,
  startDatePeriodTimestamp,
  endDatePeriodTimestamp,
  onPeriodChange,
  onIntervalChanged,
}) => {
  const now = new Date();
  const startOfThisWeek = startOfWeek(now, { weekStartsOn: 1 });
  const startOfLastWeek = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
  const endOfLastWeek = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
  const midnightToday = startOfDay(now);

  // Retrieve weekday translations based on the current language
  const datePickerLocale = i18n.t("DatePickerLocale", { returnObjects: true });

  const handlePeriodChange = (dates: ValueType, event: React.SyntheticEvent) => {
    if (dates && dates.length === 2) {
      const startDate = dates[0]?.getTime() || now.getTime();
      const endDate = dates[1]?.getTime() || now.getTime();
      const adjustedEndDate = Math.min(endDate + 86400000 - 1, Date.now());

      const intervalType = adjustedEndDate - startDate > 86400000 ? Interval.DAY : Interval.HOUR;
      onIntervalChanged(intervalType);

      onPeriodChange([startDate, adjustedEndDate], event);
    }
  };

  return (
    <div className={classes.DashboardDateRangeContainer}>
      <DateRangePicker
        locale={{
          sunday: i18n.t("Su"),
          monday: i18n.t("Mo"),
          tuesday: i18n.t("Tue"),
          wednesday: i18n.t("Wed"),
          thursday: i18n.t("Thu"),
          friday: i18n.t("Fri"),
          saturday: i18n.t("Sat"),
        }}
        format={dateFormat}
        disabledDate={DateRangePicker.allowedMaxDays(31)}
        placement="bottomEnd"
        cleanable={false}
        value={
          startDatePeriodTimestamp && endDatePeriodTimestamp
            ? [new Date(startDatePeriodTimestamp), new Date(endDatePeriodTimestamp)]
            : [startOfThisWeek, now]
        }
        placeholder={i18n.t("SelectPeriod")}
        onChange={handlePeriodChange}
        ranges={[
          { label: i18n.t("ThisWeek"), value: [startOfThisWeek, now] },
          { label: i18n.t("LastWeek"), value: [startOfLastWeek, endOfLastWeek] },
          { label: i18n.t("Last7Days"), value: [subDays(now, 7), now] },
          { label: i18n.t("Today"), value: [midnightToday, now] },
        ]}
        renderValue={(value) => {
          if (value && value.length === 2) {
            return `${format(value[0] || now, dateFormat)} ~ ${format(value[1] || now, dateFormat)}`;
          }
          return "";
        }}
      />
    </div>
  );
};

export default DateRangeSelector;
