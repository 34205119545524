// tableData.ts
import { TableData } from './types'; // Import TableData from types.ts
import { DefaultColumnFilter } from './filters'; // Import the DefaultColumnFilter

// Initial table data
export const tableData: TableData[] = [
  { id: 1, name: 'Item 1' },
  { id: 2, name: 'Item 2' },
  { id: 3, name: 'Item 3' },
];

// Column definitions for the table
export const tableColumns = [
  {
    Header: 'ID',
    accessor: 'id' as const,
    Filter: DefaultColumnFilter,
  },
  {
    Header: 'Name',
    accessor: 'name' as const,
    Filter: DefaultColumnFilter,
  },
];
