import React, { useState, useEffect } from 'react';
import TreeComponent from './Treecomponent';
import TableComponent from './tablecomponent';
import styles from './GlobaleView.module.css';
import { Node } from './Treeutils/helpers/types';
import { initialData } from './Treeutils/helpers/treeData'; // initialData is async
import Spinner from './Treeutils/components/Spinner'; // Import your spinner component

const GlobalViewComponent: React.FC = () => {
  const [data, setData] = useState<Node[]>([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true); // New loading state
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        const result = await initialData(); // Fetch data asynchronously
        setData(result); // Set data after fetching
      } catch (error) {
        console.error('Error fetching initial data:', error);
      } finally {
        setLoading(false); // Stop loading when done
      }
    };
    fetchData();
  }, []); // Empty dependency array ensures this runs once on mount

  // Display spinner until the component is fully loaded
  if (loading) {
    return (<Spinner />); // Show loading spinner until everything is loaded
  }

  return (
    <div className={styles.container}>
      {/* Tree Component */}
      <div className={styles.treeSection}>
        {/* Search Input */}
        <div className={styles.searchContainer}>
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchTerm && (
            <button className={styles.clearButton} onClick={() => setSearchTerm('')}>
              Clear
            </button>
          )}
        </div>
        <TreeComponent data={data} setData={setData} searchTerm={searchTerm} />
      </div>

      {/* Table Component */}
      <div className={styles.tableSection}>
        <TableComponent />
      </div>
    </div>
  );
};

export default GlobalViewComponent;
