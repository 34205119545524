// src/GlobalVue/Treeutils/helpers/associateNode.ts
import { Node } from '../types';
import { findNodeById, removeNodeAndGetParentId, findOrCreateSecurispotNode, updateAssociationStatus } from '../helpers';

export const handleAssociateToggleLogic = async (
  nodeId: string,
  data: Node[],
  setData: React.Dispatch<React.SetStateAction<Node[]>>
): Promise<void> => {
  const node = findNodeById(data, nodeId);
  if (!node) return;

  console.log('Association called');

  // Step 1: Prepare to update the association status
  const { updatedData } = removeNodeAndGetParentId(data, nodeId);
  const associatedNode: Node = { ...node };

  // Step 2: Find or create "Securispot NA" node
  const securispotNode = findOrCreateSecurispotNode(updatedData);

  // Ensure children is defined
  if (!securispotNode.children) {
    securispotNode.children = [];
  }

  // Set the association status for the node (dissociated under "Securispot NA")
  const isAssociated = false;
  securispotNode.children.push(associatedNode);

  // Step 3: Call the API to update association status
  const apiSuccess = await updateAssociationStatus(nodeId, isAssociated);

  if (apiSuccess) {
    // Step 4: Only update local state if the API call was successful
    associatedNode.isAssociated = isAssociated;
    setData(updatedData);
  } else {
    console.error('Failed to update association status via API');
  }
};
