// src/devicessearch/DeviceRow/DeviceRows.jsx

import { useEffect } from "react";
import { useDrag } from 'react-dnd';
import { getEmptyImage } from "react-dnd-html5-backend";
import { NodeType } from "../../navigation/tree/NodeType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faEye, faMicrochip, faPen, faSimCard, faWeight, faWifi } from "@fortawesome/free-solid-svg-icons";
import classes from "../DevicesSearch.module.css";
import { getI18n, Trans } from "react-i18next";
import { isSuperAdmin } from "../../api/Auth";

export default function DeviceRow({
  nodeType,
  id,
  serial,
  reference,
  isDeployed,
  onUpdate,
  fullObject,
  onShowClick,
  onEditDeviceClick
}) {
  const [{ opacity }, dragRef, preview] = useDrag({
    item: { id: id, type: "device", subtype: nodeType, serial: serial, update: onUpdate, isDeployed: isDeployed, fullObject },
    type: "device",
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.7 : 1,
      dragHandlerId: monitor.getHandlerId(),
    }),
    canDrag: !isDeployed,
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  return (
    <tr
      role={"tr"}
      key={id}
      id={id}
      ref={!isDeployed ? dragRef : undefined}
      className={isDeployed === true ? classes.DeviceSearchResultRowUndraggable : classes.DeviceSearchResultRowDraggable}
      style={{ opacity }}
    >
      <td style={{ cursor: "pointer" }} onClick={() => onShowClick(fullObject)}>
        <FontAwesomeIcon icon={faEye} />
      </td>
      <td>
        <>
          {nodeType === NodeType.SECURISPOT ? <FontAwesomeIcon fixedWidth={true} icon={faWifi} /> : undefined}
        </>
        &nbsp;&nbsp;{getI18n().t(nodeType.charAt(0).toUpperCase() + nodeType.slice(1))}
      </td>
      <td>{reference}</td>
      <td>{serial}</td>
      <td>
        <Trans>{isDeployed ? "Yes" : "No"}</Trans>
      </td>
      {/* Disable the edit button for Securispot */}
      {isSuperAdmin() && nodeType !== NodeType.SECURISPOT ? (
        <td onClick={() => onEditDeviceClick(fullObject)}>
          <FontAwesomeIcon fixedWidth={true} icon={faPen} />
        </td>
      ) : null}
    </tr>
  );
}
