import { getAIResponse } from '../api/openai';
import { countEntries } from './datahelpers/countEntries';
import i18n from '../i18n';
import { removeNullOrZeroValues } from './datahelpers/removeNullOrZeroValues';
import { aggregateRulesData } from './datahelpers/aggregateRulesData';
import { aggregateSpecificRuleData } from './datahelpers/aggregateSpecificRuleData';
import { GraphType } from '../graph/GraphTypes';

interface ApiResponseDataItem {
  date?: string;
  hostname?: string;
  language?: string;
  [key: string]: any; // Other properties like rule counts
}

interface AggregatedRule {
  rule: string;
  dates: string[];
  count: number;
  language: string;
}

export const fetchAIResponse = async (
  apiResponseData: ApiResponseDataItem[] | undefined,
  setAIResponse: (response: string | undefined) => void,
  setShowAIModal: (show: boolean) => void,
  setIsRequestingAI: (isRequesting: boolean) => void,
  graphType?: GraphType
) => {
  if (!apiResponseData || !Array.isArray(apiResponseData) || apiResponseData.length === 0) {
    console.error('apiResponseData is invalid or empty.');
    return;
  }

  // Step 1: Clean the data
  const cleanedData = removeNullOrZeroValues(apiResponseData);

  if (!cleanedData || countEntries(cleanedData) === 0) {
    const insufficientDataMessage = `
      <div>
        <h4>${i18n.t('ai_analysis_not_done')}</h4>
        <p>${i18n.t('insufficient_data_message')}</p>
      </div>
    `;
    setAIResponse(insufficientDataMessage);
    setShowAIModal(true);
    return;
  }

  // Step 2: Add language to each item
  const userLanguage = i18n.language || 'fr';

  if (Array.isArray(cleanedData)) {
    cleanedData.forEach((item) => {
      if (typeof item === 'object' && item !== null) {
        item.language = userLanguage;
      }
    });
  } else if (typeof cleanedData === 'object' && cleanedData !== null) {
    cleanedData.language = userLanguage;
  } else {
    console.error('cleanedData is neither an array nor an object.');
    return;
  }

  // Step 3: Aggregate data
  let aggregatedDataArray: AggregatedRule[] | undefined;
  const firstItem = cleanedData[0];

  if ('date' in firstItem) {
    // General aggregation
    aggregatedDataArray = aggregateRulesData(cleanedData);
  } else if ('hostname' in firstItem) {
    // Specific aggregation
    aggregatedDataArray = aggregateSpecificRuleData(cleanedData, graphType, userLanguage);
  } else {
    console.error('Unexpected data structure in apiResponseData');
    return;
  }

  if (!aggregatedDataArray || aggregatedDataArray.length === 0) {
    const insufficientDataMessage = `
      <div>
        <h4>${i18n.t('ai_analysis_not_done')}</h4>
        <p>${i18n.t('insufficient_data_message')}</p>
      </div>
    `;
    setAIResponse(insufficientDataMessage);
    setShowAIModal(true);
    return;
  }

  setIsRequestingAI(true);

  try {
    const response = await getAIResponse(aggregatedDataArray);
    let aiMessage = response?.aimessage || '';

    if (aiMessage.startsWith('```html') && aiMessage.endsWith('```')) {
      aiMessage = aiMessage.substring(7, aiMessage.length - 3).trim();
    }

    setAIResponse(aiMessage);
    setShowAIModal(true);
  } catch (err) {
    const errorMessage = `
      <div>
        <h4>${i18n.t('error_title')}</h4>
        <p>${i18n.t('ai_error_message')}</p>
      </div>
    `;
    setAIResponse(errorMessage);
    setShowAIModal(true);
  } finally {
    setIsRequestingAI(false);
  }
};
