import { Chart } from 'chart.js';
import i18n from '../../i18n';
import { GraphType } from '../../graph/GraphTypes';

export const getChartTitle = (graphType: GraphType): string => {
  switch (graphType) {
    case GraphType.INCIDENTS_COUNT:
      return i18n.t('Incidents Evolution');
    case GraphType.ALARMS_EVOLUTION:
      return i18n.t('Alarms Evolution');
    case GraphType.STATUS_EVOLUTION:
      return i18n.t('Status Evolution');
    case GraphType.HAT_ALARMS_EVOLUTION:
      return i18n.t('Hat Alarms Evolution');
    case GraphType.OVERLOADED_AREA_ALARM:
      return i18n.t('OverLoad Area Alarms');
    case GraphType.AREA_IN_ALARMS_EVOLUTION:
      return i18n.t('Area In Alarms Evolution');
    case GraphType.LYING_PERSON_ALARMS_EVOLUTION:
      return i18n.t('Lying Person Alarms Evolution');
    case GraphType.COLLISION_ALARM_EVOLUTION:
      return i18n.t('Collision Alarm Evolution');
    default:
      return '';
  }
};

export const getDefaultChartOptions = (title: string, maxValue?: number, preventUpdateAnimations?: boolean): Chart.ChartOptions => ({
  responsive: true,
  maintainAspectRatio: false,
  title: {
    display: true,
    text: title,
    fontSize: 18,
    fontColor: '#333',
    fontStyle: 'bold',
  },
  legend: {
    display: true,
    labels: {
      fontColor: '#333',
      fontSize: 12,
    },
  },
  scales: {
    xAxes: [
      {
        type: 'time',
        distribution: 'linear',
        time: {
          tooltipFormat: 'll HH:mm',
          displayFormats: {
            hour: 'MMM D, hA',
            day: 'MMM D',
            week: 'MMM D',
            month: 'MMM YYYY',
          },
        },
        gridLines: {
          color: '#e0e0e0',
        },
        ticks: {
          fontColor: '#333',
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 10,
        },
        stacked: false
      },
    ],
    yAxes: [
      {
        gridLines: {
          color: '#e0e0e0',
        },
        ticks: {
          beginAtZero: true,
          fontColor: '#333',
          suggestedMax: maxValue ? maxValue * 1.1 : undefined,
        },
        stacked: false, // Ensures each dataset starts from zero independently
      },
    ],
  },
  tooltips: {
    enabled: true,
    backgroundColor: '#004aac',
    titleFontColor: '#fff',
    bodyFontColor: '#fff',
    footerFontColor: '#fff',
    mode: 'index',
    intersect: false,
  },
  elements: {
    line: {
      tension: 0.4, // Smooth curves
    },
  },
  plugins: {
    zoom: {
      zoom: {
        enabled: true,
        drag: false,
        mode: 'x',
        speed: 0.05,
      },
      pan: {
        enabled: true,
        mode: 'x',
        speed: 10,
      },
    },
  },
  animation: {
    duration: preventUpdateAnimations ? 0 : undefined,
  },
});
