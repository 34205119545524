// src/ai/pdf/AIText/processSection.ts

import jsPDF from 'jspdf';
import { renderText } from './renderText';
import { estimateSectionHeight } from './utils';
import { processElement } from './processElement';
/**
 * Processes a section consisting of an <h4> heading and its associated content.
 * Ensures the section stays on the same page by adding a page break if necessary.
 * @param doc - jsPDF instance
 * @param sectionElements - Array of HTML elements in the section
 * @param yPosition - Current Y position in the PDF
 * @param margin - Margin to apply
 * @returns Updated Y position after processing the section
 */
export const processSection = (
  doc: jsPDF,
  sectionElements: HTMLElement[],
  yPosition: number,
  margin: number
): number => {
  const pageHeight = doc.internal.pageSize.getHeight();

  // Estimate the height required for the section
  const estimatedHeight = estimateSectionHeight(doc, sectionElements, margin);

  // Check if there's enough space on the current page
  if (yPosition + estimatedHeight > pageHeight - margin) {
    doc.addPage();
    yPosition = margin;
  }

  // Process each element in the section
  sectionElements.forEach((element) => {
    yPosition = processElement(doc, element, yPosition, margin);
  });

  return yPosition;
};
