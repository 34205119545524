
import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translations: {
                    Serial: "Serial",
                    Reference: "Reference",
                    Yes: "Yes",
                    No: "No",
                    NoDataOnThisPeriod: "No data on this period",
                    Confirmation: "Confirmation",
                    AreYouSureYouWantToDissociate: "Are you sure you want to dissociate",
                    PleaseSelectAStockAreaToPutDeviceYouWantToDissociate: "Please select a stock area to put device you want to dissociate",
                    Dissociate: "Dissociate",
                    Password: "Password",

                    //Tabs items
                    GlobalTab: "Global view",
                    AreaTab: "Area monitoring",
                    HelmetTab: "Helmet wearing",
                    LyingTab: "Lying person",
                    OverloadedTab: "Overloaded area",
                    collisionTab: "Collision detection",
                    AlarmsTab: "Alarms list",

                    //Sidebar
                    Home: "Home",
                    Supervision: "Supervision",
                    Administration: "Administration",
                    AssignementManagement: "Assignement management",
                    EquipmentManagement: "Equipment management",
                    Sensors: "Sensors",

                    // Interval selector
                    PerHour: "Per hour",
                    PerDay: "Per day",
                    d: "d",

                    //Table headers
                    Securispot: "Securispot",
                    Date: "Date",
                    Description: "Description",
                    Duration: "Duration",

                    // Graphs legends
                    HelmetWearing: "Helmet Not Worn",
                    LyingPerson: "Person Lying Down",
                    OverloadedArea: "Entry into Overloaded Area",
                    Area: "Entry into an Area",
                    Collision: "Person-Vehicule Collision",

                    // Graphs titles
                    AlarmFrom: "Alarms from",
                    AlarmTo: "to",
                    AllSecurispots: "All SecuriSPOTs",
                    NoAlarm: "No alarm",
                    EventList: "Event list",
                    Alerts: "Alerts",
                    EntryZone: "Entry Zone Alarms from",
                    HelmetNotWorn: "Helmet Not Worn Alarms from",
                    LyingAlarms: "Lying Person Alarms from",
                    OverloadedAlarms: "Overloaded Area Entry Alarms from",
                    CollisionAlarms: "Collision Detection Alarms from",

                    // Export
                    Export: "Export",

                    // Analysis
                    Analysis : "AI Analysis",
                    Analyzing : "Analyzing...",

                    // Response
                    "No response" : "No response" ,
                    "Nombre de personnes": "Number of People",
                    // Retrying
                    Retrying: "Retrying...",
                    "Retry AI" : "Retry AI",
                    //Close
                    Close : "Close",
                    "Download Report": "Download Report",
                    //
                    "ai_analysis_not_done": "AI analysis not performed",
                    "insufficient_data_message": "More data is required for AI analysis. Please provide more data.",
                    "error_title": "Error",
                    "ai_error_message": "An error occurred while retrieving the AI response. Please try again later.",
                    'AI_Report.pdf': 'AI_Report.pdf',
                    'Heatmap Section':'Heatmap Section',
                    'AI-Generated Insights':'AI-Generated Insights',
                    'AI-Generated Report':'AI-Generated Report',
                    'Heatmap from':'Heatmap from',
                    'to1':'to',
                    // Date range picker
                    SelectPeriod: "Select period",
                    Last24Hours: "Last 24 hours",
                    Last48Hours: "Last 48 hours",
                    Last7Days: "Last 7 days",
                    LastMonth: "Last month",
                    LastYear: "Last year",
                    Today: "Today",
                    Yesterday: "Yesterday",
                    LastWeek: "Last week",
                    // Days of the week 
                    Sunday : "Sunday",
                    Monday : "Monday",
                    Tuesday: "Tuesday",
                    Wednesday : "Wednesday",
                    Thursday : "Thursday",
                    Friday : "Friday",
                    Saturday: "Saturday",
                    "Start Date:":"Start Date:",
                    "End Date:":"End Date:",
                    // HeatMap stuff 
                    'Alerts by day and time': 'Alerts by day and time',
                    'Number of alerts' : 'Number of alerts',
                    'Percentage of alerts' : 'Percentage of alerts',
                    'Days of the week' : 'Days of the week',
                    'Hours of the day' : 'Hours of the day',
                    // Modal Filter
                    "Clear Filters":"Clear Filters",
                    SelectDevice: "Select a SecuriSPOT",
                    SelectAll: "Select all",
                    ApplyFilters: "Apply filters",
                    "Clear Filter" : "Clear Filter",
                    "No options found.":"No options found.",
                    // week Date
                    // Abr Days
                    Su : "Su",
                    Mo: "Mo",
                    Tue: "Tue",
                    Wed : "Wed",
                    Thu : "Thu",
                    Fri : "Fri",
                    Sat: "Sat",
                    "OK": "OK",
                    "Hours": "Hours",
                    "Minutes": "Minutes",
                    "Seconds": "Seconds",
                    "ThisWeek": "This Week",
                    AreYouSureYouWantToRemoveThisArea: "Are you sure you want to remove this area ?",
                    AreYouSureYouWantToRemoveThisDevice: "Are you sure you want to remove this device ?",
                    AreYouSureYouWantToRemoveThisMediaspot: "Are you sure you want to remove this mediaspot ? All modacs associated to this mediaspots will be dissociated too",

                    DropThisMediaspotOnABusToBindIt: "Drop this mediaspot on a bus to bind it",
                    DropThisRouterOnAMediaspotToBindIt: "Drop this router on a mediaspot to bind it",
                    DropThisModacOnAMediaspotToBindTt: "Drop this modac on mediaspot to bind it",
                    DropThisSimOnARouterToBindIt: "Drop this SIM card on a router to bind it",
                    DropThisSensorOnARouterToBindIt: "Drop this sensor on a mediaspot to bind it",

                    DownloadEnabledUpdated: "Download enabled",
                    DownloadDisabledUpdated: "Download disabled",
                    SyncNowUpdated: "Sync in progress ",
                    DownloadAuthTokenSecretUpdated: "Download auth token secret updated",
                    MakeAnalyticsNowUpdated: "Analytics started",
                    ClientRemoveByNameUpdated: "Deleted client",
                    BandUpdated: "Updated band",
                    ChannelUpdated: "Updated channel",
                    SsidUpdated: "Updated SSID",
                    TxPowerUpdated: "Updated Tx power",
                    RadioEnabledUpdated: "Enabled radio",
                    RadioDisabledUpdated: "Disabled radio",
                    WpaPasswordUpdated: "Updated WPA password",
                    BssUtilizationUpdated: "Updated bss utilization",
                    ObssUtilizationUpdated: "Updated obss utilization",
                    IdleUpdated: "Updated idle channel",
                    AllowedPortsUdpUpdated: "Updated allowed UDP ports",
                    AllowedPortsTcpUpdated: "Updated allowed TCP ports",
                    InternetBlockingEnabledUpdated: "Internet blocking enabled",
                    InternetBlockingDisabledUpdated: "Internet blocking disabled",
                    AutoCaptivePortalEnabledUpdated: "Automatic captive portal enabled",
                    AutoCaptivePortalDisabledUpdated: "Automatic captive portal disabled",
                    InternetWhitelistUpdated: "Updated internet whitelist",
                    WelcomepageZipUrlUpdated: "Updated welcomepage zip url",
                    MediaspotNameUpdated: "Mediaspot name updated",
                    MediacenterHostUpdated: "Mediacenter host updated",
                    Tr069HostUpdated: "TR069 host updated",
                    WebsocketHostUpdated: "Websocket server host updated",
                    FirmwareUpdateUrlUpdated: "Firmware update request OK",
                    MediaspotCustomInfoUpdated: "Custom JSON updated",

                    DownloadEnabledRequest: "Download activation",
                    DownloadDisabledRequest: "Disable download",
                    SyncNowRequest: "Synchronization request",
                    DownloadAuthTokenSecretRequest: "Download auth token secret request",
                    MakeAnalyticsNowRequest: "Analytics",
                    ClientRemoveByNameRequest: " Client deletion",
                    BandRequest: "Updating Wi-Fi band",
                    ChannelRequest: "Updating Wi-Fi channel",
                    SsidRequest: "Updating SSID",
                    TxPowerRequest: "Updating Wi-Fi transmit power",
                    RadioEnabledRequest: "Radio activation",
                    RadioDisabledRequest: "Radio deactivation",
                    WpaPasswordRequest: "Updating WPA password",
                    BssUtilizationRequest: "Updating bss utilization",
                    ObssUtilizationRequest: "Updating obss utilization",
                    IdleRequest: "Updating idle",
                    AllowedPortsUdpRequest: "Updating allowed UDP ports",
                    AllowedPortsTcpRequest: "Updating allowed TCP ports",
                    InternetBlockingEnabledRequest: "Enabling internet blocking",
                    InternetBlockingDisabledRequest: "Disabling internet blocking",
                    AutoCaptivePortalEnabledRequest: "Enable automatic captive portal",
                    AutoCaptivePortalDisabledRequest: "Disable automatic captive portal",
                    InternetWhitelistRequest: "Updating internet whitelist",
                    WelcomepageZipUrlRequest: "Updating welcome page zip url",
                    MediaspotNameRequest: "Updating mediaspot name",
                    MediacenterHostRequest: "Updating mediacenter host",
                    Tr069HostRequest: "Updating TR069 host",
                    WebsocketHostRequest: "Updating websocket server host",
                    FirmwareUpdateUrlRequest: "Updating firmware update url",
                    MediaspotCustomInfoRequest: "Updating mediaspot custom JSON",

                    ContentProviders: "Content providers",
                    WifiInterfaces: "Wifi interfaces",
                    Firewall: "Firewall",
                    Welcomepage: "Welcome page",
                    StatusMonitor: "Status monitor",
                    AdvancedParameters: "Advanced parameters",
                    CustomData: "Custom data",

                    // Administration device search
                    Type: "Type",
                    Associated: "Associated",
                    Radiameter: "Radiameter",
                    radiameter: "radiameter",
                    Search: "Search",
                    All: "All",
                    AnErrorOccurred: "An error occurred",
                    AnErrorOccurredPleaseReload: "An error occurred, please reload",
                    AnErrorOccurredDuringLoading: "An error occurred during loading",
                    Reload: "Reload",
                    SearchResultsForTypes: "Search results for types",
                    SearchResultsForType: "Search results for type",
                    AssociatedOnly: "associated only",
                    NotAssociatedOnly: "not associated only",
                    WithReference: "with reference",
                    WithSerial: "with serial",
                    AnyDeviceMatchingWithSearchFilters: "No device matching with search filters",
                    YouHaveToPickAtLeastOneTypeOfDevice: "You have to pick at least one type of device",

                    AreaLabelNotConform: "Area label contains unauthorized characters.",
                    AreYouSureYouWantToReplace: "Are you sure you want to replace",
                    With: "with",
                    Percent: "Percent",

                    //User utils
                    Disconnect: "Log out",
                    SureToDisconnect: "Are you sure you want to log out?",

                    RigthReserved: "All rights reserved to Fastpoint",
                    ValidAccess: "Access to this service is strictly reserved for authenticated users with valid access.",

                    //SignUP 
                    "Don't have an account?":"Don't have an account?",
                    "Sign Up here":"Sign Up here",
                    "Signup successful! Redirecting...":"Inscription réussie! Redirection...",
                    "First Name":"First Name",
                    "Last Name":"Last Name",
                    Hide: "Hide",
                    Show:"Show",
                    "Confirm Password":"Confirm Password",
                    "Site Owner (fpAdmin)":"Site Owner (fpAdmin)",
                    "Company Admin (isAdmin)": "Company Admin (isAdmin)",
                    "Sign Up":"Sign Up",
                    "Select Client":"Select Client",
                    "create user":"create user",
                    "Signup successful":"Signup successful",
                    "Back":"Back",
                    "Send Email":"Send Email",
                    //Password validation
                    "Password must be at least 8 characters long.": "Password must be at least 8 characters long.",
                    "Password must contain at least one uppercase letter.": "Password must contain at least one uppercase letter.",
                    "Password must contain at least one lowercase letter.": "Password must contain at least one lowercase letter.",
                    "Password must contain at least one number.": "Password must contain at least one number.",
                    "Password must contain at least one special character (!@#$%^&*).": "Password must contain at least one special character (!@#$%^&*).",
                    'Please enter a valid email address.':'Please enter a valid email address.',
                    "Passwords do not match.":'Passwords do not match.',

                    //SendEmail 
                    "Creation user for SecuriSPOT SmartView website": "Creation user for SecuriSPOT SmartView website",
                    "Dear": "Dear",
                    "The tech team at FastPoint has successfully created an account for you on the SecuriSPOT SmartView website. Please find your login details below:": "The tech team at FastPoint has successfully created an account for you on the SecuriSPOT SmartView website. Please find your login details below:",
                    "Username": "Username",
                    "Best regards": "Best regards",
                    "The FastPoint Team": "The FastPoint Team",

                    // Confirmation Modal Tree
                    'Please enter the word "Confirm" to proceed.':'Please enter the word "Confirm" to proceed.',
                    'Pasting is not allowed.':'Pasting is not allowed.',
                    "You are moving the node":"You are moving the node",
                    "from":"from",
                    "to":"to",
                    'Type "Confirm" in the field below to proceed:':'Type "Confirm" in the field below to proceed:',
                    'confirm':'confirm',
                    'Cancel':'Cancel',
                     // ChartUtils 
                     "Incidents Evolution": "Incidents Evolution",
                    "Alarms Evolution": "Alarms Evolution",
                    "Status Evolution": "Status Evolution",
                    "Hat Alarms Evolution": "Hat Alarms Evolution",
                    "OverLoad Area Alarms": "OverLoad Area Alarms",
                    "Area In Alarms Evolution": "Area In Alarms Evolution",
                    "Lying Person Alarms Evolution": "Lying Person Alarms Evolution",
                    "Collision Alarm Evolution": "Collision Alarm Evolution",
                },  
            },
            fr: {
                translations: {
                    Serial: "Numéro de série",
                    Reference: "Réference",
                    Yes: "Oui",
                    No: "Non",
                    NoDataOnThisPeriod: "Pas de données sur cette période",
                    Confirmation: "Confirmation",
                    AreYouSureYouWantToDissociate: "Êtes-vous sûre de vouloir dissocier",
                    PleaseSelectAStockAreaToPutDeviceYouWantToDissociate: "Veuillez sélectionner une zone de réserve où mettre l'appareil que vous souhaitez dissocier",
                    Dissociate: "Dissocier",
                    Password: "Mot de passe",

                    //Tabs items
                    GlobalTab: "Vision globale",
                    AreaTab: "Surveillance d'une zone",
                    HelmetTab: "Port du casque",
                    LyingTab: "Personne allongée",
                    OverloadedTab: "Zone sous charge",
                    collisionTab: "Détection Collisions",
                    AlarmsTab: "Liste des alarmes",

                    //Sidebar
                    Home: "Accueil",
                    Supervision: "Supervision",
                    Administration: "Administration",
                    AssignementManagement: "Gestion des affectations",
                    EquipmentManagement: "Gestion des équipements",
                    Sensors: "Capteurs",

                    //Table headers
                    Securispot: "Securispot",
                    Date: "Date",
                    Description: "Description",
                    Duration: "Durée",

                    // Graphs legends
                    HelmetWearing: "Non port du casque",
                    LyingPerson: "Personne allongée",
                    OverloadedArea: "Entrée en zone sous charge",
                    Area: "Entrée dans une zone",
                    Collision: "Collision Personne-Véhicule",

                    //Graphs titles
                    AlarmFrom: "Alarmes du",
                    AlarmTo: "au",
                    AllSecurispots: "Tous les SecuriSPOT",
                    NoAlarm: "Aucune alarme",
                    EventList: "Liste des événements",
                    Alerts: "Alertes",
                    EntryZone: "Alarmes d'entrées dans une zone du",
                    HelmetNotWorn: "Alarmes de non port du casque du",
                    LyingAlarms: "Alarmes de personne allongée du",
                    OverloadedAlarms: "Alarmes d'entrée en zone sous charge du",
                    CollisionAlarms: "Alarmes de détection de collision du",

                    // Export
                    Export: "Exporter",

                    // Analysis
                    Analysis : "Analyses IA",
                    Analyzing : "Analyse...",
                    // Retrying
                    Retrying : "Réessaye...",
                    "Retry AI": "Réessayez l'IA",
                    //Close
                    Close : "Fermer",
                    "Download Report": "télécharge le Report",
                    "ai_analysis_not_done": "Analyse AI non effectuée",
                    "insufficient_data_message": "Plus de données sont nécessaires pour l'analyse AI. Veuillez fournir plus de données.",
                    "error_title": "Erreur",
                    "ai_error_message": "Une erreur s'est produite lors de la récupération de la réponse AI. Veuillez réessayer plus tard.",
                    'AI_Report.pdf': 'Rapport_IA.pdf',
                    'Heatmap Section':'Section de Heatmap ',
                    'AI-Generated Report':"Rapport généré par l'IA ",
                    'Heatmap from':'Carte thermique de',
                    'to1':"jusqu'à",
                    // Response : 
                    "No response" : "Aucune réponse",
                    // Interval selector
                    PerHour: "Par heure",
                    PerDay: "Par jour",
                    d: "j",
                    "Nombre de personnes": "Nombre de personnes",
                    // Date range picker
                    SelectPeriod: "Selectionnez une période",
                    Last24Hours: "Dernières 24h",
                    Last48Hours: "Dernières 48h",
                    Last7Days: "7 derniers jours",
                    LastMonth: "Mois dernier",
                    LastYear: "An dernier",
                    Today: "Aujourd'hui",
                    Yesterday: "Hier",
                    LastWeek: "Semaine dernière",
                    // Jours de la semaine
                    Sunday : "Dimanche",
                    Monday : "Lundi",
                    Tuesday: "Mardi",
                    Wednesday : "Mercredi",
                    Thursday : "Jeudi",
                    Friday : "Vendredi",
                    Saturday: "Samedi",
                    "Start Date:" : "Date de début:",
                    "End Date:" : "Date de fin",
                    // ChartUtils 
                    "Incidents Evolution": "Évolution des Incidents",
                    "Alarms Evolution": "Évolution des Alarmes",
                    "Status Evolution": "Évolution du Statut",
                    "Hat Alarms Evolution": "Évolution des Alarmes de Chapeau",
                    "OverLoad Area Alarms": "Alarmes de Zone Surchargée",
                    "Area In Alarms Evolution": "Évolution de la Zone en Alarmes",
                    "Lying Person Alarms Evolution": "Évolution des Alarmes de Personne Allongée",
                    "Collision Alarm Evolution": "Évolution des Alarmes de Collision",
                    // Abr weekDays :
                    Su : "Di",
                    Mo: "Lu",
                    Tue: "Mar",
                    Wed : "Mer",
                    Thu : "Jeu",
                    Fri : "Ve",
                    Sat: "Sa",
                    // HeatMap stuff 
                    'Alerts by day and time': 'Alertes par jour et heure',
                    'Number of alerts' : 'Nombre d\'alertes',
                    'Percentage of alerts' : 'Pourcentage d\'alertes',
                    'Days of the week' : 'Jours de la semaine',
                    'Hours of the day' : 'Heures de la journée',
                    'AI-Generated Insights': "Informations générées par l'IA ",
                    // Modal Filter
                    "Clear Filters": "Effacer les filtres",
                    SelectDevice: "Selectionner un SecuriSPOT",
                    SelectAll: "Sélectionner tous",
                    ApplyFilters: "Appliquer les filtres",
                    "Clear Filter": "Effacer le filtre",
                    "No options found.":"Aucune option trouvée.",
                    // week Date 

                    "OK": "OK",
                    "Hours": "Heures",
                    "Minutes": "Minutes",
                    "Seconds": "Secondes",
                    "ThisWeek": "Cette Semaine",

                    AreYouSureYouWantToRemoveThisArea: "Êtes-vous sûre de vouloir supprimer cette zone ?",
                    AreYouSureYouWantToRemoveThisDevice: "Êtes-vous sûre de vouloir supprimer cet équipement ?",
                    AreYouSureYouWantToRemoveThisMediaspot: "Êtes-vous sûre de vouloir supprimer ce mediaspot. L'ensemble des modacs y étant associés vont également être dissociés.",

                    DropThisMediaspotOnABusToBindIt: "Déplacer ce mediaspot sur un bus pour le lier",
                    DropThisRouterOnAMediaspotToBindIt: "Déplacer ce routeur sur un mediaspot pour le lier",
                    DropThisModacOnAMediaspotToBindTt: "Déplacer ce modac sur un mediaspot pour le lier",
                    DropThisSimOnARouterToBindIt: "Déplacer cette SIM sur un routeur pour la lier",
                    DropThisSensorOnARouterToBindIt: "Déplacer ce capteur sur un mediaspot pour le lier",

                    DownloadEnabledUpdated: "Téléchargement activé",
                    DownloadDisabledUpdated: "Téléchargement désactivé",
                    SyncNowUpdated: "Synchronisation en cours",
                    DownloadAuthTokenSecretUpdated: "Secret d'authentification basé sur des tokens à jour",
                    MakeAnalyticsNowUpdated: "Analytiques démarrés",
                    ClientRemoveByNameUpdated: "Client supprimé",
                    BandUpdated: "Bande à jour",
                    ChannelUpdated: "Canal à jour",
                    SsidUpdated: "SSID à jour",
                    TxPowerUpdated: "Puissance de transmission à jour",
                    RadioEnabledUpdated: "Radio activée",
                    RadioDisabledUpdated: "Radio désactivée",
                    WpaPasswordUpdated: "Clé WPA à jour",
                    BssUtilizationUpdated: "Utilisation du canal bss à jour",
                    ObssUtilizationUpdated: "Utilisation du canal obss à jour",
                    IdleUpdated: "Canal inactif mis à jour",
                    AllowedPortsUdpUpdated: "Ports UDP sortants autorisés à jour",
                    AllowedPortsTcpUpdated: "Ports TCP sortants autorisés à jour",
                    InternetBlockingEnabledUpdated: "Blocage d'internet activé",
                    InternetBlockingDisabledUpdated: "Blocage d'internet désactivé",
                    AutoCaptivePortalEnabledUpdated: "Portail captif automatique activé",
                    AutoCaptivePortalDisabledUpdated: "Portail captif automatique désactivé",
                    InternetWhitelistUpdated: "Whitelist internet mis à jour",
                    WelcomepageZipUrlUpdated: "URL du zip de la page de bienvenue à jour",
                    MediaspotNameUpdated: "Nom du mediaspot à jour",
                    MediacenterHostUpdated: "Nom d'hôte du mediacenter à jour",
                    Tr069HostUpdated: "Nom d'hôte du TR069 mis à jour",
                    WebsocketHostUpdated: "Nom d'hôte du serveur websocket mis à jour",
                    FirmwareUpdateUrlUpdated: "Demande de mis à jour du firmware OK",
                    MediaspotCustomInfoUpdated: "JSON personnalisé à jour",

                    DownloadEnabledRequest: "Activation du téléchargement",
                    DownloadDisabledRequest: "Désactivation du téléchargement",
                    SyncNowRequest: "Demande de synchronisation",
                    DownloadAuthTokenSecretRequest: "Mise à jour du secret d'authentification basé sur des tokens",
                    MakeAnalyticsNowRequest: "Mise à jour des analytiques",
                    ClientRemoveByNameRequest: "Suppression du client",
                    BandRequest: "Mise à jour de la bande Wi-Fi",
                    ChannelRequest: "Mise à jour du canal Wi-Fi",
                    SsidRequest: "Mise à jour du SSID",
                    TxPowerRequest: "Mise à jour de la puissance de transmission",
                    RadioEnabledRequest: "Activation radio",
                    RadioDisabledRequest: "Désactivation radio",
                    WpaPasswordRequest: "Mise à jour de la clé WPA",
                    BssUtilizationRequest: "Mise à jour de l'utilisation du canal bss",
                    ObssUtilizationRequest: "Mise à jour de l'utilisation du canal obss",
                    IdleRequest: "Mise à jour du canal inactif",
                    AllowedPortsUdpRequest: "Mise à jour des ports UDP sortants autorisés",
                    AllowedPortsTcpRequest: "Mise à jour des ports TCP sortants autorisés",
                    InternetBlockingEnabledRequest: "Activation du blocage d'internet",
                    InternetBlockingDisabledRequest: "Désactivation du blocage d'internet",
                    AutoCaptivePortalEnabledRequest: "Activation du portail captif automatique",
                    AutoCaptivePortalDisabledRequest: "Désactivation du portail captif automatique",
                    InternetWhitelistRequest: "Mise à jour de la whitelist internet",
                    WelcomepageZipUrlRequest: "Mise à jour de l'URL du zip de la page de bienvenue",
                    MediaspotNameRequest: "Mise à jour du nom du mediaspot",
                    MediacenterHostRequest: "Mise à jour du nom d'hôte du mediacenter",
                    Tr069HostRequest: "Mise à jour du nom d'hôte du TR069",
                    WebsocketHostRequest: "Mise à jour du nom d'hôte du serveur websocket",
                    FirmwareUpdateUrlRequest: "Demande de mis à jour du firmware",
                    MediaspotCustomInfoRequest: "Mise à jour du JSON personnalisé",

                    ContentProviders: "Content providers",
                    WifiInterfaces: "Interfaces Wifi",
                    Firewall: "Pare-feu",
                    Welcomepage: "Welcome page",
                    StatusMonitor: "Moniteur d'état",
                    AdvancedParameters: "Paramètres avancés",
                    CustomData: "Données personnalisées",

                    // Administration device search
                    Type: "Type",
                    Associated: "Associé",
                    Radiameter: "Radiamètre",
                    radiameter: "radiamètre",
                    Search: "Rechercher",
                    All: "Tous",
                    AnErrorOccurred: "Une erreur s'est produite",
                    AnErrorOccurredPleaseReload: "Une erreur s'est produite, veuillez recharger",
                    AnErrorOccurredDuringLoading: "Une erreur s'est produite pendant le chargement",
                    Reload: "Recharger",
                    SearchResultsForTypes: "Résultats de recherche pour les types",
                    SearchResultsForType: "Résultats de recherche pour le type",
                    AssociatedOnly: "associé uniquement",
                    NotAssociatedOnly: "non associés uniquement",
                    WithReference: "avec la référence",
                    WithSerial: "avec le n° de série",
                    AnyDeviceMatchingWithSearchFilters: "Aucun appareil correspondant aux filtres de recherche",
                    YouHaveToPickAtLeastOneTypeOfDevice: "Vous devez sélectionner au moins un type d'appareil",

                    AreaLabelNotConform: "Le nom de la zone contient des caractères non autorisés",
                    AreYouSureYouWantToReplace: "Êtes-vous sûre de vouloir remplacer",
                    With: "avec",
                    Percent: "Pourcentage",

                    //User utils
                    Disconnect: "Déconnexion",
                    SureToDisconnect: "Etes-vous sûre de vouloir vous déconnecter ?",

                    RigthReserved: "Tous droits réservés Fastpoint",
                    ValidAccess: "L'accès à ce service est strictement réservé aux utilisateurs authentifiés et disposants d'un accès valide.",

                    //SignUP 
                    "Don't have an account?":"Vous n'avez pas de compte ?",
                    "Sign Up here":"Inscrivez-vous ici",
                    "Signup successful! Redirecting...":"Signup successful! Redirecting...",
                    "First Name":"Prénom",
                    "Last Name":"Nom",
                    Hide:"Cacher",
                    Show:"Montrer",
                    "Confirm Password":"Confirmez le mot de passe",
                    "Site Owner (fpAdmin)":"Propriétaire du site (fpAdmin)",
                    "Company Admin (isAdmin)":"Administrateur de l'entreprise (isAdmin)",
                    "Sign Up":"S'inscrire",
                    "Select Client":"Sélectionner un client",
                    "create user":"creation de utilisateur",
                    "Signup successful":"Inscription réussie",
                    "Back":"Retourne",
                    "Send Email":"Envoyer un e-mail",

                    //Password validation
                    "Password must be at least 8 characters long.": "Le mot de passe doit comporter au moins 8 caractères.",
                    "Password must contain at least one uppercase letter.": "Le mot de passe doit contenir au moins une lettre majuscule.",
                    "Password must contain at least one lowercase letter.": "Le mot de passe doit contenir au moins une lettre minuscule.",
                    "Password must contain at least one number.": "Le mot de passe doit contenir au moins un chiffre.",
                    "Password must contain at least one special character (!@#$%^&*).": "Le mot de passe doit contenir au moins un caractère spécial (!@#$%^&*).",
                    'Please enter a valid email address.':"S'il vous plaît, mettez une adresse email valide.",
                    "Passwords do not match.":"Les mots de passe ne correspondent pas.",
                    
                    //SendEmail 

                    "Creation user for SecuriSPOT SmartView website": "Création d'un utilisateur pour le site SecuriSPOT SmartView",
                    "Dear": "Cher",
                    "The tech team at FastPoint has successfully created an account for you on the SecuriSPOT SmartView website. Please find your login details below:": "L'équipe technique de FastPoint a créé avec succès un compte pour vous sur le site SecuriSPOT SmartView. Veuillez trouver vos identifiants ci-dessous :",
                    "Username": "Nom d'utilisateur",
                    "Best regards": "Cordialement",
                    "The FastPoint Team": "L'équipe FastPoint",
                      
                    // Confirmation Modal Tree
                    'Please enter the word "Confirm" to proceed.':'Veuillez saisir le mot "Confirmer" pour continuer.',
                    'Pasting is not allowed.':"Le collage n'est pas autorisé.",
                    "You are moving the node":"Vous déplacez le nœud",
                    "from":"depuis",
                    "to":"à",
                    'Type "Confirm" in the field below to proceed:':'Tapez "Confirm" dans le champ ci-dessous pour continuer :',
                    'confirm':'confirmer',
                    'Cancel':'Annuler',
                },
            },
        },
        load: "languageOnly",
        fallbackLng: "en",
        debug: false,
        lng: localStorage.getItem("language"),
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ",",
        },

        react: {
            wait: true,
        },
    })

export default i18n
